import { intl } from 'di18n-react';
/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-09-09 16:21:19
 * @LastEditTime: 2023-11-14 17:13:31
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/pages/knowledge/PageDetail/Header/Star/index.js
 *
 */
import styles from './style.module.less';
import classnames from 'classnames/bind';
import { Tooltip, message } from 'antd';
import { starAction } from '@/service/knowledge/star';
import { useDispatch, useSelector } from 'react-redux';

const cls = classnames.bind(styles);

function Star({ docInfo, source = 'RESOURCE', starInfo = '' }) {
  const dispatch = useDispatch();
  const { markedStar, pageId } = docInfo;
  const { changeStar } = dispatch.pageDetail;
  const { changeSharStar } = dispatch.SharePage;
  const { tree } = useSelector((state) => state.PageTree);

  const starHandle = async (markedStar) => {
    const type = markedStar ? 0 : 1;
    const listInfo = {
      resourceId: pageId,
      starType: source, // 收藏类型  RESOURCE普通页面及资源; PAGE_SHARE页面分享; DK_SHARE知识库分享【必填】
      starInfo,
      appId: 4,
    };
    let data = await starAction(type, [listInfo]);
    if (data) {
      message.success(type === 1 ? intl.t('收藏成功') : intl.t('已取消收藏'));
      if (source === 'RESOURCE') {
        changeStar(!markedStar);
        tree.updateNode(pageId, { markedStar: !markedStar });
      } else {
        changeSharStar(!markedStar);
      }
    }
  };
  return (
    <Tooltip
      title={markedStar ? intl.t('取消收藏') : intl.t('收藏')}
      placement="bottom"
      overlayClassName="dk-ant-tooltip__reset"
    >
      <span
        className={cls('star-trigger')}
        onClick={() => starHandle(markedStar)}
      >
        <i
          className={cls({
            'dk-iconfont': true,
            'dk-icon-yishoucang1': markedStar,
            'star-button': true,
            'dk-icon-shoucang4px': !markedStar,
            hasStar: markedStar,
          })}
        />
      </span>
    </Tooltip>
  );
}

export default Star;
