/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-06-27 10:53:52
 * @LastEditTime: 2023-07-07 15:11:13
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/ShareModal/ShareEntryPage/index.js
 *
 */

import { intl } from 'di18n-react';
import classNames from 'classnames/bind';
import styles from './style.module.less';

const cls = classNames.bind(styles);

function ShareEntryPage({ isSharing }) {
  return (
    <span className={cls('share-trigger', { 'is-active': isSharing })}>
      <i className={cls('dk-iconfont', 'dk-icon-fenxiang', 'status-icon')}/>
      {isSharing ? intl.t('分享中') : intl.t('分享')}
    </span>
  );
}

export default ShareEntryPage;
