import { intl } from 'di18n-react';
import classBind from 'classnames/bind';
import { highlight } from '@/utils';
import styles from './style.module.less';

const cx = classBind.bind(styles);

function TagListNode({ nodeData, onClick }) {
  return (
    <div
      className={cx('search-tag-item')}
      onClick={onClick}
    >
      <span
        className={cx('item-title', {
          'item-used': nodeData.used,
        })}
      >
        {highlight(nodeData.name)}
      </span>

      {nodeData.used && (
        <span className={cx('exist-tag')}>{intl.t('已添加')}</span>
      )}
    </div>
  );
}

export default TagListNode;
