import { intl } from 'di18n-react';
import { useMemo, useState, useEffect } from 'react';
import classBind from 'classnames/bind';
import { blurTime } from '@/utils';
import styles from './style.module.less';
import PageViews from '../PageViews';

const cx = classBind.bind(styles);

const UpdateInfo = ({ time, pageId }) => {
  return (
    time && (
      <div className={cx('update-info')}>
        {intl.t('编辑页面-更新于')}

        {blurTime(time)}
        <PageViews pageId={pageId} />
      </div>
    )
  );
};

export default UpdateInfo;
