import { intl } from 'di18n-react';
import classBind from 'classnames/bind';
import { Modal, Button } from 'antd';
import { PageNotPublishIcon } from '@/assets/icon';
import styles from './style.module.less';
import { checkNameErrorMsg, openNewWindow } from '@/utils/index';

const cx = classBind.bind(styles);
// 查看详情
const viewDetails = (args) => {
  const { redirectBpmUrl = '' } = args;
  openNewWindow(redirectBpmUrl);
};
const CustomModal = ({
  icon = PageNotPublishIcon,
  title,
  reissue,
  content,
  ...args
}) => {
  const style = reissue
    ? 'custom-confirm-container  custom-confirm-container-viewDetails'
    : 'custom-confirm-container dk-ant-modal_reset';

  Modal.confirm({
    centered: true,
    closable: false,
    width: 580,
    // 由于二次确认有可能在弹窗上层，因此需要z-index高一些
    zIndex: 1040,
    ...args,
    className: style,
    // 自定义Button
    content: (
      <div style={{ position: 'relative' }}>
        <div className={cx('custom-modal-content')}>
          <img
            src={icon}
            className={cx('custom-confirm-icon')} />

          <div className={cx('custom-text')}>
            <h1 className={cx('text-h1')}>{title}</h1>
            <p className={cx('text-p')}>{content}</p>
          </div>

        </div>
        <div>
          {reissue ? (
            <Button
              style={{
                position: 'absolute',
                left: '128px',
                bottom: '-72px',
                background: '#f6f6f6',
                color: '#444b4f',
              }}
              onClick={() => viewDetails(args)}
            >
              {intl.t('查看详情')}
            </Button>
          ) : null}
        </div>
      </div>
    ),
    icon: undefined,
    title: undefined,
  });
};

export default CustomModal;
