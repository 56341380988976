/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-07-13 16:13:21
 * @LastEditTime: 2023-07-13 20:23:28
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/common/BatchButton/index.js
 *
 */
import React from 'react';
import { Button } from 'antd';
import classBind from 'classnames/bind';
import styles from './style.module.less';
import { isDC } from '@/utils/index';

const cx = classBind.bind(styles);

class BatchButton extends React.Component {
  render() {
    const { batches } = this.props;
    return (
      <div
        className={cx({
          'batch-button': true,
          'is-dc': isDC(),
        })}>
        <Button.Group>
          {
            batches.map((b) => <Button
              key={ b.name }
              onClick={ b.callback }><i/>{ b.name }</Button>)
          }
        </Button.Group>
      </div>
    );
  }
}

export default BatchButton;
