
import CooperApi from '@/utils/request/api/CooperApi';
import { del, put, get } from '@/utils/request/cooper';
import qs from 'qs';

export const roleMap = {
  RESOURCE_OWNER: 'ADMIN',
  RESOURCE_ADMIN: 'ADMIN',
  RESOURCE_MEMBER: 'MEMBER',
  RESOURCE_NOPERM_MEMBER: 'NO_PERM',
};

// 修改权限
export const BatchChangeRoleForPerm = async (resourceId, list, perm, callback) => {
  const ps = list.map((item) => {
    let param = {
      memberType: 'User',
      resourceType: 'dir',
      perm,
      orgMemberId: item.orgMemberId,
    };
    if (item.id) {
      param = {
        ...param,
        id: item.id,
      };
    }
    if (item.sources && roleMap[item.role] === 'ADMIN') {
      let param1 = {
        memberType: 'User',
        resourceType: 'dir',
        role: 'RESOURCE_MEMBER',
        orgMemberId: item.orgMemberId,
      };
      if (item.id) {
        param1 = {
          ...param1,
          id: item.id,
        }
      }

      return put(CooperApi.CHANGE_USER_ROLE.replace(':resourceId', resourceId), param1).then(() => {
        return put(CooperApi.CHANGE_USER_PERM.replace(':resourceId', resourceId), { ...param, id: Number(item.orgMemberId) })
      })
    }
    return put(CooperApi.CHANGE_USER_PERM.replace(':resourceId', resourceId), param)
  });
  await Promise.all(ps).then(() => { callback(false) }).catch(() => { callback(true) })
}

// 移除成员
export const BatchRemoveAuthMembers = async (resourceId, list, callback) => {
  const ps = list.map((item) => {
    return del(`${CooperApi.CHANGE_USER_REMOVE.replace(':resourceId', resourceId)}?orgMemberId=${item.orgMemberId}`);
  })
  await Promise.all(ps).then(() => { callback(false) }).catch(() => { callback(true) })
}

// 调整管理员身份
export const BatchChangeRoleForAdmin = async (resourceId, list, role, callback) => {
  const ps = list.map((item) => {
    let param = {
      memberType: 'User',
      resourceType: 'dir',
      role,
      orgMemberId: item.orgMemberId,
    };
    if (item.id) {
      param = {
        ...param,
        id: item.id,
      }
    }
    return put(CooperApi.CHANGE_USER_ROLE.replace(':resourceId', resourceId), param);
  })
  await Promise.all(ps).then(() => { callback(false) }).catch(() => { callback(true) })
}

export const getFolderMember = (params) => {
  const { resourceId, pageNum, pageSize } = params;
  return get(CooperApi.GET_FOLDER_MEMBER.replace(':resourceId', resourceId), {
    params: {
      pageNum,
      pageSize,
    },
  });
};

export const searchFolderMember = (params) => {
  const { resourceId } = params;
  return get(`${CooperApi.SEARCH_FOLDER_MEMBER.replace(':resourceId', resourceId)}?${qs.stringify(params)}`);
}
