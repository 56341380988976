import { message } from 'antd';
import { intl } from 'di18n-react';
import React, { useState, useEffect } from 'react';
import cls from 'classnames';
import QuotaBtn from '@/components/QuotaLib/quota-btn';
import { getMyQuota } from '@/service/cooper/personSpace';
import './index.less';
import { useDispatch } from 'react-redux';

function Capacity() {
  const [quota, setQuota] = useState({
    proportion: 0,
    quotaBpmUrl: '',
    usedQuota: 0,
    spaceQuota: 0,
  });

  const dispatch = useDispatch();
  const { setPersonalSpaceName, setPersonalSpaceId } = dispatch.TeamData;

  useEffect(() => {
    updateQuota();
  }, []);

  async function updateQuota() {
    const quota = await getMyQuota();

    setQuota(quota);
    setPersonalSpaceId(quota.personalSpaceId);
    setPersonalSpaceName({
      nameZh: quota.personalSpaceName,
      nameEn: quota.personalSpaceNameEn,
    });
  }

  const { proportion, quotaBpmUrl, usedQuota, spaceQuota } = quota;
  const fullWarn = (usedQuota > 0 && spaceQuota - usedQuota < 5);

  return (
    <div className='v3-capacity'>
      {intl.t('容量')}:
      <span className={cls('used-quota', { red: fullWarn })}>{usedQuota}</span>/{spaceQuota}G
      {fullWarn && !quotaBpmUrl && (
        <QuotaBtn
          teamId={0}
          className='quota-btn'
          success={() => {
            updateQuota();
          }}
        />
      )}
      {quotaBpmUrl && (
        <span
          className='auth'
          onClick={() => {
            window.open(quotaBpmUrl);
          }}
        >
          {intl.t('待审核')}
        </span>
      )}
    </div>
  );
}

export default Capacity;
