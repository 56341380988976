import { intl } from 'di18n-react';
import classNames from 'classnames/bind';
import { NoSearchResultIcon } from '@/assets/icon';
import styles from './style.module.less';

const cx = classNames.bind(styles);

const EmptySearch = ({ text = intl.t('搜索无结果') }) => {
  return (
    <div className={cx('emptySearch')}>
      <img
        className={cx('emptySearch-icon')}
        src={NoSearchResultIcon} />
      <p className={cx('emptySearch-tip')}>{text}</p>
    </div>
  );
};
export default EmptySearch;
