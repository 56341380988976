/*
 * @Date: 2023-11-09 16:00:00
 * @LastEditors: guanzhong
 * @LastEditTime: 2023-11-27 15:00:26
 * @FilePath: /knowledgeforge/src/components/CooperTrashFilesList/BatchOperate/index.js
 * @Description: cooper 回收站批量操作
 */
import { intl } from 'di18n-react';
import React from 'react';
import { noop } from 'lodash-es';
import BatchButton from '@/components/common/BatchButton/index';
import deleteFile from '@/components/CooperOperation/DeleteFile';
import downloadFile from '@/components/CooperOperation/DownloadFile';

import './index.less';


function BatchOperate({
  checks = [],
  children,
  deleteCallback = noop,
}) {
  // if (checks.length === 0) {
  //   const batches = [
  //     {
  //       name: intl.t('下载'),
  //       callback: () => msgWaining(),
  //     },
  //     {
  //       name: intl.t('删除'),
  //       callback: () => msgWaining(),
  //     },
  //     {
  //       name: intl.t('移动'),
  //       callback: () => msgWaining(),
  //     },
  //     {
  //       name: intl.t('复制'),
  //       callback: () => msgWaining(),
  //     },
  //     {
  //       name: intl.t('收藏'),
  //       callback: () => msgWaining(),
  //     },
  //   ];

  //   return (
  //     <div className='batch-operate'>
  //       <div className='batch-operate-main'>
  //         <BatchButton batches={batches}/>
  //       </div>

  //       <div className='batch-operate-cancel'>
  //         {children && children}
  //       </div>

  //     </div>
  //   );
  // }
  const { teamId } = checks[0];

  const batches = [
    {
      name: intl.t('还原'),
      callback: () => {
        const { __connectSiteId = 0 } = window;
        downloadFile(checks, __connectSiteId, 0, teamId);
      },
    },
    {
      name: intl.t('删除'),
      callback: () => {
        deleteFile(checks.map((f) => f.id), deleteCallback);
      },
    },
  ];

  return (
    <div className='batch-operate'>
      <div className='batch-operate-main'>
        <BatchButton batches={batches} />
      </div>

      <div className='batch-operate-cancel'>
        {children && children}
      </div>

    </div>
  );
}

export default BatchOperate;
