import classBind from 'classnames/bind';
import styles from './style.module.less';
import FoldTree from './FoldTree/index';
import { noop } from 'lodash-es';

const cx = classBind.bind(styles);

function CooperTrashFilesList({
  role = 0,
  isTeam = true,
  teamId = 0,
  folderId = 0,
  viewType,
  sortBy,
  orderAsc,
  onDragResizeEnd,
  onToggleSort,
  fileOpreationType,
  setIsShowSwitchView,
  originFileType,
  switchView,
  isShowSwitchView,
  showRightBox,
}) {
  return (
    <div className={cx('cooper-files-list')}>
      <FoldTree
        role={role}
        folderId={folderId}
        fileOpreationType={fileOpreationType}
        teamId={teamId}
        isTeam={isTeam}
        viewType={viewType}
        switchView={switchView}
        sortBy={sortBy}
        orderAsc={orderAsc}
        onDragResizeEnd={onDragResizeEnd}
        onToggleSort={onToggleSort}
        setIsShowSwitchView={setIsShowSwitchView}
        isShowSwitchView={isShowSwitchView}
        showRightBox={showRightBox}
        originFileType={originFileType}
      />
    </div>
  )
}

export default CooperTrashFilesList;
