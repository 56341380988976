/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-07-22 15:41:23
 * @LastEditTime: 2023-07-22 15:44:36
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/CooperFoldAuth/departContent/index.js
 *
 */
import React, { useMemo } from 'react';
import { Tooltip } from 'antd';
import { intl, getLocale } from 'di18n-react';
import './index.less';


const DepartContent = ({ department = [], isShort = 'false' }) => {
  const getDepartmentStr = (str) => {
    if (str == null) str = intl.t('部门信息同步中');
    const inEnglish = getLocale() === 'en-US';
    if (str.length > 10 || inEnglish) {
      let showLength = isShort ? 3 : 4;
      if (self && inEnglish) showLength--;
      return `${str.slice(0, showLength)}...${str.slice(str.length - showLength - 1, -1)}`;
    }
    return str;
  };

  const needEllipsis = useMemo(() => {
    return department.join('-').length > 10 || getLocale() === 'en-US';
  }, [department]);

  const depart = department.join('-');

  return <Tooltip
    placement='top'
    overlayClassName={'department-tooltip'}
    getPopupContainer={(e) => e.parentNode}
    title={needEllipsis ? depart : undefined}>
    <span className={needEllipsis ? 'text-hover' : ''}>
      ({getDepartmentStr(department.join('-'))})
    </span>
  </Tooltip>;
};

export default DepartContent;
