import React from 'react';
import classBind from 'classnames/bind';
import styles from './style.module.less';

const cx = classBind.bind(styles);

const SecondLevelTab = ({tabList, onClick, currKey, className}) => {
  return <div className={`${className} ${cx('list-type')}`}>
  {
    tabList.map((value,index) => {
      return (
        <div
          key={value.key}
          onClick={() => onClick(value.key)}
          className={cx('list-type-item', {
            'tab-active': currKey === value.key
          })}
        >{value.title}</div>
      )
    })
  }
</div>;
};

export default SecondLevelTab;
