/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-07-11 15:54:45
 * @LastEditTime: 2023-07-22 15:13:57
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/CooperFilesList/index.js
 *
 */
import classBind from 'classnames/bind';
import styles from './style.module.less';
import FoldTree from './FoldTree/index';
import { noop } from 'lodash-es';
import { connect, useSelector } from 'react-redux';

const cx = classBind.bind(styles);

function CooperFilesList({
  teamId = 0,
  folderId = 0,
  viewType = 'TREE',
  sortBy,
  orderAsc,
  onDragResizeEnd,
  onToggleSort,
  fileOpreationType,
  uploadCase = { triggerUpload: noop },
  setIsShowSwitchView,
  originFileType,
  switchView,
  isShowSwitchView,
  isTeam,
  isOuter,
  location
}) {
  return (
    <div className={cx('cooper-files-list')}>
      <FoldTree
        folderId={folderId}
        fileOpreationType={fileOpreationType}
        teamId={teamId}
        viewType={viewType}
        switchView={switchView}
        sortBy={sortBy}
        orderAsc={orderAsc}
        onDragResizeEnd={onDragResizeEnd}
        onToggleSort={onToggleSort}
        onUpload={(e) => {
          e.preventDefault();
          uploadCase.triggerUFiles(isOuter);
        }}
        setIsShowSwitchView={setIsShowSwitchView}
        isShowSwitchView={isShowSwitchView}
        originFileType={originFileType}
        isTeam={isTeam}
        isOuter={isOuter}
        location={location}
      />
    </div>
  )
}


const mapStateToProps = (state) => {
  return {
    uploadCase: state.UploadList.uploadCase,
  };
};


export default connect(mapStateToProps)(CooperFilesList);
