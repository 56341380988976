import { intl } from 'di18n-react';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { blurTime, getFileSuffix, bytesToSize } from '@/utils';
import styles from './style.module.less';

const cls = classNames.bind(styles);

function FileMetaInfo() {
  const { previewFileInfo } = useSelector((state) => state.pageDetail);
  const {
    previewFileName,
    previewFileSize,
    createdBy,
    createdOn,
    lastModifiedBy,
    lastModifiedOn,
  } = previewFileInfo;

  return (
    <div className={cls('meta-info')}>
      <div className={cls('file-info')}>
        <span className={cls('file-type')}>
          {getFileSuffix(previewFileName).toUpperCase()}
        </span>
        <span className={cls('file-size')}>{bytesToSize(previewFileSize)}</span>
      </div>
      <div className={cls('info')}>
        <span className={cls('username')}>{createdBy}</span>
        <span className={cls('time')}>
          {intl.t('创建于')}
          {blurTime(createdOn)}
        </span>
      </div>
      <div className={cls('info')}>
        <span className={cls('username')}>{decodeURI(lastModifiedBy)}</span>
        <span className={cls('time')}>
          {intl.t('最后更新于')}
          {blurTime(lastModifiedOn)}
        </span>
      </div>
    </div>
  );
}

export default FileMetaInfo;
