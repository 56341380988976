
import { getPermissionOptions } from './contants';

export const containKeyword = (str, keyWord) => {
  return new RegExp(keyWord, 'i').test(str);
};


export const roleMap = {
  RESOURCE_OWNER: 'ADMIN',
  RESOURCE_ADMIN: 'ADMIN',
  RESOURCE_MEMBER: 'MEMBER',
  RESOURCE_NOPERM_MEMBER: 'NO_PERM',
};

export const parsePerm = (perm) => {
  const perms = [];
  getPermissionOptions().forEach((item) => {
    if ((item.perm & perm) === item.perm) { // eslint-disable-line no-bitwise
      perms.push(item);
    }
  });
  return perms;
};

