import React, { useState } from 'react';
import { intl } from 'di18n-react';
import { message, Modal } from 'antd';
import classNames from 'classnames';
import { debounce } from 'lodash-es';
import inheritNoCheck from '../../icon/inheritNoCheck.png';
import inheritCheck from '../../icon/inheritCheck.png';
import { setCooperationType, getAllShare } from '../../service';
import cooperConfirm from '@/components/common/CooperConfirm';
import './index.less';


const InheritContent = (props) => {
  const [value, setValue] = useState(props.isInherit);
  const [lock, setLock] = useState(false);
  const { resourceId, teamId } = props;
  const INHERIT_SPACE_PERMISSIONS = [
    {
      title: intl.t('继承团队空间权限'),
      tip: intl.t('空间成员权限变化实时同步'),
      status: true,
    },
    {
      title: intl.t('不继承团队空间权限'),
      tip: intl.t('自行设置可以访问文件夹内容的成员'),
      status: false,
    },
  ];

  const modifyInheritance = (status) => {
    setCooperationType({
      resourceId,
      spaceId: teamId,
      extensionType: status ? 0 : 1,
    }).then((res) => {
      let inheritValue = res.extensionType;
      setValue(inheritValue === 0);
      props.inheritCallback(inheritValue);
      message.success(intl.t('已修改继承方式'));
      (inheritValue === 0) ? window.__OmegaEvent('ep_teamspace_more_permisionset_inherit_confirm_ck') : window.__OmegaEvent('ep_teamspace_more_permisionset_disconnect_confirm_ck');
    }).catch((error) => {
      if (error.errorCode === '501082') {
        message.error(intl.t('你没有权限'));
      }
    });
  };

  const getAllShareFun = () => {
    setLock(true);
    getAllShare({ resourceId })
      .then((res) => {
        if (lock) {
          return;
        }
        const { link, direct } = res;
        if (link == 0 && direct == 0) {
          cooperConfirm({
            width: 480,
            zIndex: 2001,
            type: 'warn',
            title: intl.t('确认不继承团队空间权限吗？'),
            content: intl.t('切换为不继承后，仅文件夹管理员和被单独授权的空间成员可以访问文件夹'),
            onOk: () => modifyInheritance(status),
            okText: intl.t('确定'),
            cancelText: intl.t('取消'),
          });
        } else {
          cooperConfirm({
            width: 480,
            zIndex: 2001,
            type: 'warn',
            title: intl.t('确认不继承团队空间权限吗？'),
            content: <span>{intl.t('切换为不继承后，仅文件夹管理员和被单独授权的空间成员可以访问文件夹')}。<span className='content-title-weight'>{intl.t('当前存在分享记录，切换为不继承后，获得文件夹/文件分享链接的用户将无法访问')}</span></span>,
            onOk: () => modifyInheritance(status),
            okText: intl.t('确定'),
            cancelText: intl.t('取消'),
          });
        }
        setLock(false);
      });
  };

  const inheritSpace = debounce((data) => {
    const { status } = data;
    if (value == status) {
      return;
    }
    if (status) {
      cooperConfirm({
        zIndex: 2001,
        type: 'warn',
        title: intl.t('确认继承团队空间权限吗？'),
        content: intl.t('切换为继承后，团队空间内所有的成员都可以访问文件夹'),
        onOk: () => modifyInheritance(status),
        okText: intl.t('确定'),
        cancelText: intl.t('取消'),
      });
      window.__OmegaEvent('ep_teamspace_more_permisionset_inherit_ck');
    } else {
      getAllShareFun();
      window.__OmegaEvent('ep_teamspace_more_permisionset_disconnect_ck');
    }
  }, 500);

  return <div className='inherit-content'>
    {
      INHERIT_SPACE_PERMISSIONS.map((item) => {
        return (
          <div
            className={classNames('container', {
              'change-container-inherit': item.status == value && value,
              'change-container-noninherit': item.status == value && !value,
            })}
            onClick={() => inheritSpace(item)}
            key={item.status}>
            <img
              className='container-left'
              src={ item.status == value ? inheritCheck : inheritNoCheck}/>
            <div className='container-right'>
              <span
                className={classNames('right-title', {
                  'change-right-title': item.status == value,
                })}>{item.title}</span>
              <span className='right-tip'>{item.tip}</span>
            </div>
          </div>
        );
      })
    }
  </div>;
};

export default InheritContent;
