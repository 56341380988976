import { isWebkit } from '@/utils';
import { useMemo, useState } from 'react';

// 中文输入结束才搜索
const CompositionInput = ({
  onInputChange, onFocus = () => {}, onBlur = () => {},
  style, customClass, placeholder, defaultValue, autoFocus,
}) => {
  const [cnInputting, setCnInputting] = useState(false);

  const inWebkit = useMemo(() => {
    return isWebkit();
  }, []);
  const onCompositionStart = () => {
    setCnInputting(true);
  };
  const onCompositionEnd = (e) => {
    setCnInputting(false);
    if (inWebkit) { onInputChange(e); }
  };
  const onChange = (e) => {
    if (cnInputting) return;
    onInputChange(e);
  };
  return <input
    defaultValue={defaultValue}
    style={style}
    className={customClass}
    onChange={onChange}
    onFocus={onFocus}
    onBlur={onBlur}
    onCompositionStart={onCompositionStart}
    onCompositionEnd={onCompositionEnd}
    placeholder={placeholder}
    autoFocus={autoFocus}
    />;
};
export default CompositionInput;
