import { intl } from 'di18n-react';
import { useEffect, useContext, useState } from 'react';
import classNames from 'classnames/bind';
import { getPageDetailPageView } from '@/service/knowledge/page';
import styles from './style.module.less';

const cls = classNames.bind(styles);
const PageViews = ({ pageId }) => {
  const [num, setNum] = useState(null);
  useEffect(() => {
    (async () => {
      const res = await getPageDetailPageView(pageId);
      const calcRes = res.pv < 10000
        ? res.pv
        : intl.t('{slot0}万', {
          slot0: (Math.floor(res.pv / 1000) / 10).toString(),
        });
      setNum(calcRes);
    })();
  }, []);
  return (
    <>
      {num ? (
        <span className={cls('page-views')}>
          <i className={cls('dk-iconfont', 'dk-icon-yulan', 'yuLanIcon')} />
          <span className={cls('view-num')}>{num}</span>
        </span>
      ) : null}
    </>
  );
};
export default PageViews;
