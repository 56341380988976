import { intl } from 'di18n-react';
import DirectAdd from '@didi/add-member-search-react';
import {
  searchMembersWhenAddMember,
  searchCustomMembersWhenAddMember,
} from '@/service/knowledge/setup';
import classBind from 'classnames/bind';
import NotificationStatus from '@/constants/notification';
import useNotification from '@/hooks/useNotification';
import {
  ADD_SEARCH_MEMBER_TYPE,
  ADD_MEMBER_FROM_TYPE,
  ADD_MEMBER_TYPE,
  CHANGE_SEARCH_MEMBER_TYPE,
} from '@/constants/setup';
import styles from './style.module.less';

const cx = classBind.bind(styles);

const AddCollaborator = ({
  onClose,
  permissionType,
  currId,
  invite,
  batchAdd,
  showBatchAdd,
  EpClickBatchAddFns,
  setShowBatchAdd,
  existMessage,
  addManagerPermission = true,
  groupOmegaType,
  inDk,
}) => {
  const notification = useNotification();

  const doInvite = async (memberData) => {
    clickAddButton(memberData);
    await invite(currId, memberData);
    if (permissionType !== ADD_MEMBER_FROM_TYPE.Dk_Group) {
      notification(NotificationStatus.SUCCESS, intl.t('添加成功'));
    }
    onClose();
  };
  const doSearch = async ({ keyword, searchType, pageNum }) => {
    const params = { keyword, searchType, itemSize: 10, pageNum };
    if (permissionType === ADD_MEMBER_FROM_TYPE.DK_PAGE) {
      params.pageId = currId;
    } else {
      params.knowledgeId = currId;
    }
    const api = permissionType === ADD_MEMBER_FROM_TYPE.Dk_Group
      ? searchCustomMembersWhenAddMember
      : searchMembersWhenAddMember;
    const data = await api(params);
    // 成员添加成员时，memberCount不显示
    if (
      permissionType === ADD_MEMBER_FROM_TYPE.DK_PAGE
      || permissionType === ADD_MEMBER_FROM_TYPE.DK
      || permissionType === ADD_MEMBER_FROM_TYPE.Dk_Group
    ) {
      return data.map((obj) => {
        if (obj.type === ADD_MEMBER_TYPE.DEPARTMENT) {
          return {
            ...obj,
            items: obj.items.map((member) => ({
              ...member,
              memberCount: '',
            })),
          };
        }
        return {
          ...obj,
          items: obj.items.map((member) => ({
            ...member,
          })),
        };
      });
    }
    return data;
  };
  const getDefaultPermis = () => {
    const defaultChoose = permissionType === ADD_MEMBER_FROM_TYPE.DK ? [2] : [1];
    return defaultChoose;
  };
  const permissionFilter = (defaultPermission) => {
    const temp = defaultPermission.map((item) => {
      if (item.id === 0) {
        item.disabled = false;
      }
      if (!addManagerPermission && item.label === intl.t('管理员')) {
        item.disabled = true;
      }
      return item;
    });
    if (inDk) {
      return temp;
    }

    return temp.filter((item) => {
      if (item.label !== intl.t('只读成员')) {
        return item;
      }
      return null;
    });
  };
  const clickSearchTab = (index) => {
    switch (permissionType) {
      case ADD_MEMBER_FROM_TYPE.Dk_Group:
        window.__OmegaEvent(
          'ep_dkpc_member_creategroup_invite_searchresult_tab_ck',
          '',
          { source: groupOmegaType, tab: ADD_SEARCH_MEMBER_TYPE[index] },
        );
        break;
      case ADD_MEMBER_FROM_TYPE.DK:
        window.__OmegaEvent('ep_dkpc_member_invite_searchresult_tab_ck', '', {
          tab: ADD_SEARCH_MEMBER_TYPE[index],
        });
        break;
      case ADD_MEMBER_FROM_TYPE.DK_PAGE:
        window.__OmegaEvent(
          'ep_dkpc_pagedetail_invite_searchresult_tab_ck',
          '',
          { tab: ADD_SEARCH_MEMBER_TYPE[index] },
        );
        break;
      default:
    }
  };
  const clickSeeMore = (index) => {
    switch (permissionType) {
      case ADD_MEMBER_FROM_TYPE.Dk_Group:
        window.__OmegaEvent(
          'ep_dkpc_member_creategroup_invite_searchresult_viewmore_ck',
          '',
          { source: groupOmegaType, viewmore: ADD_SEARCH_MEMBER_TYPE[index] },
        );
        break;
      case ADD_MEMBER_FROM_TYPE.DK:
        window.__OmegaEvent(
          'ep_dkpc_member_invite_searchresult_viewmore_ck',
          '',
          { viewmore: ADD_SEARCH_MEMBER_TYPE[index] },
        );
        break;
      case ADD_MEMBER_FROM_TYPE.DK_PAGE:
        window.__OmegaEvent(
          'ep_dkpc_pagedetail_invite_searchresult_viewmore_ck',
          '',
          { viewmore: ADD_SEARCH_MEMBER_TYPE[index] },
        );
        break;
      default:
    }
  };
  const chooseSearchItem = (type) => {
    if (permissionType === ADD_MEMBER_FROM_TYPE.Dk_Group) {
      switch (type) {
        case ADD_MEMBER_TYPE.DC_GROUP:
          window.__OmegaEvent(
            'ep_dkpc_member_creategroup_invite_groupdc_ck',
            '',
            { source: groupOmegaType },
          );
          break;
        case ADD_MEMBER_TYPE.COOPER_GROUP:
          window.__OmegaEvent(
            'ep_dkpc_member_creategroup_invite_groupcooper_ck',
            '',
            { source: groupOmegaType },
          );
          break;
        case ADD_MEMBER_TYPE.OE_GROUP:
          window.__OmegaEvent(
            'ep_dkpc_member_creategroup_invite_groupoe_ck',
            '',
            { source: groupOmegaType },
          );
          break;
        case ADD_MEMBER_TYPE.EMAIL_GROUP:
          window.__OmegaEvent(
            'ep_dkpc_member_creategroup_invite_groupemail_ck',
            '',
            { source: groupOmegaType },
          );
          break;
        case ADD_MEMBER_TYPE.PERSON:
          window.__OmegaEvent('ep_dkpc_member_creategroup_invite_user_ck', '', {
            source: groupOmegaType,
          });
          break;
        default:
      }
    }
  };
  const clickAddButton = (memberData) => {
    if (permissionType === ADD_MEMBER_FROM_TYPE.DK) {
      memberData.forEach((element) => {
        switch (element.type) {
          case ADD_MEMBER_TYPE.PERSON:
            window.__OmegaEvent('ep_dkpc_member_invite_user_ck');
            break;
          case ADD_MEMBER_TYPE.OE_GROUP:
            window.__OmegaEvent('ep_dkpc_member_invite_groupoe_ck');
            break;
          case ADD_MEMBER_TYPE.COOPER_GROUP:
            window.__OmegaEvent('ep_dkpc_member_invite_groupcooper_ck');
            break;
          case ADD_MEMBER_TYPE.EMAIL_GROUP:
            window.__OmegaEvent('ep_dkpc_member_invite_groupemail_ck');
            break;
          case ADD_MEMBER_TYPE.DC_GROUP:
            window.__OmegaEvent('ep_dkpc_member_invite_groupdc_ck');
            break;
          case ADD_MEMBER_TYPE.DEPARTMENT:
            window.__OmegaEvent('ep_dkpc_member_invite_grouporganization_ck');
            break;
          default:
        }
      });
    } else {
      memberData.forEach((element) => {
        switch (element.type) {
          case ADD_MEMBER_TYPE.PERSON:
            window.__OmegaEvent('ep_dkpc_pagedetail_invite_user_ck');
            break;
          case ADD_MEMBER_TYPE.OE_GROUP:
            window.__OmegaEvent('ep_dkpc_pagedetail_invite_groupoe_ck');
            break;
          case ADD_MEMBER_TYPE.COOPER_GROUP:
            window.__OmegaEvent('ep_dkpc_pagedetail_invite_groupcooper_ck');
            break;
          case ADD_MEMBER_TYPE.EMAIL_GROUP:
            window.__OmegaEvent('ep_dkpc_pagedetail_invite_groupemail_ck');
            break;
          case ADD_MEMBER_TYPE.DC_GROUP:
            window.__OmegaEvent('ep_dkpc_pagedetail_invite_groupdc_ck');
            break;
          case ADD_MEMBER_TYPE.DEPARTMENT:
            window.__OmegaEvent(
              'ep_dkpc_pagedetail_invite_grouporganization_ck',
            );
            break;
          default:
        }
      });
    }
  };
  const epClickChangePermis = (_, permis) => {
    if (permissionType === ADD_MEMBER_FROM_TYPE.DK) {
      window.__OmegaEvent('ep_dkpc_member_invite_changerole_ck', '', {
        role: CHANGE_SEARCH_MEMBER_TYPE[permis[0]],
      });
    } else {
      window.__OmegaEvent('ep_dkpc_pagedetail_invite_changerole_ck', '', {
        role: CHANGE_SEARCH_MEMBER_TYPE[permis[0] + 1],
      });
    }
  };
  const locale = 'zh-CN';

  return (
    <div className={cx('add')}>
      <DirectAdd
        currId={currId}
        onClose={onClose}
        // locale={locale}
        onOk={doInvite}
        singleCheck={true}
        defaultPermis={getDefaultPermis()}
        searchFun={doSearch}
        permissionFilter={permissionFilter}
        permissionType={permissionType}
        existMessage={existMessage}
        epClickSearchTab={clickSearchTab}
        epClickSeeMore={clickSeeMore}
        epChooseSearchItem={chooseSearchItem}
        epClickChangePermis={epClickChangePermis}
        showBatchAdd={showBatchAdd} // 展示批量添加弹窗
        setShowBatchAdd={setShowBatchAdd}
        batchAdd={batchAdd}
        okText={
          permissionType === ADD_MEMBER_FROM_TYPE.Dk_Group
            ? intl.t('添加')
            : intl.t('添加并通知')
        }
        searchPlaceholder={intl.t('请输入用户名、D-Chat群组、组织架构名称等')}
        EpClickBatchAddFns={EpClickBatchAddFns}
        isShowOutDes={false}
      />
    </div>
  );
};

export default AddCollaborator;
