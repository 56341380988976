import { intl } from 'di18n-react';
import { useEffect, useState, useRef, useMemo, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useParams } from 'react-router-dom';
import { Tooltip, Popover } from 'antd';
import { ASIDE_SHOW_TYPE } from '@/constants';
import classBind from 'classnames/bind';
import { searchFromAllTags } from '@/service/knowledge/search';
import { TREE_VIEW_TYPE, TAG_SOURCE_TYPE } from '@/constants/index';
import LayoutContext from '@/components/serviceComponents/Layout/layoutContext';
import usePermission from '@/hooks/usePermission';
// import { checkItemUnread, setReadBatch } from '@/service/guide';
import { hitWords } from '@/utils';
import DebounceSelect from '@/components/DebounceSelectDk';
import SafeTag from '@/components/Tag/SafeTag';
import { apolloSwitch } from '@/utils/ab';
import styles from './style.module.less';
import TagManageContent from './TagManageContent';

const cx = classBind.bind(styles);

function PageTag({ overlayClassName }) {
  const { tags } = useSelector((state) => state.pageDetail);
  const { openGuide } = useSelector((state) => state.guide);
  // const { tagId } = useSelector((state) => state.TagTree);

  const dispatch = useDispatch();
  const { getPageTags, addPageTags } = dispatch.pageDetail;
  const { setTreeViewType } = dispatch.KnowledgeData;
  const { setState, addTagToPage, getNewTagPageData } = dispatch.TagTree;
  const { pageId, knowledgeId } = useContext(LayoutContext);
  const tagItemRef = useRef(null);
  const tagListRef = useRef(null);
  const { checkOperationPermission } = usePermission();
  const {
    permission = {},
    asideType,
    treeViewType,
  } = useSelector((state) => state.KnowledgeData);
  const { globalOutsideChain } = useSelector((state) => state.CooperIndex);

  const [showSeeAll, setShowSeeAll] = useState(false);
  const [allTagList, setAllTagList] = useState([]);
  const [safeTagSwitch, setSafeTagSwitch] = useState();
  const [addTagPopoverVisible, setAddTagPopoverVisible] = useState(false);
  const [tagManagePopoverVisible, setTagManagePopoverVisible] = useState(false);

  const hasAsidePerm = useMemo(() => {
    return checkOperationPermission('READ_DK', permission.perm);
  }, [permission]);

  useEffect(() => {
    getPageTags({ pageId });
    if (tagListRef?.current && tagListRef.current.width > 871) {
      setShowSeeAll(true);
    }
  }, [pageId]);


  useEffect(() => {
    initGrey();
  }, []);

  const initGrey = async () => {
    const data = await apolloSwitch('safe_tag');
    setSafeTagSwitch(data);
  };


  const fetchTagList = async (value) => {
    const res = await searchFromAllTags({
      key: value,
      pageId,
    });

    setAllTagList(res.items);

    return res.items;
  };

  const openAddTagGuide = () => {
    openGuide && openGuide('1-4-1029-addTag');
  };

  const chooseTag = async (item) => {
    let tagName = hitWords(item.name);
    addPageTags({
      pageId,
      tagId: item.id,
      tagName,
    }).then(() => {
      updateAfterAdd({ newTagId: item.id, newTagName: tagName });
    });
  };

  const pressAddTag = async (value) => {
    let tagName = value.toLowerCase();
    addPageTags({
      pageId,
      tagId: null,
      tagName,
    }).then((res) => {
      updateAfterAdd({ newTagId: res, newTagName: tagName });
    });
  };

  const updateAfterAdd = ({ newTagId, newTagName }) => {
    if (hasAsidePerm && treeViewType === TREE_VIEW_TYPE.TAG) {
      // 实时更新左侧标签
      getNewTagPageData({
        tagId: newTagId,
        tagName: newTagName,
        knowledgeId,
      }).then((tagPageData) => {
        addTagToPage({ tagPageData });
      });

      setState({
        choosePageMsg: {
          parentTagId: newTagId,
          pageId,
        },
      });
    }
    openAddTagGuide();
  };

  const linkToTagView = (linkTagId) => {
    setTreeViewType(TREE_VIEW_TYPE.TAG);

    // 唤起左侧目录树
    if (asideType === ASIDE_SHOW_TYPE.MODAL) {
      const foldEle = document.querySelector(
        '.dk-icon-liebiaomulushu.icon-unfold',
      );
      foldEle && foldEle.click();
    }

    setState({
      choosePageMsg: {
        parentTagId: linkTagId,
        pageId,
      },
    });

    window.__OmegaEvent('ep_dkpc_tag_view_ck');
  };

  const onClickAddTag = (value) => {
    value && window.__OmegaEvent('ep_dkpc_tag_create_ck');
    setAddTagPopoverVisible(value);
  };

  const onClickTagManage = (value) => {
    value && window.__OmegaEvent('ep_dkpc_tag_manage_ck');
    setTagManagePopoverVisible(value);
  };


  const hasSafetyTag = tags?.length > 0 && tags[0].sourceType === TAG_SOURCE_TYPE.SYSTEM_SECURE;

  return (
    <div className={cx('pagetag-wrap', overlayClassName)}>
      <Tooltip
        title={intl.t('页面标签管理')}
        placement="top"
        overlayClassName="dk-ant-tooltip__reset">
        <i className={cx('dk-icon-biaoqian', 'dk-iconfont', 'icon-tag')} />
      </Tooltip>
      <Popover
        overlayClassName={cx('add-tag-search-popover', 'dk-ant-popover__reset')}
        placement="bottomLeft"
        destroyTooltipOnHide={true}
        trigger={['click']}
        onVisibleChange={onClickAddTag}
        visible={addTagPopoverVisible}
        content={<DebounceSelect
          placeholder={intl.t('按Enter键添加标签')}
          showSearch={false}
          fetchOptions={fetchTagList}
          onChange={fetchTagList}
          onPressEnter={pressAddTag}
          data={allTagList}
          onSelect={chooseTag}
          notFoundContent={null}
          maxLength={50}
        />}
      >
        <i
          className={cx(
            'dk-icon-tianjiabiaoqian',
            'dk-iconfont',
            'icon-add-tag',
            {
              'icon-add-tag-active': addTagPopoverVisible,
            },
          )}
        >
          {intl.t('知识库-页面-顶部-标签')}
        </i>
      </Popover>

      {tags?.length > 0 && (
        <>
          <div
            className={cx('tags-list')}
            ref={tagListRef}>
            {safeTagSwitch && hasSafetyTag && (
              <div style={{ display: 'inline-block' }}>
                <SafeTag
                  tagkey={tags[0].id}
                  safeTag={{
                    name: tags[0].name,
                    id: tags[0].id,
                  }}
                  onClick={
                  () => linkToTagView(tags[0].id)
                }
              />
              </div>
            )}
            {safeTagSwitch && hasSafetyTag && tags[1] && <>、</>}
            {tags.map((item, index) => {
              if (hasSafetyTag && index === 0) return null;
              return (
                <Tooltip
                  title={item.name}
                  key={item.id}
                  placement="top">
                  <span
                    className={cx('tag-item')}
                    key={item.id}
                    ref={tagItemRef}
                    onClick={() => linkToTagView(item.id)}
                  >
                    {item.name}
                  </span>
                  {index !== tags.length - 1 && <>、</>}
                </Tooltip>
              );
            })}
          </div>

          {showSeeAll && (
            <span className={cx('ellipsis-sign')}>{intl.t('等')}</span>
          )}

          <Popover
            placement="bottom"
            trigger={['click']}
            onVisibleChange={onClickTagManage}
            visible={tagManagePopoverVisible}
            overlayClassName={cx(
              'dk-ant-popover__reset',
              'tags-manager-popover',
            )}
            content={<TagManageContent
              tagsData={tags}
              pageId={pageId} />}
          >
            <button
              className={cx('tags-manager-btn', {
                'tags-manager-btn-active': tagManagePopoverVisible,
              })}
            >
              {intl.t('等')}
              {tags.length}
              {intl.t('个标签')}
            </button>
          </Popover>
        </>
      )}
    </div>
  );
}

export default PageTag;
