const CooperParcelUrlMap = {
  dev: 'https://cooper-test.didichuxing.com/fecomponents', // 本地调试时改成http://localhost:3005
  test: 'https://cooper-test.didichuxing.com/fecomponents',
  qa: 'https://cooper-qa.didichuxing.com/fecomponents',
  prod: 'https://cooper.didichuxing.com/fecomponents',
};


const CooperParcelUrl = CooperParcelUrlMap[process.env.APP_ENV]

export {
  CooperParcelUrl,
}
