import { intl } from 'di18n-react';

export const InheritConfigRoot = {
  0: {
    value: 0,
    title: intl.t('继承知识库权限'),
    desc: intl.t('知识库成员和被添加的成员均可访问页面'),
  },
  1: {
    value: 1,
    title: intl.t('不继承知识库权限'),
    desc: intl.t('仅页面管理员和被添加的成员可访问页面'),
  },
};

export const MemberRoleMap = {
  PAGE_ADMIN: {
    value: 'PAGE_ADMIN',
    title: intl.t('页面-权限管理-管理员'),
    desc: intl.t('可编辑，可管理页面成员'),
  },
  PAGE_MEMBER: {
    value: 'PAGE_MEMBER',
    title: intl.t('页面-权限管理-成员'),
    desc: intl.t('可编辑'),
  },
  PAGE_READ_MEMBER: {
    value: 'PAGE_READ_MEMBER',
    title: intl.t('页面-权限管理-只读成员'),
    desc: intl.t('可阅读'),
  },
  PAGE_NOPERM_MEMBER: {
    value: 'PAGE_NOPERM_MEMBER',
    title: intl.t('页面-权限管理-无权限成员'),
    desc: intl.t('该用户将无法访问页面，支持更改'),
  },
  PAGE_REMOVE: {
    value: 'PAGE_REMOVE',
    title: intl.t('页面权限-知识库外受邀成员-移除'),
    desc: intl.t('该用户将无法访问页面'),
    isRemove: true,
  },
  RESUME: {
    value: 'RESUME',
    title: `${intl.t('恢复继承')}123`,
    desc: intl.t('该用户权限已被修改，可恢复继承'),
  },
};

export const PAGE_SOURCE = {
  ALONE_PERM: intl.t('当前页面单独授权'),
  EXTEND_PARENT: intl.t('继承自父页面'),
  NO_SHOW: '',
};

export const TagRoleMap = {
  DK_OWNER: intl.t('知识库所有者'),
  PAGE_OWNER: intl.t('页面所有者'),
  DK_ADMIN: intl.t('知识库管理员'),
};
