import { intl } from 'di18n-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Popover } from 'antd';
import cls from 'classnames';
import { isDC } from '@/utils/index';
import operate from './icon/operate.png';
import './index.less';
// import { iconShareFolder } from '../v3/icons';
import { folder } from '@/assets/icon/fileIcon';

export class CooperSubHead extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ellipsisData: [],
    };
  }

  getEllipsisRoutes = (routes) => {
    // 最多展示maxLevel个层级，超出用...
    let len = routes && routes.length;
    let ellipsisData = [];
    const maxLevel = document.body.offsetWidth < 1100 ? 3 : 4;
    if (!routes || len <= maxLevel) {
      return {
        ellipsisRoutes: routes,
        ellipsisData,
      };
    }

    const ellipsisNew = routes.slice();
    ellipsisData = ellipsisNew.splice(1, len - maxLevel + 1, {
      path: '...',
      breadcrumbName: '...',
    });
    return {
      ellipsisRoutes: ellipsisNew,
      ellipsisData,
    };
  };

  render() {
    const { title, children, routes, className } = this.props;
    const ellipsisRoutesObj = this.getEllipsisRoutes(routes);
    const { ellipsisRoutes, ellipsisData } = ellipsisRoutesObj;
    let ellipsisContent = '';

    if (ellipsisData.length > 0) {
      ellipsisContent = (
        <ul className={'pop-ellipsis'}>
          {ellipsisData.map((data) => {
            return (
              <li
                key={data.path}
                className="folder-item">
                <Link
                  to={data.path}
                  title={data.breadcrumbName}
                  className="folder-item-link"
                >
                  <img
                    className="folder-icon"
                    src={folder}
                    alt={intl.t('文件夹图标')}
                  />
                  <span className="folder-name">{data.breadcrumbName}</span>
                </Link>
              </li>
            );
          })}
        </ul>
      );
    }

    return (
      <div className={cls('cooper-subhead', `${className}`, { 'is-dc-subhead': isDC() })}>
        {title ? (
          <div className="cooper-subhead-title">
            {routes && routes.length > 0 ? (
              <Breadcrumb separator={<i className="icon-separator" />}>
                {ellipsisRoutes.map((route, key) => {
                  if (key === ellipsisRoutes.length - 1) {
                    return (
                      <Breadcrumb.Item key={key}>
                        {route.breadcrumbName}
                      </Breadcrumb.Item>
                    );
                  }

                  if (route.path === '...') {
                    return (
                      <Breadcrumb.Item key={key}>
                        <Popover
                          placement="bottomLeft"
                          overlayClassName="bread-crumb-container"
                          content={ellipsisContent}
                        >
                          <img
                            src={operate}
                            className="bread-omit"
                            style={{ width: 24, marginRight: 0 }}
                          />
                        </Popover>
                      </Breadcrumb.Item>
                    );
                  }

                  return (
                    <Breadcrumb.Item key={key}>
                      <Link
                        to={route.path}
                        title={route.breadcrumbName}>
                        {route.breadcrumbName}
                      </Link>
                    </Breadcrumb.Item>
                  );
                })}
              </Breadcrumb>
            ) : (
              <Breadcrumb separator={<i className="icon-separator" />}>
                <Breadcrumb.Item>{title}</Breadcrumb.Item>
              </Breadcrumb>
            )}
          </div>
        ) : null}

        {children}
      </div>
    );
  }
}

export default CooperSubHead;
