import { get, post } from '@/utils/request';
import api from '@/utils/request/api/DkApi';

export default async function getDKPermission(knowledgeId) {
  // let id = string62to10(knowledgeId);
  const res = await get(api.GET_DK_PERMISSION.replace(':knowledgeId', knowledgeId));
  return res;
}

export async function applyHigherPermFn(params) {
  const data = await post(api.APPLY_MEMBER_PERM.replace(':resourceId', params.resourceId), params);
  return data;
}

export async function applyOwnerPermCheck(params) {
  const res = await get(api.APPLY_PAGE_VERIFY.replace(':resourceId', params.resourceId), { params });
  return res;
}


export async function applyOwnerPermFn(params) {
  const res = await post(api.APPLY_PAGE.replace('{resourceId}', params.resourceId), params);
  return res;
}


