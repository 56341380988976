import { history } from '@/model';


export function findChecks(tree, isListView) {
  // 列表视图
  if (isListView) {
    return (tree.children || []).filter((ch) => ch.checkStatus === 1);
  }

  // 树视图
  if (tree.checkStatus === 1) {
    if (tree.level === 0) {
      return tree.children || [];
    }
    return [tree];
  }

  return (
    (tree.children || [])
      .map((item) => findChecks(item, isListView))
      .reduce((a, b) => [...a, ...b], [])
  );
}

export function findById(tree, id) {
  if (tree.id === id) return tree;

  for (const ch of tree.children || []) {
    const item = findById(ch, id);
    if (item) return item;
  }

  return null;
}

export function unselectAll(tree) {
  if (tree) tree.checkStatus = 0;

  for (const ch of tree.children || []) {
    unselectAll(ch);
  }
}

export function treeToList(tree, isListView) {
  if (isListView) {
    return tree.children || [];
  }

  const result = tree.level === 0 ? [] : [tree];
  if ((tree.level === 0 || tree.isOpen) && tree.children) {
    for (const child of tree.children) {
      result.push(...treeToList(child));
    }
  }
  return result;
}

export function getParentCheckStatus(parent) {
  const { children = [] } = parent;
  const checkCount = children.filter((item) => item.checkStatus === 1).length;

  if (checkCount === 0) return 0;
  if (checkCount === children.length) return 1;
  return 2;
}

export function handleFileClick(file, teamId, originFileType) {
  let params = null

  switch (file.resourceType) {
    // 普通文件
    // case 'FILE':
    //   params = {
    //     resourceId: file.id,
    //     resourceName: file.name,
    //     shareId: 0,
    //     shareLink: '',
    //     markedQuickVisit: file.marked_quick_visit,
    //   }
    //   previewFile(params);
    //   break;

    // 文件夹
    case 'DIR':
      // params = {
      //   resourceId: file.id,
      //   teamId,
      // }
      history.push(`/trash/${file.id}`);
      // openFolder(params);

      break;

      // 协作文档
      // case 'COOPER_DOC':
      // case 'COOPER_SHEET':
      // case 'COOPER_SLIDE':
      // case 'COOPER_ANONYMOUS_DOC':
      // case 'SHIMO2_WORD_PRO':
      // case 'SHIMO2_WORD':
      // case 'SHIMO2_EXCEL':
      // case 'SHIMO2_PPT':
      //   openCollob(file.id, file.mimeType);
      //   break;

      // 氢文档
      // case 'DI_DOC':
      //   openDidoc(file.id);
      //   break;

      // case 4:
      //   openFlowChart(file.id);
      //    break;

    default:
      break;
  }
}

