import CooperEmptyTip from '@/components/CooperEmptyTip';
import AuthTopFolder from '@/components/CooperOperation/AuthTopFolder';
import NoMore from '@/components/NoMore';
import OperateMenu from '@/components/OperateMenu';
import FileTableSkeleton from '@/components/SkeletonPage/common/FileTableSkeleton';
import SpinRender from '@/components/SpinRender';
import cooperConfirm from '@/components/common/CooperConfirm';
import FileEllipsis from '@/components/common/FileEllipsis';
import ImageEnlarger from '@/components/common/ImageEnlarger';
import PrimaryModal from '@/components/common/PrimaryModal';
import {
  FlowChart,
  PERSONAL_TEAM,
  TEAM_COOPER,
} from '@/constants/cooperConstants';
import {
  createGetFiles,
  getChildFiles,
  getResourceContact,
} from '@/service/cooper/personSpace';
import { setImgUrl } from '@/utils/cooperutils';
import miniBus from '@/utils/miniBus';
import { Button, Checkbox } from 'antd';
import classBind from 'classnames/bind';
import { intl } from 'di18n-react';
import { debounce, noop, throttle } from 'lodash-es';
import { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BatchOperate from '../BatchOperate/index';
import DragLayer from '../DragLayer/index';
import Li from '../DragLi/index';
import Resizer from '../Resizer/index';
import SortIcon from '../SortIcon/index';
import ViewSwitcher from '../ViewSwitcher';
import styles from './style.module.less';
import GetHtml from '@/utils/DOMPurify'
import {
  drageMoveFile,
  findById,
  findChecks,
  getParentCheckStatus,
  handleFileClick,
  treeToList,
  unselectAll,
} from './utils';

const PAGE_SIZE = 100;

const cx = classBind.bind(styles);
let getRootFiles = noop;

function FoldTree(props) {
  const {
    onUpload,
    viewType,
    originFileType,
    isShowSwitchView,
    switchView,
    fileOpreationType,
    isTeam,
    isOuter,
    location
  } = props;

  const navigate = useNavigate();
  // 标记是第一次渲染
  const [isInit, setInit] = useState(true);
  const [batchMode, setIsBatchMode] = useState(false); // fix:批量下切换空间时，底部按钮不消失问题
  // const [childLoading, setChildLoading] = useState(false)
  const [any, forceUpdate] = useReducer((num) => num + 1, 0);
  const [state, setState] = useState({
    root: {
      teamId: 0,
      id: 0,
      isRoot: true,
      level: 0,
      checkStatus: 0,
      children: [],
    },
    isEnd: false,
    nameWidth: 0,
    initialWidth: 0,
    originalWidth: 0,
    openedDir: [],
    isDraggingResizer: false,
    showNoAuthModal: false,
    loading: true,
  });
  const [pageOption, setPageOption] = useState({
    sortBy: props.sortBy,
    orderAsc: props.orderAsc,
  });
  const { root, nameWidth, isDraggingResizer } = state;
  const nameRef = useRef(null);
  let checks = [];

  const { userViewData, needPersonSpaceSkeleton, needTeamDetailSkeleton } = useSelector((s) => s.GlobalData);
  const { setUserViewDataRq, setNeedPersonSpaceSkeleton, setNeedTeamDetailSkeleton } = useDispatch().GlobalData;

  useEffect(() => {
    setState({
      ...state,
      loading: true,
      isEnd: false,
      root: {
        teamId: 0,
        id: 0,
        isRoot: true,
        level: 0,
        checkStatus: 0,
        children: [],
      },
    })
    setPageOption({
      sortBy: props.sortBy,
      orderAsc: props.orderAsc,
    })
    initFolder()
  }, [props.teamId, props.folderId]);

  useEffect(() => {
    if (props.sortBy !== undefined) {
      setPageOption({
        sortBy: props.sortBy,
        orderAsc: props.orderAsc,
      })
    }
  }, [props.sortBy, props.orderAsc])
  // 排序方式更改的时候去更新根节点的数据 此时需要过滤掉进来的第一次加载
  useEffect(() => {
    if (pageOption.sortBy !== undefined) {
      if (isInit) {
        setInit(false);
        initFolder();
      } else {
        _refreshRootNode();
      }
    }
  }, [pageOption.sortBy, pageOption.orderAsc]);

  // 判断视图类型
  const isListView = useCallback(() => {
    return viewType === 'LIST';
  }, [viewType]);

  // 判断当前排序方式
  const getSorterStatus = (itemSortBy, itemOrderAsc) => {
    return (
      pageOption.sortBy === itemSortBy
      && pageOption.orderAsc === itemOrderAsc
    );
  };

  // 判断当前文件是团队还是个人的
  const teamFileType = useCallback(() => {
    return originFileType == TEAM_COOPER;
  }, [originFileType]);

  // 拖拽名字宽度
  const resizeBegin = () => {
    setState({
      ...state,
      originalWidth: nameRef.current.clientWidth,
      isDraggingResizer: true,
    });
  };

  const resizing = throttle((offsetX) => {
    const nameWidth = state.originalWidth + offsetX;
    setState({
      ...state,
      nameWidth,
    });
  }, 16);

  const onEnd = () => {
    setState({
      ...state,
      isDraggingResizer: false,
    });
    const { onDragResizeEnd } = props;
    if (typeof onDragResizeEnd === 'function') {
      onDragResizeEnd();
    }
  };

  // 初始化
  const initFolder = async () => {
    const { folderId, teamId } = props;
    const root = {
      teamId,
      id: folderId,
      isRoot: true,
      level: 0,
      checkStatus: 0,
      children: [],
    };

    const { sortBy, orderAsc } = pageOption;

    if (sortBy === undefined || orderAsc === undefined) return;
    getRootFiles = createGetFiles(folderId, teamId, PAGE_SIZE, sortBy, orderAsc);

    const { result, isEnd } = await getRootFiles();
    if (isTeam) {
      setNeedTeamDetailSkeleton(false);
    } else {
      setNeedPersonSpaceSkeleton(false);
    }

    if (result?.errorCode === 1144 || result?.errorCode === 301082) {
      // 调用查询是否有联系对象接口
      if (teamId && folderId) {
        contactPerson(folderId);
      }
      setState({
        ...state,
        showNoAuthModal: true,
      });
      return;
    }

    result.length > 0
      && root.children.push(
        ...result.map((ch) => ({
          ...ch,
          level: 1,
          parent: root,
        })),
      );

    const { current } = nameRef;
    const nameWidth = current && current.clientWidth;

    setState({
      ...state,
      root,
      isEnd,
      nameWidth,
      loading: false,
      initialWidth: nameWidth,
    });
  };

  const toggleOpen = async (item) => {
    window.__OmegaEvent('ep_list_openclose_ck', '', {
      platform: 'new',
      source: isTeam ? 'team' : 'personal',
    });
    const { teamId } = props;

    let { openedDir } = state;
    item.isLoadChild = true
    // 加载子节点
    if (item.hasChild && !item.isOpen) {
      openedDir.push(item);
      setState({ ...state, openedDir });

      const children = await getChildFiles(item.id, teamId, pageOption);

      if (children.length > 0) {
        item.children = children.map((ch) => ({
          ...ch,
          level: item.level + 1,
          parent: item,
          checkStatus: item.checkStatus === 1 ? 1 : 0,
        }));
      } else {
        item.hasChild = false;
      }
    } else if (item.isOpen) {
      const index = openedDir.findIndex((cur) => item.id === cur.id);
      openedDir.splice(index, 1);
      setState({ ...state, openedDir });
    }
    item.isLoadChild = false
    // 更新
    item.isOpen = !item.isOpen;

    forceUpdate();
  };

  const toggleSort = async (sortBy, orderAsc) => {
    setPageOption({ sortBy, orderAsc })
    let obj = isTeam ? 'Team_SPACE_FILE' : 'MY_SPACE_FILE';
    const data = { ...userViewData };
    data[obj] = {
      ...userViewData[obj],
      sort: {
        sortBy,
        orderAsc,
      },
    }
    if (JSON.stringify(userViewData) === '{}') return;
    setUserViewDataRq(data);
  };

  const setBatchMode = (Mode) => {
    // 取消所有勾选
    if (!Mode) {
      const { root } = state;
      unselectAll(root);
    }

    const { setIsShowSwitchView } = props;

    setIsShowSwitchView(!Mode);

    setIsBatchMode(Mode);

    forceUpdate();
  };

  const toggleCheck = (item) => {
    // 更新自己
    const checkStatus = (
      item.checkStatus = item.checkStatus === 1 ? 0 : 1
    );

    // 更新父节点
    let p = item;
    while (p.parent) {
      p = p.parent;
      p.checkStatus = getParentCheckStatus(p);
    }

    // 更新子节点
    function _toggleCheck(_item) {
      if (_item !== item) {
        _item.checkStatus = checkStatus;
      }
      (_item.children || []).forEach((ch) => _toggleCheck(ch));
    }

    _toggleCheck(item);
    // 处理批量时的视图切换问题，触发渲染
    const { root } = state;
    const checks = findChecks(root, isListView());
    if (isListView()) {
      setBatchMode(checks.length > 0);
    } else {
      forceUpdate();
    }
  };

  const _refreshRootNode = async () => {
    const { root: item } = state;
    const { sortBy, orderAsc } = pageOption;

    if (sortBy === undefined || orderAsc === undefined) return;

    const pageSize = Math.max(PAGE_SIZE, item.children.length);

    getRootFiles = createGetFiles(
      props.folderId,
      props.teamId,
      pageSize,
      sortBy,
      orderAsc,
    );

    const { result = [], isEnd = true } = await getRootFiles();

    _mergeWithState(result, item);
    forceUpdate()
  };

  const _mergeWithState = (newList = [], parent) => {
    newList.forEach((ch) => {
      ch.level = parent.level + 1;
      ch.parent = parent;
    });

    newList.forEach((item, idx) => {
      const old = (parent.children || []).find((it) => it.id === item.id);
      if (old) {
        // 保持引用不变
        newList[idx] = Object.assign(old, item, {
          checkStatus: old.checkStatus,
          isOpen: old.isOpen,
          hasChild: old.hasChild,
          children: old.children,
        });
      }
    });

    parent.hasChild = newList.length > 0;
    parent.children = newList;
    parent.checkStatus = getParentCheckStatus(parent);
  };

  const batchRefreshWithState = async (checks) => {
    await baseBatchRefreshWithState(checks);

    refreshOpened(checks);
  };

  const baseBatchRefreshWithState = async (checks) => {
    checks.forEach((item) => refreshWithState(item));
    setBatchMode(false);
    // 再刷新根节点
    await _refreshRootNode();
  };

  const refreshWithState = async (item) => {
    if (item.isRoot) {
      return _refreshRootNode();
    }
    return _refreshChildNode(item.parent);
  };

  const _refreshChildNode = async (item) => {
    const { teamId } = props;
    const result = await getChildFiles(item.id, teamId, pageOption);
    _mergeWithState(result, item);
    forceUpdate();
  };

  const clickFile = (item, idx) => {
    const { originFileType } = props;
    if (batchMode === true) {
      toggleCheck(item);
      return;
    }

    const { teamId } = props;
    handleFileClick(item, teamId, originFileType, false, idx);
  };

  const operateCallback = async (item) => {
    // 拿不到目标位置，直接刷新本页面
    // 先刷新操作节点父级
    await refreshWithState(item);
    // 再刷新根节点
    await _refreshRootNode();
  };

  const setBatchModeOpe = (item) => {
    item.checkStatus = 1;
    item.parent.checkStatus = 2;
    setBatchMode(true);
  };

  // 刷新所有打开的节点
  const refreshOpened = (operateItem) => {
    // 刷新所有open的节点
    const { openedDir } = state;

    openedDir.forEach((item) => {
      const index = operateItem.findIndex((ope) => ope.id === item.id);
      if (index === -1) {
        _refreshChildNode(item);
      }
    });
  };

  // 滚动加载方法
  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop + clientHeight + 25 >= scrollHeight) {
      if (!state.isEnd) {
        loadMore();
      }
    }
  };
  // 加载更多
  const loadMore = debounce(
    async () => {
      setState({ ...state, loading: true });

      const { root: item } = state;
      const { result = [], isEnd = true } = await getRootFiles();

      _mergeWithState(result, item);
      setState({ ...state, isEnd });
    },
    300,
    { leading: true },
  );

  useEffect(() => {
    miniBus.addEventListener('upload', _refreshRootNode);
    miniBus.addEventListener('folderCreated', _refreshRootNode);

    return () => {
      miniBus.removeEventListener('uploaded', _refreshRootNode);
      miniBus.removeEventListener('folderCreated', _refreshRootNode);
    };
  });

  const getTeamListData = () => {
    initFolder();
  };

  // 拖拽排序操作
  const setVisitOrder = async (item, target) => {
    const { root } = state;

    if (target.type !== 1) {
      // 非文件夹
      target = target.parent;
    }

    item = findById(root, item?.id);
    target = findById(root, target?.id);

    const checks = findChecks(root, isListView());
    const items = checks.length > 0 ? checks : [item];

    drageMoveFile(items, target).then(() => {
      // 刷新起始节点
      const parents = [...new Set(items.map((ch) => ch.parent))];
      for (const parent of parents) {
        // 串行刷新，避免重复节点
        _refreshChildNode(parent);
      }

      // 刷新目标节点
      target.hasChild = 1;
      _refreshChildNode(target);
      window.__OmegaEvent('ep_list_dragmove_ck', '', {
        platform: 'new',
        source: isTeam ? 'team' : 'personal',
      });
    });

    // 刷新起始节点
    // const parents = [...new Set(items.map((ch) => ch.parent))];
    // for (const parent of parents) {
    //   // 串行刷新，避免重复节点
    //   await _refreshChildNode(parent);
    // }
  };

  // 关闭权限弹窗
  const closeNoAuthModal = () => {
    setState({
      ...state,
      showNoAuthModal: false,
    });
    goRootFolder();
  };
  // 回到根页面
  const goRootFolder = () => {
    const { teamId } = props;
    if (teamId) {
      navigate(`/team-file/${teamId}`);
      return;
    }
    navigate('/disk');
  };

  // 联系有权限人
  const contactPerson = async (folderId) => {
    const res = await getResourceContact(folderId);
    const { email, ldap, name } = res;
    if (email && ldap && name) {
      cooperConfirm({
        type: 'warn',
        title: intl.t('你无权访问该文件夹'),
        content: (
          <span>
            {intl.t('该文件夹被单独设置了权限，如需访问，可联系')}
            <span
              className={cx('content-tip')}
              onClick={() => {
                window.location.href = `dchat://im/start_conversation?name=${ldap}`;
                window.__OmegaEvent(
                  'ep_teamspace_visitsettedfolder_contract_ck',
                );
              }}
            >
              {name}( {ldap} )
            </span>
            {intl.t('，他可在文件夹右侧【...】-【协作】处进行成员权限管理')}
          </span>
        ),
        isShowFooterCancel: false,
        onDestroy: () => modifyContact(),
        onOk: () => modifyContact(),
        okText: intl.t('确认'),
      });
    } else {
      cooperConfirm({
        type: 'warn',
        title: intl.t('你无权访问该文件夹'),
        content: (
          <span>
            {intl.t('该文件夹被单独设置了权限，如需访问，可联系')}
            <span
              className={cx('content-tip')}
              onClick={() => {
                window.location.href = 'https://im.xiaojukeji.com/channel?uid=7135&token=94094275f671c1cf7157d89dbe36abd8&id=146847933529642240';
                window.__OmegaEvent(
                  'ep_teamspace_visitsettedfolder_contractcooper_ck',
                );
              }}
            >
              {intl.t('Cooper反馈群')}
            </span>
            {intl.t('的人工客服')}
          </span>
        ),
        isShowFooterCancel: false,
        onDestroy: () => modifyContact(),
        onOk: () => modifyContact(),
        okText: intl.t('确认'),
      });
    }
  };

  const modifyContact = () => {
    const { teamId } = props;
    navigate(`/team-file/${teamId}`);
  };

  const teamManage = (item) => {
    window.isChildFile = item.parent_id;
    AuthTopFolder(
      item.id,
      props.teamId,
      item.display_name,
      _refreshRootNode,
      false,
      initFolder,
      getTeamListData,
    );
    window.__OmegaEvent('ep_teamspace_more_permisionset_ck', '', {
      platform: 'new',
    });
  };

  const config = { multiSelectOperate: !isListView() };
  const fileList = treeToList(state.root, isListView());

  checks = findChecks(root, isListView()).filter(
    (checkboxItem) => checkboxItem.type !== FlowChart && checkboxItem.mime_type !== 9,
  );

  if (state.isEnd && fileList.length === 0) {
    return (
      <div className={cx('folder-tree')}>
        <CooperEmptyTip
          title={intl.t('还没有内容哦')}
          prefixText={intl.t('你可以将文件拖拽到这里，或者')}
          callbackText={intl.t('上传文件1')}
          callback={onUpload}
        />
      </div>
    );
  }

  return (
    ((!isTeam && needPersonSpaceSkeleton) || (isTeam && needTeamDetailSkeleton)) ? (
      <>
        <div
          style={{ padding: '0 24px 0 24px' }}
          dangerouslySetInnerHTML={{ __html: GetHtml(FileTableSkeleton) }} />
      </>
    ) : (
      <div
        className={cx({
          'folder-tree': true,
          'list-view': isListView(),
          'batch-mode': batchMode,
          'person-list': !teamFileType(),
        })}
      >
        <div className={cx('tb-header')}>
          <div className={cx('tb-header-div')}>
            <span
              className={cx('file-name')}
              ref={nameRef}
              style={nameWidth > 0 ? { width: `${nameWidth}px` } : {}}
            >
              {(batchMode || isListView())
                && <div className={cx('checkbox-wrap')}>
                  <Checkbox
                    indeterminate={root.checkStatus === 2}
                    checked={root.checkStatus === 1}
                    onChange={() => toggleCheck(root)} />
                </div>
              }

              <span>
                {checks.length > 0
                  ? intl.t('已选中{one}项', {
                    one: `${checks.length}`,
                  })
                  : intl.t('名称')}
              </span>
              {!batchMode && (
                <SortIcon
                  iconUp={getSorterStatus('display_name', 1)}
                  iconDown={getSorterStatus('display_name', 0)}
                  sort={(value) => toggleSort('display_name', Number(value))}
                />
              )}
            </span>
            <span className={cx('file-resizer')}>
              <Resizer
                onBegin={resizeBegin}
                onResize={resizing}
                onEnd={onEnd} />
            </span>
            {teamFileType() && (
              <span className={cx('file-owner')}>{intl.t('所有者')}</span>
            )}

            <span className={cx('file-time')}>
              <span>{intl.t('更新时间')}</span>
              {!batchMode && (
                <SortIcon
                  iconUp={getSorterStatus('modify_time', 1)}
                  iconDown={getSorterStatus('modify_time', 0)}
                  sort={(value) => toggleSort('modify_time', Number(value))}
                />
              )}
            </span>
            <span className={cx('file-operate')}>
              {isShowSwitchView && (
                <ViewSwitcher
                  viewType={viewType}
                  onSwitch={switchView} />
              )}
            </span>
          </div>
        </div>
        <DndProvider backend={HTML5Backend}>
          <DragLayer num={checks.length || 1} />
          <div
            className={cx('tb-body', 'os-scrollbar')}
            onScroll={handleScroll}>
            {fileList.map((item, idx) => {
              return (
                <Li
                  key={idx}
                  idx={item}
                  switchOrder={(item, target) => setVisitOrder(item, target)}
                  className={cx('tb-body-row')}
                >
                  <span
                    className={cx('file-name')}
                    style={{
                      paddingLeft: Math.min(
                        (item.level - 1) * 40,
                        Math.max(nameWidth, 300),
                      ),
                      width: nameWidth || '',
                    }}
                  >
                    {(batchMode || isListView())
                      && <div className={cx('checkbox-wrap')}>
                        <Checkbox
                          disabled={item.type === FlowChart && item.mime_type === 9}
                          indeterminate={item.checkStatus === 2}
                          checked={item.checkStatus === 1}
                          onChange={() => toggleCheck(item)}
                        />
                      </div>

                    }

                    {!isListView() && (
                      <div
                        onClick={() => toggleOpen(item)}
                        className={cx('ft-triangle-wrapper')}
                      >
                        {/* <i
                        className={cx({
                          triangle: true,
                          'triangle-li': true,
                          'is-open': item.isOpen,
                        })}
                        style={{ opacity: item.hasChild ? 1 : 0 }}
                      /> */}
                        {/* className='dk-iconfont dk-icon-shouqi' */}
                        <i
                          className={cx({
                            'dk-iconfont': true,
                            'dk-icon-jiantouyou': !item.isLoadChild,
                            'dk-icon-shuaxin1': item.isLoadChild,
                            'is-loading': item.isLoadChild,
                            'is-open': item.isOpen,
                            'triangle-li': true,
                            triangle: true,
                          })}
                          style={{ opacity: item.hasChild ? 1 : 0 }}
                        />
                      </div>
                    )}

                    <ImageEnlarger
                      src={setImgUrl(item)}
                      isTiny={!!item.tiny}
                      mimeType={item.mime_type || item.mimeType}
                      resourceType={item.space_resource_type}
                    />
                    <div
                      onClick={() => clickFile(item, idx)}
                      className={cx('file-name-display')}
                      key={new Date()}
                    >
                      <FileEllipsis
                        value={item.name}
                        isShowStar={true}
                        doneCallback={() => _refreshChildNode(item.parent)}
                        record={item}
                        originFileType={originFileType}
                        isOuter={isOuter}
                      />
                    </div>
                  </span>
                  <div
                    className={cx('file-resizer')}
                    style={{ height: 50 }}>
                    <div
                      className={cx('resizer-line')}
                      style={{ display: isDraggingResizer ? 'block' : 'none' }}
                    />
                  </div>
                  {teamFileType() && (
                    <div className={cx('file-owner')}>{item.owner}</div>
                  )}

                  <div className={cx('file-time')}>{item.updateTime}</div>
                  {!batchMode && (
                    <span className={cx('file-operate')}>
                      <OperateMenu
                        key={new Date()}
                        isTeam={isTeam}
                        teamId={props.teamId}
                        file={item}
                        config={config}
                        fileOpreationType={fileOpreationType}
                        doneCallback={() => operateCallback(item)}
                        originFileType={PERSONAL_TEAM}
                        teamManage={() => teamManage(item)}
                        setBatchMode={() => setBatchModeOpe(item)}
                        isFlowChart={
                          item.type === FlowChart && item.mime_type === 9
                        }
                        uniqueCallback={{
                          moveOperate: () => refreshOpened([item]),
                          copyOperate: () => refreshOpened([item]),
                        }}
                        isOuter={isOuter}
                        location={location}
                      />
                    </span>
                  )}
                </Li>
              );
            })}

            <li className={cx('bottom-tip')}>
              {state.isEnd ? (
                <NoMore />
              ) : (
                <SpinRender loading={state.loading} />
              )}
            </li>
          </div>
        </DndProvider>
        {/* 批量操作处理逻辑 */}
        {(batchMode || checks.length > 0) && (
          <BatchOperate
            checks={checks}
            doneCallback={() => batchRefreshWithState(checks)}
            deleteCallback={() => baseBatchRefreshWithState(checks)}
          >
            <Button
              className={cx('batch-cancel')}
              onClick={() => setBatchMode(false)}
            >
              {intl.t(' 取消')}
            </Button>
          </BatchOperate>
        )}

        {/* 此时是无权限页面弹窗 */}
        {state.showNoAuthModal && !props.teamId && (
          <PrimaryModal
            title={intl.t('您无权限访问')}
            onOk={closeNoAuthModal}
            onCancel={closeNoAuthModal}
            selfClassName="folderTree-noAuthModal"
            cancelText=""
          >
            <div className="unboundDKModal">
              {intl.t(
                '该链接为他人个人空间的访问地址，您无权访问。可联系分享者获取文件的分享链接进行访问，分享链接获取方式：个人空间列表-【…】更多操作-分享-全员分享-创建链接-复制链接',
              )}
            </div>
          </PrimaryModal>
        )}
      </div>)
  );
}

export default FoldTree;
