import classnames from 'classnames/bind';
import styles from './style.module.less';
import { useState } from 'react';
import { intl } from 'di18n-react';

const cls = classnames.bind(styles);
const Popover = ({ tips, pageStyle, changePageStyle }) => {
  const [data, setData] = useState(pageStyle);

  const toggleFontSize = (fontSize) => {
    if (data.fontSize !== fontSize) {
      const state = {
        ...data,
        fontSize,
      };
      setData(state);
      window.postMessage({
        type: 'dk:PageStyle',
        fontSize,
      });
      changePageStyle({ fontSize });
    }
  };

  const toggleScreen = (screen) => {
    if (data.screen !== screen) {
      const state = {
        ...data,
        screen,
      };
      setData(state);
      window.postMessage({
        type: 'dk:PageStyle',
        screen,
      });
      changePageStyle({ screen });
    }
  }

  return (
    <div className={cls('page-style-popover')}>
      <div className={cls('title')}>{intl.t('正文字号')}</div>
      <div className={cls('content')}>
        <div
          className={cls('tab', 'font-size', { active: data.fontSize === 14 })}
          onClick={() => toggleFontSize(14)}
        >
          <i className={cls('dk-iconfont', 'dk-icon-wenziyanse1', 'font-size-14')}/>
          <div className={cls('text')}>{intl.t('小字14px')}</div>
        </div>
        <div
          className={cls('tab', { active: data.fontSize === 16 })}
          onClick={() => toggleFontSize(16)}
        >
          <i className={cls('dk-iconfont', 'dk-icon-wenziyanse1', 'font-size-16')}/>
          <div className={cls('text')}>{intl.t('大字16px')}</div>
        </div>
      </div>
      <hr/>
      <div
        className={cls('title')}
      >
        {intl.t('页面宽度')}
      </div>
      <div className={cls('content')}>
        <div
          className={cls('tab', 'page-width', { active: data.screen })}
          onClick={() => toggleScreen(1)}
        >
          <i className={cls('narrow-screen-icon')}/>
          <div className={cls('text')}>{intl.t('窄屏模式')}</div>
          <div className={cls('summary')}>{intl.t('适合高效阅读')}</div>
        </div>
        <div
          className={cls('tab', { active: !data.screen })}
          onClick={() => toggleScreen(0)}
        >
          <i className={cls('wide-screen-icon')}/>
          <div className={cls('text')}>{intl.t('超宽模式')}</div>
          <div className={cls('summary')}>{intl.t('可展示更多信息')}</div>
        </div>
      </div>
      <div className={cls('tips')}>
        <i className={cls('icon', 'dk-iconfont', 'dk-icon-tishi')}/>
        <span>{tips}</span>
      </div>
    </div>
  );
}

export default Popover;
