import { intl } from 'di18n-react';

export const GLOBAL_PERM = 'GLOBAL_PERM';
export const MULTI_PERM = 'MULTI_PERM';
export const NO_ADMIN_PERM = 'NO_ADMIN_PERM';

export const GLOBAL_PERM_DK = 'GLOBAL_PERM_DK';
export const NO_ADMIN_PERM_DK = 'NO_ADMIN_PERM_DK';

export const TEAM_SPACE = 'TEAM_SPACE';
export const PERSONAL_SPACE = 'PERSONAL_SPACE';

export const DOCS_INVITE_TYPE = 1;

export const cooperOptionGlobal = () => [
  {
    label: intl.t('查看'),
    desc: '',
    value: '1',
    perm: 1,
    checked: false,
    multiple: true,
  },
  {
    label: intl.t('编辑'),
    value: '2',
    desc: '',
    perm: 2,
    checked: false,
    multiple: true,
  },
  // {
  //   label: intl.t('上传'),
  //   value: '8',
  // desc: '',
  //   perm: 8,
  //   checked: false,
  //   multiple: true,
  // },
  // {
  //   label: intl.t('分享'),
  //   value: '16',
  // desc: '',
  //   perm: 16,
  //   checked: false,
  //   multiple: true,
  // },
  {
    label: intl.t('下载'),
    desc: '',
    value: '32',
    perm: 32,
    checked: false,
    multiple: true,
  },
  {
    label: intl.t('文档管理员'),
    desc: intl.t('可添加协作成员、分享文档'),
    value: 'admin',
    perm: 63,
    checked: false,
    multiple: false,
  },
  {
    label: intl.t('文档所有者'),
    value: 'owner',
    perm: 63,
    desc: intl.t('可添加协作成员、分享文档、设置管理员'),
    checked: false,
    multiple: false,
  },
];

export const cooperOptionMulti = () => [
  {
    label: intl.t('查看'),
    desc: '',
    value: '1',
    perm: 1,
    checked: false,
    multiple: true,
  },
  {
    label: intl.t('编辑'),
    value: '2',
    desc: '',
    perm: 2,
    checked: false,
    multiple: true,
  },
  // {
  //   label: intl.t('上传),
  //   value: '8',
  // desc: '',
  //   perm: 8,
  //   checked: false,
  //   multiple: true,
  // },
  // {
  //   label: intl.t('分享'),
  //   value: '16',
  // desc: '',
  //   perm: 16,
  //   checked: false,
  //   multiple: true,
  // },
  {
    label: intl.t('下载'),
    desc: '',
    value: '32',
    perm: 32,
    checked: false,
    multiple: true,
  },
];

export const cooperOptionNoAdmin = () => [
  {
    label: intl.t('查看'),
    desc: '',
    value: '1',
    perm: 1,
    checked: false,
    multiple: true,
  },
  {
    label: intl.t('编辑'),
    value: '2',
    desc: '',
    perm: 2,
    checked: false,
    multiple: true,
  },
  // {
  //   label: intl.t('上传'),
  //   value: '8',
  // desc: '',
  //   perm: 8,
  //   checked: false,
  //   multiple: true,
  // },
  // {
  //   label: intl.t('分享'),
  //   value: '16',
  // desc: '',
  //   perm: 16,
  //   checked: false,
  //   multiple: true,
  // },
  {
    label: intl.t('下载'),
    desc: '',
    value: '32',
    perm: 32,
    checked: false,
    multiple: true,
  },
  {
    label: intl.t('文档所有者'),
    desc: intl.t('可添加协作成员、分享文档、设置管理员'),
    value: 'owner',
    perm: 72,
    checked: false,
    multiple: false,
  },
];

export const DkOptionGlobal = () => [
  {
    value: 'PAGE_READ_MEMBER',
    roleKeyFront: 1,
    label: intl.t('只读成员'),
    desc: '',
    multiple: false,
    checked: false,
  }, {
    value: 'PAGE_MEMBER',
    roleKeyFront: 2,
    label: intl.t('成员'),
    desc: '',
    multiple: false,
    checked: false,
  }, {
    value: 'PAGE_ADMIN',
    roleKeyFront: 3,
    label: intl.t('管理员'),
    desc: '',
    multiple: false,
    checked: false,
  }, {
    value: 'PAGE_OWNER',
    roleKeyFront: 4,
    label: intl.t('所有者'),
    desc: '',
    multiple: false,
    checked: false,
  },
]

export const DkOptionNoAdmin = () => [
  {
    value: 'PAGE_READ_MEMBER',
    roleKeyFront: 1,
    label: intl.t('只读成员'),
    desc: '',
    multiple: false,
    checked: false,
  }, {
    value: 'PAGE_MEMBER',
    roleKeyFront: 2,
    label: intl.t('成员'),
    desc: '',
    multiple: false,
    checked: false,
  },
]


