import { Input } from 'antd';
import EmptyRender from '@/components/SpinRender';
import classBind from 'classnames/bind';
import TagListNode from '@/components/serviceComponents/PageTag/TagListNode';
import { checkErrorCharacter } from '@/utils';
import useDebounceFn from '@/hooks/useDebounceFn';
import styles from './style.module.less';


const cx = classBind.bind(styles);

export default function DebounceSelect({
  fetchOptions,
  notFoundContent = <div style={{ textAlign: 'center', padding: 4, color: '#bbb' }}>暂无数据</div>,
  ...props
}) {
  const [fetching, setFetching] = React.useState(false);
  const [options, setOptions] = React.useState(props.data || []);
  const [inputValue, setInputValue] = React.useState('');
  const fetchRef = React.useRef(0);
  const [nameCheckMsg, setNameCheckMsg] = React.useState('');

  const loadOptions = (value) => {
    fetchRef.current += 1;
    const fetchId = fetchRef.current;
    setOptions([]);
    setFetching(true);

    fetchOptions(value).then((newOptions) => {
      if (fetchId !== fetchRef.current) {
        return;
      }
      setOptions(newOptions || []);
      setFetching(false);
    });
  };

  const { run: debounceReq } = useDebounceFn(loadOptions);

  const onChange = (e) => {
    const { value } = e.target;
    let checkMsg = checkErrorCharacter(value);

    if (value.length > props.maxLength) {
      checkMsg = `最多支持${props.maxLength}个字符`;
    }

    setNameCheckMsg(checkMsg);

    setInputValue(value);

    if (checkMsg === '') {
      debounceReq(value);
    }
  };


  const onPressEnter = (e) => {
    if (props.onPressEnter) {
      props.onPressEnter(e.target.value);
      setInputValue('');
    }
  };


  const onChoose = (v) => {
    props.onSelect(v);
    setInputValue('');
  };


  return (
    <div className={cx('search-popover-content')}>
      {props.showSearch && <i className={`${cx('dk-icon-sousuo', 'dk-iconfont')} ${cx('switch-search-icon')}`} />}
      <Input
        className={cx('switch-search-input')}
        value={inputValue}
        autoFocus
        onChange={onChange}
        placeholder={props.placeholder}
        onPressEnter={onPressEnter}
      />
      {
        nameCheckMsg !== '' && <div className={cx('check-msg')}>{nameCheckMsg}</div>
      }
      {
        nameCheckMsg === '' && options.length
          ? <ul>
            {options.map((v) => (
              <React.Fragment key={v.id}>
                <TagListNode
                  nodeData={v}
                  onClick={() => onChoose(v)} />
              </React.Fragment>))}
          </ul>
          : <>
            {fetching
              ? <EmptyRender loading={true} />
              : notFoundContent}
          </>
      }
    </div>
  );
}
