import React from 'react';
import { intl } from 'di18n-react';
import './index.less';

const EmptyInfo = ({ desc = intl.t('暂无搜索结果') }) => {
  return (
    <div className="emptyInfo">
      <img
        className="pic"
        src={require('../icon/empty13.png')} />
      {intl.t(desc)}
    </div>
  );
};

export default EmptyInfo;
