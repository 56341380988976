import { intl } from 'di18n-react'
import React, { useState } from 'react'
// import ReactTooltip from 'react-tooltip'
import { Tooltip } from 'antd';


const ToFullComment = () => {
  let scrollDoc = document.querySelector('.cooper-component-comment')
  scrollDoc?.scrollIntoView({ behavior: 'smooth' })
}
export default () => {
  return (
    <React.Fragment>
      <Tooltip
        multiline
        title={intl.t('全文评论')}
        className="tooltip"
        place="left"
        effect="solid"
        backgroundColor="rgba(0, 0, 0, 0.8)"
        border={false}
        arrowColor="rgba(0, 0, 0, 0.75)"
      >
        <div
          className='icon-box'
          onClick={() => ToFullComment()}>
          <i className='dk-iconfont dk-icon-quanwenpinglun' />
        </div>
      </Tooltip>
    </React.Fragment>
  )
}
