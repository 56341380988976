
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import { apolloSwitch } from '@/utils/ab'
import VersionComparison from './VersionComparison';
import styles from './style.module.less';

const cls = classNames.bind(styles);
function Header() {
  const [versionComparison, setVersionComparison] = useState(null);
  const initVersionComparison = async () => {
    const data = await apolloSwitch('editor_doc_diff');
    setVersionComparison(data)
  }
  useEffect(() => {
    initVersionComparison()
  }, [])
  return (
    <div className={cls('header')}>
      {versionComparison
        ? <VersionComparison/>
        : null
      }
    </div>
  );
}

function mapStateToProps({ historyVersion, pageDetail }) {
  const { docInfo } = pageDetail;
  const { view, versionList, currentVersionIndex } = historyVersion;
  return {
    view,
    docInfo,
    versionList,
    currentVersionIndex,
  };
}

function mapDispatchToProps({ historyVersion, pageDetail }) {
  const { revertVersion, toggleHistoryVersionVisible, changeComparisonVersion, getVersionList } = historyVersion;
  const { getPageDetail } = pageDetail;
  return {
    revertVersion,
    toggleHistoryVersionVisible,
    changeComparisonVersion,
    getPageDetail,
    getVersionList,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
