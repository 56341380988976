import { intl } from 'di18n-react';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useParams } from 'react-router-dom';
import { TAG_SOURCE_TYPE, TREE_VIEW_TYPE } from '@/constants/index';
import usePermission from '@/hooks/usePermission';
import { Tooltip } from 'antd';
import classBind from 'classnames/bind';
// import LayoutContext from '@/components/serviceComponents/Layout/layoutContext';
import styles from './style.module.less';

const cx = classBind.bind(styles);

const TagManageContent = React.memo(({ tagsData, pageId }) => {
  const dispatch = useDispatch();
  const { setTreeViewType } = dispatch.KnowledgeData;
  const { updateDataForDel, setState } = dispatch.TagTree;
  const { delPageTags } = dispatch.pageDetail;

  // const { tagId } = useSelector((state) => state.TagTree);
  const { treeViewType, permission } = useSelector(
    (state) => state.KnowledgeData,
  );
  const { checkOperationPermission } = usePermission();

  // const { knowledgeId } = useContext(LayoutContext);

  const hasAsidePerm = useMemo(() => {
    return checkOperationPermission('READ_DK', permission.perm);
  }, [permission]);

  const delTag = async (e, target) => {
    e.stopPropagation();
    let param = {
      pageId,
      tagId: target.id,
    };
    delPageTags(param).then(() => {
      if (hasAsidePerm && treeViewType === TREE_VIEW_TYPE.TAG) {
        // 实时更新左侧标签
        updateDataForDel(param);
      }
    });

    window.__OmegaEvent('ep_dkpc_tag_manage_delete_ck');
  };

  const linkToTagView = (e, linkTagId) => {
    e.stopPropagation();
    setTreeViewType(TREE_VIEW_TYPE.TAG);

    setState({
      choosePageMsg: {
        parentTagId: linkTagId,
        pageId,
      },
    });

    window.__OmegaEvent('ep_dkpc_tag_manage_view_ck');
  };

  const hasSafetyTag = tagsData.length > 0
    && tagsData[0].sourceType === TAG_SOURCE_TYPE.SYSTEM_SECURE;

  return (
    <div className={cx('tags-manage-container')}>
      <h1 className={cx('title')}>
        <span>
          {tagsData.length}
          {intl.t('个标签')}
        </span>
        <Tooltip
          title={
            <p>
              {intl.t(
                '自定义标签：页面成员手动添加的标签。知识库所有者、管理员、页面所有者可管理所有标签；页面「成员」角色只能管理自己添加的标签',
              )}
              <br />
              <br />
              {intl.t('系统标签：系统扫描判定的页面数据安全等级，不支持管理')}
            </p>
          }
        >
          <i
            className={cx('dk-icon-a-bangzhuzhongxin4px', 'dk-iconfont', 'tag-help')}
          />
        </Tooltip>
      </h1>
      <div className={cx('content')}>
        {hasSafetyTag && (
          <button
            className={cx('content-name-btn', 'safety-level')}
            key={tagsData[0].id}
            onClick={(e) => linkToTagView(e, tagsData[0].id)}
          >
            <i
              className={cx(
                'dk-icon-a-bianzubeifen3',
                'dk-iconfont',
                'safety-level-icon',
              )}
            />
            <span>
              {intl.t('数据等级C')}
              {tagsData[0].name}
            </span>
          </button>
        )}

        {tagsData.map((item, index) => {
          if (hasSafetyTag && index === 0) return null;

          return (
            <button
              className={cx('content-name-btn')}
              key={item.id}
              onClick={(e) => linkToTagView(e, item.id)}
            >
              {item.name}
              {item.ownPerm && (
                <Tooltip title={intl.t('删除标签')}>
                  <i
                    className={cx('dk-icon-guanbi', 'dk-iconfont', 'tag-del')}
                    onClick={(e) => delTag(e, item)}
                  />
                </Tooltip>
              )}
            </button>
          );
        })}
      </div>
    </div>
  );
});

export default TagManageContent;
