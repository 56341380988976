import React, { useMemo, useContext, useEffect, useState } from 'react';
import { intl } from 'di18n-react';
import { message } from 'antd';
import { getPermissionOptions, PERM_DEFAULT_LIST } from '../../contants';
import DropdownCheckbox from '@/components/common/DropdownCheckbox';
import TeamContext from '@/components/CooperFoldAuth/TeamContext';
import {
  changeRoleForPerm,
  changeRoleForRole,
  changeRoleForRemoveUser,
  getRemoveOperator,
} from '../../service';
import { roleMap } from '../../util';
import './index.less';

const SOURCE_MAP = () => {
  return {
    ALONE_PERM: intl.t('当前文件夹单独授权'),
    EXTEND_PARENT: intl.t('继承自父文件夹'),
    NO_SHOW: '',
  }
};

const UserPermSelect = ({ memberInfo, refresh }) => {
  const {
    sources,
    role,
    canOperate,
    perm,
    cooperate_with_cn: name,
    id,
    orgMemberId,
  } = memberInfo;
  const { resourceId, userRole } = useContext(TeamContext);
  const [removeStatus, setRemoveStatus] = useState();
  const permList = useMemo(() => {
    let perms = [];
    for (let i = 0; i < PERM_DEFAULT_LIST.length; i++) {
      if (perm & PERM_DEFAULT_LIST[i]) perms.push(i); // eslint-disable-line no-bitwise
    }
    return perms;
  }, [perm]);

  const folderPermsDefault = getPermissionOptions();

  // 修改权限 ----查看删除等
  const onCheckboxGroupChange = (value) => {
    let parmArr = value[0] === 0 ? value : [0].concat(value);
    let permNum = null;
    parmArr.forEach((v) => {
      permNum += folderPermsDefault[v].perm;
    });

    changeRoleForPerm({
      resourceId,
      perm: permNum,
      orgMemberId,
      id,
    }).then(() => {
      refresh();
      message.success(intl.t('更新权限成功'));
    });
    window.__OmegaEvent('ep_teamspace_more_permisionset_modify_ck');
  };

  const onManageClick = (item, isAdd) => {
    changeRoleForRole({
      resourceId,
      orgMemberId,
      id,
      role: isAdd ? 'RESOURCE_ADMIN' : 'RESOURCE_MEMBER',
    }).then(() => {
      refresh();
      message.success(intl.t('更新权限成功'));
    });
    isAdd
      ? window.__OmegaEvent('ep_teamspace_more_permisionset_admin_ck')
      : window.__OmegaEvent('ep_teamspace_more_permisionset_canceladmin_ck');
  };

  const onClickRemove = (_, isRecover) => {
    changeRoleForRemoveUser({
      resourceId,
      orgMemberId,
      id,
    }).then(() => {
      refresh();
      message.success(intl.t('更新权限成功'));
    });
    window.__OmegaEvent(
      isRecover
        ? 'ep_teamspace_more_permisionset_recover_ck'
        : 'ep_teamspace_more_permisionset_remove_ck',
    );
  };

  useEffect(() => {
    getActions(memberInfo);
  }, [removeStatus]);

  const getActions = (item) => {
    const res = [];
    if (sources === 'ALONE_PERM') {
      if (removeStatus) {
        res.push({
          label: intl.t('移除成员'),
          handle: () => {
            onClickRemove(item);
          },
          isRed: true,
        });
      } else if (removeStatus === false) {
        res.push({
          label: intl.t('该用户权限已被修改可恢复继承'),
          tip: intl.t('恢复'),
          placement: 'top',
          handle: () => {
            onClickRemove(item, true);
          },
        });
      }
    }

    if (userRole === 'SUPER_ADMIN') {
      if (roleMap[role] === 'MEMBER') {
        res.unshift({
          label: intl.t('设为管理员'),
          handle: () => {
            onManageClick(item, true);
          },
        });
      } else {
        res.unshift({
          label: intl.t('取消管理员'),
          handle: () => {
            onManageClick(item, false);
          },
        });
      }
    }
    return res;
  };

  const onOutsideCloseForChange = (value, hasChangePerm) => {
    if (hasChangePerm) {
      onCheckboxGroupChange(value);
    }
  };

  const getPermListOnclick = () => {
    const params = {
      principalMemberId: memberInfo.principalMemberId,
      principalType: memberInfo.principalType,
      resourceId,
    };
    getRemoveOperator(params).then((res) => {
      const status = res === 'REMOVE';
      setRemoveStatus(status);
    });
  };

  return (
    <div
      className={`user-item-right ${
        canOperate ? '' : 'user-item-right-disabled'
      } ${SOURCE_MAP()[sources] !== '' ? 'user-item-right-bottom' : ''} ${
        userRole === 'MEMBER' ? 'none-operate' : ''
      } `}
    >
      <DropdownCheckbox
        isNewStyle={true}
        options={roleMap[role] === 'ADMIN' ? [] : folderPermsDefault}
        placeholder={roleMap[role] === 'ADMIN' ? intl.t('管理员') : null}
        removeStatus={removeStatus}
        actions={getActions(memberInfo)}
        value={permList}
        getPermListOnclick={getPermListOnclick}
        onChange={() => {}}
        changeDisabled={canOperate === false}
        onOutsideCloseForChange={onOutsideCloseForChange}
      />

      {SOURCE_MAP()[sources] !== '' && (
        <div className="right-bottom">{intl.t(SOURCE_MAP()[sources])}</div>
      )}
      {SOURCE_MAP()[sources] !== '' && (
        <div className="right-bottom empty-sub-bottom">
          {intl.t(SOURCE_MAP()[sources])}
        </div>
      )}
    </div>
  );
};

export default UserPermSelect;
