import { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames/bind';
import { Modal, Spin } from 'antd';
import LayoutContext from '@/components/serviceComponents/Layout/layoutContext';
import Header from './Header';
import Editor from './Editor';
import VersionList from './VersionList';
import Preview from '@/components/serviceComponents/Preview';
import { isPreviewFile } from '@/utils';
import styles from './style.module.less';
import { ModelDetel } from '@/assets/icon'

const cls = classnames.bind(styles);

function HistoryRecordModal(props) {
  const {
    initLoading,
    historyVersionVisible,
    toggleHistoryVersionVisible,
    getVersionList,
    changeInitLoading,
    currentVersion = {},
    isEdit,
  } = props;
  const { pageId } = useContext(LayoutContext);
  const { resourceType, version, title, resourceSize } = currentVersion;
  const isPreview = isPreviewFile(resourceType);
  const [owner, setOwner] = useState(0)
  const [listLoading, setListLoading] = useState();


  const initVersionList = () => {
    changeInitLoading(true);
    setListLoading(true)
    getVersionList({ pageId, owner })
      .then((data) => {
        if (Array.isArray(data)) {
          changeInitLoading(false);
        }
      })
      .catch(() => {
        changeInitLoading(false);
      }).finally(() => {
        setListLoading(false);
      });
  };


  useEffect(() => {
    historyVersionVisible && initVersionList();
  }, [historyVersionVisible]);

  return (
    <Modal
      width={'auto'}
      wrapClassName={ cls('history-version-modal') }
      title={null}
      visible={historyVersionVisible}
      onCancel={toggleHistoryVersionVisible}
      footer={null}
      centered
      destroyOnClose
      closeIcon={(<img
        src={ModelDetel}
        className={cls('history-close')}/>)}
    >
      <div className={cls('history-version')}>
        <Header
          isPreview={isPreview}
          isEdit={isEdit}/>
        <div className={cls('history-container')}>
          {
            initLoading && (
              <div className={'page-detail-loading'}>
                <Spin/>
              </div>
            )
          }
          {isPreview
            ? <>{version && <Preview
                hasHeader={false}
                historyFileName={title}
                historyFileSize={resourceSize}
                historyFileId={version}/>}</>
            : <Editor />}
        </div>
        <VersionList
          isPreview={isPreview}
          isEdit={isEdit}
          loading={listLoading}
        />
      </div>
    </Modal>
  );
}

function mapStateToProps({ historyVersion }) {
  const { initLoading, historyVersionVisible, versionList, currentVersionIndex } = historyVersion;
  return {
    initLoading,
    historyVersionVisible,
    currentVersion: versionList[currentVersionIndex],
  };
}

function mapDispatchToProps({ historyVersion }) {
  const { toggleHistoryVersionVisible, changeInitLoading, getVersionList } = historyVersion;
  return {
    toggleHistoryVersionVisible,
    changeInitLoading,
    getVersionList,
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(HistoryRecordModal);
