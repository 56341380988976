import { intl } from 'di18n-react';
import { useEffect, useMemo, useState, useContext, useCallback } from 'react';
import { Popover, Switch, Tooltip, Radio } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import NotificationStatus from '@/constants/notification';
import useNotification from '@/hooks/useNotification';
import { getShareLink } from '@/utils/index';
import { SHARE_TYPE, SHARE_MODAL_TEXT } from '@/constants/index';
import ShareEntryDK from './ShareEntryDK';
import ShareEntryPage from './ShareEntryPage';
import ShareLink from './ShareLink';
import styles from './style.module.less';
import TextOnlyModal from '@/components/TextOnlyModal';
import LayoutContext from '@/components/serviceComponents/Layout/layoutContext';
import { gainSetting } from '@/service/knowledge/setup';
import { SAFE_SETTING_VALUE, SHARE_STATUS } from '@/constants/setup';

const cls = classNames.bind(styles);

const CAN_OPERATE = 1;

function ShareModal({ resourceId, shareType, hasPermission, resourceName }) {
  const { knowledgeId, pageId } = useContext(LayoutContext);
  const dispatch = useDispatch();
  const notification = useNotification();

  const { changeOperatePerm } = dispatch.Setting;
  const { getShareModalSetting, editShareModalSetting } = dispatch.SharePage;
  const { shareModalSetting } = useSelector((state) => state.SharePage);
  const { operatePerm } = useSelector((state) => state.Setting);

  const [sharePopoverVisible, setSharePopoverVisible] = useState(false);

  const reqShareSetting = () => {
    getShareModalSetting({
      resourceType: shareType,
      resourceId,
    });

    // 获取高级设置中安全设置中是否允许操作
    gainSetting({ knowledgeId }).then((res) => {
      changeOperatePerm({
        isAllowDownload: res?.switchSetting?.shareLinkDownloadConfig, // 1:允许 2.不允许
        isAllowComment: res?.switchSetting?.shareLinkCommentConfig,
      });
    });
  };

  let uniqueId = pageId || knowledgeId;

  useEffect(() => {
    reqShareSetting();
  }, [uniqueId]);

  const isSharing = useMemo(() => {
    return shareModalSetting?.status === SHARE_STATUS.OPEN; // 0:关闭 1:开启
  }, [shareModalSetting]);

  const downloadValue = useMemo(() => {
    return shareModalSetting?.sharePermList?.download;
  }, [shareModalSetting]);

  const commentValue = useMemo(() => {
    return shareModalSetting?.sharePermList?.comment;
  }, [shareModalSetting]);

  const openSubPageValue = useMemo(() => {
    return shareModalSetting?.isOpenSubPage;
  }, [shareModalSetting]);

  const shareLink = useMemo(() => {
    return getShareLink({ shareId: shareModalSetting?.shareId, shareType });
  }, [shareModalSetting]);

  const shareTypeText = useMemo(() => {
    return shareType === SHARE_TYPE.DK ? intl.t('知识库') : intl.t('页面');
  }, [shareType]);

  const getDesc = (value, diffDesc) => {
    return intl.t('{slot0}后，获得{slot1}的人都将{slot2}{diffDesc}', {
      slot0: value === SAFE_SETTING_VALUE.OPEN ? intl.t('关闭') : intl.t('开启'),
      slot1: typeof SHARE_MODAL_TEXT[shareType].title === 'function' ? SHARE_MODAL_TEXT[shareType].title() : SHARE_MODAL_TEXT[shareType].title,
      slot2: value === SAFE_SETTING_VALUE.OPEN ? intl.t('无法') : intl.t('可以'),
      diffDesc,
    });
  };

  const onPopoverVisibleChange = (value) => {
    if (value) {
      reqShareSetting();
      if (shareType === SHARE_TYPE.DK) {
        window.__OmegaEvent('ep_dkpc_share_ck');
      } else {
        window.__OmegaEvent('ep_dkpc_pagedetail_share_ck');
      }
    }
    setSharePopoverVisible(value);
  };

  const copyCallBack = () => {
    notification(NotificationStatus.SUCCESS, intl.t('链接复制成功'));
    setSharePopoverVisible(false);

    if (shareType === SHARE_TYPE.DK) {
      window.__OmegaEvent('ep_dkpc_share_copylink_ck');
    } else {
      window.__OmegaEvent('ep_dkpc_pagedetail_share_copylink_ck');
    }
  };

  const MODAL_DATA_MAP = {
    closeShare: {
      title: intl.t('关闭分享'),
      cancelText: intl.t('取消'),
      okText: intl.t('确定'),
      content: intl.t(
        '一旦关闭分享，之前获得分享链接的用户将无法访问{shareTypeText}',
        { shareTypeText },
      ),
      onOk: () => {
        doControlSetting({
          control: SHARE_STATUS.CLOSE,
        }).then(() => {
          notification(
            NotificationStatus.SUCCESS,
            intl.t('{shareTypeText}已关闭分享', {
              shareTypeText,
            }),
          );
        });
      },
    },
    download: {
      title: intl.t('权限变更提示'),
      cancelText: intl.t('取消'),
      okText: intl.t('确认变更'),
      content: getDesc(downloadValue, intl.t('下载页面内的附件')),
      onOk: () => {
        onOkDownload();
      },
    },
    comment: {
      title: intl.t('权限变更提示'),
      cancelText: intl.t('取消'),
      okText: intl.t('确认变更'),
      content: getDesc(commentValue, intl.t('查看和添加评论')),
      onOk: () => {
        onOkComment();
      },
    },
    subScope: {
      title: intl.t('更改分享范围'),
      cancelText: intl.t('取消'),
      okText: intl.t('确认变更'),
      content: (
        <p>
          {intl.t('选择分享“当前页面及子页面”，你为')}
          <b>{intl.t('管理员')}</b>
          {intl.t('角色的子页面会同时开启分享')}
        </p>
      ),
      onOk: () => {
        window.__OmegaEvent('ep_dkpc_pagedetail_share_includesubpages_ck');
        onOkShareScope();
      },
    },
    onlySelfScope: {
      title: intl.t('更改分享范围'),
      cancelText: intl.t('取消'),
      okText: intl.t('确认变更'),
      content: (
        <p>
          {intl.t(
            '选择分享“仅当前页面”,获得分享链接的用户将无法再访问当前页面的子页面，但不会更改子页面的分享状态',
          )}
        </p>
      ),
      onOk: () => {
        window.__OmegaEvent('ep_dkpc_pagedetail_share_current_ck');
        onOkShareScope();
      },
    },
  };

  const permRenderArray = [
    {
      text: intl.t('下载页面附件'),
      disableDesc: intl.t('知识库-安全设置-已关闭-下载'),
      overAllAllow: operatePerm.isAllowDownload,
      allow: downloadValue,
      clickPermSwitch: () => showConfirm('download'),
    },
    {
      text: intl.t('查看并添加划线评论、全文评论'),
      disableDesc: intl.t('知识库-安全设置-已关闭-评论'),
      overAllAllow: operatePerm.isAllowComment,
      allow: commentValue,
      clickPermSwitch: () => showConfirm('comment'),
    },
  ];

  const onOkDownload = () => {
    let newValue = Math.abs(downloadValue - 1);
    doControlSetting({ download: newValue, control: 2 })
      .then(() => {
        notification(NotificationStatus.SUCCESS, intl.t('变更成功'));
      })
      .catch(() => {
        notification(NotificationStatus.ERROR, intl.t('变更失败，请稍后再试'));
      });

    if (newValue) {
      if (shareType === SHARE_TYPE.DK) {
        window.__OmegaEvent('ep_dkpc_share_closedownload_ck');
      } else {
        window.__OmegaEvent('ep_dkpc_pagedetail_share_closedownload_ck');
      }
    } else if (shareType === SHARE_TYPE.DK) {
      window.__OmegaEvent('ep_dkpc_share_opendownload_ck');
    } else {
      window.__OmegaEvent('ep_dkpc_pagedetail_share_opendownload_ck');
    }
  };

  const onOkComment = () => {
    const newValue = Math.abs(commentValue - 1);

    doControlSetting({ comment: Math.abs(commentValue - 1), control: 2 })
      .then(() => {
        notification(NotificationStatus.SUCCESS, intl.t('变更成功'));
      })
      .catch(() => {
        notification(NotificationStatus.ERROR, intl.t('变更失败，请稍后再试'));
      });

    if (newValue === CAN_OPERATE) {
      shareType === SHARE_TYPE.DK
        && window.__OmegaEvent('ep_dkpc_dkpcvistor_allowcomment_ck');
      shareType === SHARE_TYPE.PAGE
        && window.__OmegaEvent('ep_dkpc_pagevistor_allowcomment_ck');
    } else {
      shareType === SHARE_TYPE.DK
        && window.__OmegaEvent('ep_dkpc_dkpcvistor_forbidcomment_ck');
      shareType === SHARE_TYPE.PAGE
        && window.__OmegaEvent('ep_dkpc_pagevistor_forbidcomment_ck');
    }
  };

  const onOkShareScope = () => {
    let newValue = Math.abs(openSubPageValue - 1);
    doControlSetting({
      scopeValue: newValue,
      control: 2,
    }).then(() => {
      notification(NotificationStatus.SUCCESS, intl.t('更改分享范围成功'));
    });
  };

  const doControlSetting = async ({
    control,
    download,
    comment,
    scopeValue,
  }) => {
    // 注意:control: 0: 关闭分享 1:开启分享 2:修改除分享状态之外的其他操作
    let params = {
      resourceId,
      resourceType: shareType,
      control: control ?? isSharing + 0,
      isOpenSubPage: scopeValue ?? openSubPageValue,
      sharePermList: {
        download: download ?? downloadValue,
        comment: comment ?? commentValue,
      },
    };

    const data = editShareModalSetting(params);
    return data;
  };

  const showConfirm = (key) => {
    const item = MODAL_DATA_MAP[key];
    TextOnlyModal.confirm({
      title: item.title,
      wrapClassName: cls('second-floor-modal'),
      getContainer: () => document.querySelector('#tooltip-wrap'),
      content: item.content,
      onOk: item.onOk,
    });
  };

  const clickShareSwitch = (checked) => {
    if (checked) {
      doControlSetting({
        control: SHARE_STATUS.OPEN,
      }).then(() => {
        notification(
          NotificationStatus.SUCCESS,
          intl.t('{shareTypeText}已开启分享', { shareTypeText }),
        );
      });
    } else {
      showConfirm('closeShare');
    }

    if (checked) {
      if (shareType === SHARE_TYPE.DK) {
        window.__OmegaEvent('ep_dkpc_share_begin_ck');
      } else {
        window.__OmegaEvent('ep_dkpc_pagedetail_share_begin_ck');
      }
    } else if (shareType === SHARE_TYPE.DK) {
      window.__OmegaEvent('ep_dkpc_share_stop_ck');
    } else {
      window.__OmegaEvent('ep_dkpc_pagedetail_share_stop_ck');
    }
  };

  const isPermDisable = useCallback(
    (val) => {
      if (!val) return !hasPermission;
      return !hasPermission || val === SAFE_SETTING_VALUE.CLOSE;
    },
    [hasPermission],
  );

  const isPermCheck = useCallback((overAllVal, val) => {
    return overAllVal === SAFE_SETTING_VALUE.OPEN && val;
  }, []);

  const shareContent = (
    <div
      className={cls('share-content', { 'share-close': !isSharing })}
      id="tooltip-wrap"
    >
      <div className={cls('title')}>{typeof SHARE_MODAL_TEXT[shareType].title === 'function' ? SHARE_MODAL_TEXT[shareType].title() : SHARE_MODAL_TEXT[shareType].title }</div>
      <div className={cls('switch-global')}>
        <Tooltip
          placement="bottom"
          title={
            hasPermission
              ? ''
              : intl.t('仅{shareTypeText}管理员可开启/关闭链接分享', {
                shareTypeText,
              })
          }
        >
          <Switch
            disabled={!hasPermission}
            checked={isSharing}
            className={cls('share-switch', 'dk-ant-switch_reset')}
            onChange={clickShareSwitch}
          />
        </Tooltip>
        {isSharing
          ? intl.t('已开启访客链接分享，获得链接的人可阅读分享内容')
          : intl.t('未开启，只有已添加的页面成员可访问该页面')}
      </div>
      {isSharing && (
        <>
          <ShareLink
            shareLink={shareLink}
            shareTypeText={shareTypeText}
            resourceName={resourceName}
            copyCallBack={copyCallBack}
            shareType={shareType}
          />

          {shareType === SHARE_TYPE.PAGE && (
            <div className={cls('share-setting')}>
              <div className={cls('setting-titles')}>{intl.t('分享范围')}</div>
              <div className={cls('setting-content')}>
                <Radio.Group
                  disabled={!hasPermission}
                  onChange={(e) => {
                    showConfirm(e.target.value ? 'subScope' : 'onlySelfScope');
                  }}
                  value={openSubPageValue}
                  className={cls('safe-radio', 'dk-ant-radio_reset')}
                >
                  <Radio
                    className={cls('safe-need')}
                    value={0}>
                    {intl.t('仅当前页面')}
                  </Radio>
                  <Radio
                    className={cls('safe-need')}
                    value={1}>
                    {intl.t('当前页面及子页面')}
                  </Radio>
                </Radio.Group>
              </div>
            </div>
          )}

          <div className={cls('share-setting')}>
            <div className={cls('setting-titles')}>{intl.t('链接权限')}</div>
            <div className={cls('setting-content')}>
              {permRenderArray.map(
                ({
                  text,
                  overAllAllow,
                  allow,
                  disableDesc,
                  clickPermSwitch,
                }) => (
                  <div
                    className={cls('setting-item')}
                    key={text}>
                    <Switch
                      disabled={isPermDisable(overAllAllow)}
                      checked={isPermCheck(overAllAllow, allow)}
                      className={cls('share-switch', 'dk-ant-switch_reset')}
                      onChange={clickPermSwitch}
                    />

                    {text}
                    {overAllAllow !== 1 && (
                      <div className={cls('disabled-desc')}>
                        {intl.t('知识库安全设置中{disableDesc}权限已关闭', {
                          disableDesc,
                        })}
                      </div>
                    )}
                  </div>
                ),
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );

  return (
    <div className={cls('share')}>
      <Popover
        trigger={['click']}
        content={shareContent}
        placement={'bottomRight'}
        destroyOnClose={true}
        onVisibleChange={onPopoverVisibleChange}
        visible={sharePopoverVisible}
        overlayClassName={cls('share-popover', 'dk-ant-popover__reset')}
      >
        {shareType === SHARE_TYPE.DK && <ShareEntryDK isSharing={isSharing} />}

        {shareType === SHARE_TYPE.PAGE && (
          <ShareEntryPage isSharing={isSharing} />
        )}
      </Popover>
    </div>
  );
}

export default ShareModal;
