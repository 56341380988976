
import api from '@/utils/request/api/CooperApi';
import { get, post } from '@/utils/request/cooper';
import { history } from '@/model';

export const getApply = async (id) => {
  let data = {}
  await get(`${api.TEAM_LINK_STATUS}?link=${id}`, {
    keepSilent: true,
    needDD05: true,
  }).then((res) => {
    data = res;
  }).catch((err) => {
    if (err.errorCode) {
      history.push('/team-file/team-invalid')
    }
    return Promise.reject(err);
  })
  const {
    team_name: teamName,
    time_left: timeLeft,
    is_member: permitted,
    team_id: teamId,
    team_owner: approverEn,
    team_owner_cn: approverCn,
    check: isPrivate,
  } = data;
  return {
    timeLeft,
    permitted,
    teamId,
    teamName,
    approverEn,
    approverCn,
    isPrivate,
  };
};


export async function doApply(link, message) {
  const data = await post(api.LINK_JOIN.replace(':link', link), { message });
  return data;
}

export async function doAddMemberBatch(inviter, invitees, limitCount = 500) {
  // let url = isDiDiTenant() ? api.TEAM_MEMBERS_BATCH : api.TEAM_MEMBERS_BATCH_TENANT;
  // url = url.replace(':teamId', inviter);
  // console.log(inviter, invitees, limitCount);
  // invitee_type: 用户：0; OE组：1; Cooper团队：2; 邮件组：3; DC群组：4
  const data = await post(api.TEAM_MEMBERS_BATCH.replace(':teamId', inviter), { limitCount, invitees });
  return data;
}

export function getDefaultPermis(teamInfo) {
  // XXX: teamInfo 中 teamInfo.permission(个人权限) 和 teamInfo.links[0].permission(团队权限)混淆，需要修改
  const p = teamInfo.links[0].permission;
  const ps = [];
  [1, 2, 8, 16, 32].forEach((b) => {
    if (b & p) ps.push(b); // eslint-disable-line no-bitwise
  });
  return ps;
}


export async function searchAddTeamMember({ keyword, searchType, resourceId, itemSize, pageNum }) {
  const result = await get(`${api.ADD_MEMBER_SEARCH_TENANT}?keyword=${encodeURIComponent(keyword)}&searchType=${searchType}&teamId=${resourceId}&itemSize=${itemSize}&pageNum=${pageNum || 0}`);
  return result;
}

// 批量添加成员
export async function batchAddMember(params) {
  const data = await post(api.BATCH_ADD_TEAM_MEMBER, params);
  return data;
}


