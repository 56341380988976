import React from 'react';
import { Checkbox } from 'antd'
import { intl } from 'di18n-react';
import { highlight } from '@/utils';
import './index.less';

const Tag = ({ content, className = '' }) => {
  return (
    <span className={`tag ${className}`}>
      <span className="word">{intl.t(content)}</span>
    </span>
  );
};

const SOURCE_MAP = () => {
  return {
    ALONE_PERM: intl.t('当前文件夹单独授权'),
    EXTEND_PARENT: intl.t('继承自父文件夹'),
    NO_SHOW: '',
  }
};

export const roleMap = {
  RESOURCE_OWNER: 'ADMIN',
  RESOURCE_ADMIN: 'ADMIN',
  RESOURCE_MEMBER: 'MEMBER',
  RESOURCE_NOPERM_MEMBER: 'NO_PERM',
};

const TagText = ({ tagList }) => {
  if (tagList.indexOf('SPACE_OWNER') !== -1) {
    return <Tag content={intl.t('空间所有者')} />;
  }
  if (tagList.indexOf('RESOURCE_OWNER') !== -1) {
    return <Tag content={intl.t('文件夹所有者')} />;
  }
  return '';
};

const MemberItem = ({ memberInfo, selectedList, type, singleCheckChange, removeSingleItem }) => {
  const {
    tag,
    email,
    avatar,
    cooperate_with_cn: name,
    orgMemberId,
    showOuter,
    permsString,
    sources,
    role,
    isDisable,
  } = memberInfo;
  const { hrStatus } = memberInfo;
  const isSelected = type === 'selected'
  const isChecked = selectedList && selectedList.some((item) => item.orgMemberId === orgMemberId);
  const clickItem = () => {
    if (type !== 'selected' && !isDisable) {
      singleCheckChange(!isChecked, memberInfo)
    }
  }
  return (
    <div
      className={`${hrStatus === 'I' ? 'quitMember' : ''
      } batchMemberItem ${isSelected ? 'isSelected' : ''} ${isDisable ? 'disabled-not-allow' : ''}`}
      key={orgMemberId}
      onClick={() => clickItem()}
    >
      {
        !isSelected && <Checkbox
          className='batchMemberItem-checkbox'
          checked={isChecked}
          disabled={isDisable}
          />
      }
      <img
        className="pic"
        src={avatar} />
      <div className="info">
        <div className='left'>
          <div className="nameArea">
            <div
              className="name"
            >{highlight(name)}</div>
            {/* {self && <Tag
            content={intl.t('我')}
            className="tag-self" />} */}
            {hrStatus === 'I' && (
              <Tag
                content={intl.t('已离职')}
                className="tag-quit-self" />
            )}
            {
              showOuter && <Tag
                content={intl.t('外部')}
                className="out-yellow" />
            }
            <TagText tagList={tag} />
          </div>
          <p
            className="mail"
          >{highlight(email)}</p>
        </div>
        {
          !isSelected && <div className='right'>
            <div className='tag'>
              { roleMap[role] === 'ADMIN' ? intl.t('管理员') : permsString }
            </div>
            <div className='single'>
              {
              SOURCE_MAP()[sources] !== '' ? intl.t(SOURCE_MAP()[sources]) : ''
            }
            </div>
          </div>
        }
        {
          isSelected && <i
            className='dk-iconfont dk-icon-guanbi1'
            onClick={() => { removeSingleItem(memberInfo) }}
          />
        }
      </div>
    </div>
  );
};

export default MemberItem;
