import { Tooltip } from 'antd';
import classBind from 'classnames/bind';
import styles from './style.module.less';

const cx = classBind.bind(styles);
// 黑色的，么有箭头
const ModalTitleTip = ({ title, desc }) => {
  return <Tooltip
    title={<div>
      <p className={cx('title')}>{title}</p>
      {
        (() => {
          if (Array.isArray(desc)) {
            return desc.map((item, index) => {
              return <p
                key={index}
                className={cx('desc')}>{item}</p>;
            });
          }
          return <p className={cx('desc')}>{desc}</p>;
        })()
      }
    </div>}
    placement="bottom"
    overlayClassName={cx('modalTitleTip')}>
    <i className={cx('dk-iconfont', 'dk-icon-tishi', 'addTip')}/>
  </Tooltip>;
};
export default ModalTitleTip;
