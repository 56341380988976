/*
 * @Date: 2024-03-19 17:31:24
 * @LastEditors: guanzhong guanzhong@didiglobal.com
 * @LastEditTime: 2024-03-19 17:32:29
 * @FilePath: /knowledgeforge/src/components/common/CopyRight/index.js
 * @Description: 所有落地页的公用底部
 */

import classBind from 'classnames/bind';
import styles from './style.module.less';
import { intl } from 'di18n-react';

const cx = classBind.bind(styles);

const CopyRight = () => {
  return (
    <p className={cx('copyright')}>
      {intl.t('@{year} 效能平台部 - 信息平台部', {
        year: new Date().getFullYear(),
      })}
    </p>
  )
}

export default CopyRight
