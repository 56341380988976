import { intl } from 'di18n-react';
import classNames from 'classnames/bind';
import { getDepartmentStr } from '@/utils';
import MemberItem from '@/components/serviceComponents/MemberComponent/MemberItem';
import styles from '../style.module.less';

const cx = classNames.bind(styles);
const GroupList = ({ list, title, choose, isDepartGroup = false }) => {
  const getName = (item) => {
    return isDepartGroup
      ? getDepartmentStr(item.departmentLevel.join('/'), 13)
      : item.name;
  };
  const getNum = (item) => {
    return isDepartGroup ? '' : item.memberCount;
  };
  return (
    <>
      {list.length > 0 && <div className={cx('title')}>{title}</div>}
      {list.map((item) => {
        return (
          <MemberItem
            avatar={item.iconUrl}
            key={item.id}
            exist={item.exist}
            name={getName(item)}
            number={getNum(item)}
          >
            {!item.exist && (
              <div
                className={cx('add')}
                onClick={() => choose(item)}>
                {intl.t('添加')}
              </div>
            )}
          </MemberItem>
        );
      })}
    </>
  );
};

export default GroupList;
