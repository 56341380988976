/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-07-22 15:41:23
 * @LastEditTime: 2024-04-12 17:20:49
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/CooperFoldAuth/addMember/memberItem/index.js
 *
 */
import React from 'react';
import { intl } from 'di18n-react';
import { beautySub, highlight } from '@/utils';
import DepartContent from '../../departContent';
import '../../MemberPermContent/memberItem/index.less';
import UserPermSelect from '../UserPermSelect';

const MemberItem = ({
  memberInfo,
  keyWord,
  onChangePermission,
  isShowOperate,
}) => {
  const { email, avatar, user_cn: name, exist, depart, hrStatus, showOuter } = memberInfo;

  const changePermission = ({ permNum, role }) => {
    onChangePermission({ member: memberInfo, permission: permNum, role });
  };

  const Tag = ({ content, className = '' }) => {
    return (
      <span className={`tag ${className}`}>
        <span className="word">{intl.t(content)}</span>
      </span>
    );
  };

  return (
    <div
      className={`${
        hrStatus === 'I' ? 'quit-member' : ''
      } cooperFoldAuthm-member-list`}
    >
      <img
        className="pic"
        src={avatar} />
      <div className="info">
        <div className="nameArea">
          <div
            className="name"
          >{highlight(name)}</div>
          <DepartContent department={depart} />
          {hrStatus === 'I' && (
            <Tag
              content={intl.t('已离职')}
              className="tag-quit-self" />
          )}
          {
            showOuter && <Tag
              content={intl.t('外部')}
              className="tag-self" />
          }
        </div>
        <p
          className="mail"
        >{highlight(email)}</p>
      </div>

      <React.Fragment>
        {exist ? (
          <div className={'disabled powerChoose'}>{intl.t('已添加')}</div>
        ) : isShowOperate ? (
          <UserPermSelect
            memberInfo={memberInfo}
            onChangePermission={changePermission}
          />
        ) : (
          ''
        )}
      </React.Fragment>
    </div>
  );
};

export default MemberItem;
