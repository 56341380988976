import { Breadcrumb, Popover } from 'antd';
import classNames from 'classnames/bind';
import styles from './style.module.less';
import { Link } from 'react-router-dom';

const cls = classNames.bind(styles);

export class BreadCrumb extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ellipsisData: [],
    };
  }

  getEllipsisRoutes = (routes) => {
    // 最多展示4个层级，超出用...
    let len = routes.length;
    let ellipsisData = [];
    if (len <= 4) {
      return {
        ellipsisRoutes: routes,
        ellipsisData,
      };
    }

    const ellipsisNew = routes.slice();
    ellipsisData = ellipsisNew.splice(1, len - 3, {
      path: '...',
      breadcrumbName: '...',
    });
    return {
      ellipsisRoutes: ellipsisNew,
      ellipsisData,
    };
  };

  render() {
    const {
      children,
      routes,
    } = this.props;
    const ellipsisRoutesObj = this.getEllipsisRoutes(routes);
    const {
      ellipsisRoutes,
      ellipsisData,
    } = ellipsisRoutesObj;
    let ellipsisContent = '';

    if (ellipsisData.length > 0) {
      ellipsisContent = (
        <React.Fragment>
          {
            ellipsisData.map((data, index) => {
              return (
                <Link
                  to={data.path}
                  key={index}
                  title={data.breadcrumbName}
                  className={styles['pop-item-link']}
                >
                  <span className={styles['pop-name']}>
                    {data.breadcrumbName}
                  </span>
                </Link>
              );
            })
          }
        </React.Fragment>
      );
    }

    return (
      <div className={styles['bread-crumb']}>
        <div className={styles['bread-crumb-title']}>
          <Breadcrumb>
            {
              ellipsisRoutes.map((route, key) => {
                if (key === ellipsisRoutes.length - 1) {
                  return (
                    <Breadcrumb.Item
                      key={key}
                      className={cls('breadcrumb-item-text', 'is-last')}
                      title={route.breadcrumbName}
                    >
                      {route.breadcrumbName}
                    </Breadcrumb.Item>
                  );
                }

                if (route.path === '...') {
                  return <Breadcrumb.Item key={key}>
                    <Popover
                      placement="bottom"
                      trigger={['click']}
                      overlayClassName={cls('bread-crumb-container', 'dk-ant-popover__reset')}
                      content={ellipsisContent}>
                      <span className={cls('breadcrumb-item-text')}>
                        <i className={cls('dk-iconfont', 'dk-icon-mianbaoxie_gengduoxinxi', 'bread-omit')} />
                      </span>
                    </Popover>
                  </Breadcrumb.Item>;
                }

                return (
                  <Breadcrumb.Item key={key}>
                    <Link
                      to={route.path}
                      title={route.breadcrumbName}
                      className={styles['breadcrumb-item-text']}
                    >
                      {route.breadcrumbName}
                    </Link>
                  </Breadcrumb.Item>
                );
              })
            }
          </Breadcrumb>
        </div>
        {children}
      </div>
    );
  }
}

export default BreadCrumb;
