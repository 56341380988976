import classBind from 'classnames/bind';
import ChangeScope from '@/components/ChangeScope';
import SearchContent from '@/components/SearchContent';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styles from './style.module.less';
import CustomerService from '@/baseComponents/CustomerService';


const cx = classBind.bind(styles);

function SpaceTitleContent() {
  const { teamDetail, currentSpaceType } = useSelector((state) => state.TeamData);
  const { pathname } = useLocation();

  // TODO：还原： const isInTeamRoute = currentSpaceType === 'TEAM_SPACE';
  const isInTeamRoute = pathname.includes('/team-file/');

  const { tags = [] } = teamDetail;


  return (
    <div className={cx('header-title')}>
      <div className={cx('title-content')}>
        <ChangeScope
          isShowCurrent={true}
          dataDetail={{
            resourceId: teamDetail.id,
            resourceName: teamDetail.name,
          }}
          canSwitch={isInTeamRoute}
          searchType='space'
          isDepart={isInTeamRoute ? tags?.includes('SPACE_DEPART') : false}
        />

      </div>
      <div className={cx('search-content')}>
        <SearchContent />
      </div>
      <CustomerService />
    </div>
  );
}

export default SpaceTitleContent;
