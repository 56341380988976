import { intl } from 'di18n-react';
import { Button } from 'antd';
import classBind from 'classnames/bind';
import { useState } from 'react';
import styles from './style.module.less';

const cx = classBind.bind(styles);

const MemberFooter = ({
  cancel,
  ok,
  okTxt = intl.t('添加并通知'),
  okBtnDisabled,
  customClass,
  noLoading = false,
  children,
}) => {
  const [loading, setLoading] = useState(false);
  const onOk = () => {
    setLoading(true);
    ok().finally(() => {
      setLoading(false);
    });
  };

  return (
    <div className={cx('footer', customClass, !children && 'onlyOne')}>
      {children}
      <div className={cx('memberBtn')}>
        <Button
          className={cx('btn', 'cancel')}
          onClick={cancel}>
          {intl.t('取消')}
        </Button>
        <Button
          disabled={okBtnDisabled}
          onClick={noLoading ? ok : onOk}
          loading={loading}
          className={cx('btn', 'confirm')}
        >
          {okTxt}
        </Button>
      </div>
    </div>
  );
};
export default MemberFooter;
