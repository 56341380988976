/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-06-27 10:53:51
 * @LastEditTime: 2024-04-10 17:52:29
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/ChangeScope/index.js
 *
 */
import { intl, getLocale } from 'di18n-react';
import Capacity from '@/components/Capacity';
import { useState } from 'react';
import { Popover } from 'antd';
import classBind from 'classnames/bind';
import cls from 'classnames';
import ScopeItem from '@/components/common/ListOptions/ScopeItem';
import { getSearchTypeMap } from '@/constants/search';
import DebounceSelect from '@/components/DebounceSelect';
import {
  getSearchData,
  getVisitedDK,
  getVisitedTeam,
} from '@/service/knowledge/search';
import styles from './style.module.less';
import { useNavigate } from 'react-router-dom';
import Tag from '@/components/Tag';
import { useSelector } from 'react-redux';
import { IN_OUT } from '@/constants/space'
import safeIcon from '@/assets/icon/safeIcon.png';
import { spaceSafeSummary } from '@/service/cooper/teamSpace';
// TODO: changeKnowledge迁移
const cx = classBind.bind(styles);
const ChangeScope = ({
  isShowCurrent = true,
  dataDetail,
  searchType,
  onClick,
  canSwitch, // 团队空间
  isDepart,
}) => {
  const [switchVisible, setSwitchVisible] = useState(false);
  const [safeMsg, setSafeMsg] = useState({});
  const navigate = useNavigate();
  const { teamDetail } = useSelector((state) => state.TeamData);
  const { profile } = useSelector((state) => state.CooperIndex);
  const { check } = teamDetail;
  const searchTypeMap = getSearchTypeMap();
  // eslint-disable-next-line camelcase
  const { cooperate_type } = teamDetail
  const fetchFilterList = async (keyWord) => {
    let res = [];
    let opts = { searchType: searchTypeMap[searchType].type, key: keyWord, isContainPerson: 0 };

    if (keyWord && keyWord !== '') {
      res = await getSearchData(opts);
      return res?.[searchType]?.items.map((v) => ({
        name: v?.resourceName || '--',
        id: v?.resourceId || '--',
        isDepart: (v?.relationTypeTags || []).includes('SPACE_DEPART'),
        isShowOuter: (v?.relationTypeTags || []).includes(IN_OUT),
      }));
    }
    res = searchType === 'metis'
      ? await getVisitedDK({ maxSize: 100 })
      : await getVisitedTeam({ maxSize: 100 });
    return res?.map((v) => ({
      name: v.name,
      id: v.id,
      isDepart: (v?.relationTypeTags || []).includes('SPACE_DEPART'),
      isShowOuter: (v?.relationTypeTags || []).includes(IN_OUT),
    }));
  };

  const handleSwitch = (v) => {
    if (onClick) onClick(v);
    else {
      navigate(
        searchType === 'metis' ? `/knowledge/${v}/home` : `/team-file/${v}/home`,
      );
    }
    setSwitchVisible(false);
  };

  const _defaultName = () => {
    if (!canSwitch) {
      return intl.t('{slot0}的个人空间', {
        slot0: getLocale() === 'zh-CN' ? profile.username_zh : profile.username,
      });
    }
    // if (!dataDetail?.resourceName) return `切换${searchTypeMap[searchType].name()}`;
    if (dataDetail?.resourceName && isShowCurrent) { return dataDetail?.resourceName; }
    // if (dataDetail?.resourceName && !isShowCurrent) return `切换${searchTypeMap[searchType].name()}`;
    return null;
  };


  const getSafeMsg = async () => {
    const data = await spaceSafeSummary({
      teamId: teamDetail.id,
    });
    setSafeMsg(data);
  }

  return (
    <Popover
      overlayClassName={cx('switch-scope-popover')}
      placement="bottomLeft"
      trigger="click"
      visible={switchVisible}
      destroyTooltipOnHide={true}
      onVisibleChange={(v) => {
        !v && setSwitchVisible(false);
      }}
      content={
        canSwitch && (
          <DebounceSelect
            label={dataDetail?.resourceName}
            value={dataDetail?.resourceId}
            defaultSelected={true}
            renderNodeItem={(v) => ScopeItem(v)}
            placeholder={intl.t('搜索团队空间')}
            fetchOptions={(v) => fetchFilterList(v)}
            currentId={dataDetail?.resourceId}
            onClick={(v) => handleSwitch(v.id)}
            notFoundContent={
              <div style={{ textAlign: 'center', padding: 4, color: '#bbb' }}>
                {intl.t('无匹配的团队空间')}
              </div>
            }
          />
        )
      }
    >
      {_defaultName() && (
        <div className={cx('scope-name')}>
          <span
            className={cx('scope-text')}
            onClick={() => {
              setSwitchVisible(!switchVisible);
            }}
          >
            {_defaultName()}
          </span>
          {isDepart && <Tag
            type="yellow-primary"
            text={intl.t('部门')}
            onClick={() => {
              setSwitchVisible(!switchVisible);
            }}
          />}
          {!canSwitch && <Capacity/>}
          {canSwitch && (
            <i
              className={`${cx('dk-iconfont', 'dk-icon-qiehuanzhouqi')} ${cx(
                'moreDk',
              )} ${switchVisible ? cx('isOpen') : ''}`}
              onClick={() => {
                setSwitchVisible(!switchVisible);
              }}
            />
          )}
          {
            // eslint-disable-next-line camelcase
            (cooperate_type === IN_OUT) && canSwitch
            && <>
              <Popover
                placement='bottom'
                overlayClassName={cls('outer-tips', 'cooper_popover_Msg_arrow')}
                content={intl.t('“外部空间”支持滴滴员工和企业外成员开展在线协作和内容共享。每个空间成员需要对空间内的数据及分享对象负责，请谨慎操作。禁止上传用户隐私数据，同时请确保数据无违法违规内容。')}
              >
                <div className={cx('operate-popover')}>
                  <Tag
                    type='out-yellow'
                    text={intl.t('外部')}
                />
                </div>
              </Popover>
              <Popover
                placement='bottom'
                overlayClassName={cls('outer-tips', 'cooper_popover_Msg_arrow')}
                content={<div>
                  <p className={cx('safe-msg-title')}>
                    {
                    intl.t('此空间数据安全等级文件统计如下：')
                  }
                  </p>
                  {
                  Object.keys(safeMsg || {})?.map((i) => {
                    return <p className={cx('safe-msg-content')}>
                      {
                        intl.t('{num}等级:{count}个', {
                          num: i,
                          count: safeMsg[i],
                        })
                      }
                    </p>
                  })
                }
                </div>}
                onVisibleChange={(open) => {
                  if (open) getSafeMsg();
                }}
            >
                <div className={cx('operate-popover')}>
                  <Tag
                    type='out-yellow'
                    iconSrc={safeIcon}
                    text={intl.t('数据安全等级统计')}
                />
                </div>
              </Popover>
            </>
          }

          {
            // eslint-disable-next-line camelcase
            cooperate_type !== IN_OUT && canSwitch && <Tag
              type='gray-line'
              overlayClassName={cls('public-tag')}
              text={intl.t(!check ? '公开空间' : '私密空间')}
            />
          }
        </div>
      )}
    </Popover>
  );
};

export default ChangeScope;
