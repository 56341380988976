import { intl } from 'di18n-react';
import classNames from 'classnames/bind';
import { useState } from 'react';
import { cloneDeep, debounce } from 'lodash-es';
import MemberInput from '@/components/serviceComponents/MemberComponent/MemberInput';
import MemberItem from '@/components/serviceComponents/MemberComponent/MemberItem';
import MemberFooter from '@/components/serviceComponents/MemberComponent/MemberFooter';
import EmptySearch from '@/components/serviceComponents/MemberComponent/EmptySearch';
import PermPopover from '@/components/serviceComponents/PermPopover';
import SpinRender from '@/components/SpinRender';
import GroupList from './groupList';
import { PAGE_MEMBER_TYPE, SEARCH_GROUP_TYPE } from '@/constants/setup';
import { searchGroupMember } from '@/service/knowledge/setup';
import { addPageGroup } from '@/service/knowledge/page';
import styles from './style.module.less';

const cx = classNames.bind(styles);

const AddGroup = ({ pageId, knowledgeId, closeAddGroupModal }) => {
  const [resultList, setResultList] = useState([]);
  const [customGroupList, setCustomGroupList] = useState([]);
  const [departGroupList, setDepartGroupList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showConfrimBtn, setShowConfrimBtn] = useState(true);
  const [inputValue, setInputValue] = useState('');
  const [showRecommandWord, setShowRecommandWord] = useState(false);
  const params = {
    key: inputValue,
    searchType: SEARCH_GROUP_TYPE.CUSTOM_GROUP,
    resourceId: pageId,
  };

  const search = async (args) => {
    setLoading(true);
    const res = await searchGroupMember(knowledgeId, {
      ...args,
      pageSize: 100,
    });
    setCustomGroupList(res.customGroup?.items || []);
    setDepartGroupList(res.departGroup?.items || []);
    setLoading(false);
    setShowRecommandWord(args.key === '');
  };

  const onInputChange = debounce((e) => {
    const key = e.target.value;
    setInputValue(key);
    search({ ...params, key });
    setShowConfrimBtn(false);
    window.__OmegaEvent('ep_dkpc_pagedetail_invitegroup_search_ck');
  }, 500);
  const onFocus = () => {
    if (inputValue === '') {
      search({ ...params });
      setShowConfrimBtn(false);
    }
  };
  const findMemberIndex = (id) => {
    const index = resultList.findIndex((item) => item.id === id);
    return index;
  };
  const setPerm = (id, perm) => {
    const temp = cloneDeep(resultList);
    temp[findMemberIndex(id)].perm = perm;
    setResultList(temp);
  };
  const choose = (item) => {
    if (!resultList.find((member) => member.id === item.id)) {
      setResultList(
        resultList.concat([{ ...item, perm: PAGE_MEMBER_TYPE.PAGE_MEMBER }]),
      );
    }
    setShowConfrimBtn(true);
    if (inputValue === '') {
      window.__OmegaEvent('ep_dkpc_pagedetail_invitegroup_add_ck'); // 搜索推荐列表里，点击添加
    }
  };
  const del = (id) => {
    const temp = cloneDeep(resultList);
    temp.splice(findMemberIndex(id), 1);
    setResultList(temp);
  };
  const addGroup = () => {
    if (resultList.find((member) => member.departmentLevel !== null)) {
      window.__OmegaEvent(
        'ep_dkpc_pagedetail_invitegroup_addnotify_organization_ck',
      );
    }
    if (resultList.find((member) => member.departmentLevel === null)) {
      window.__OmegaEvent('ep_dkpc_pagedetail_invitegroup_addnotify_custom_ck');
    }
    return addPageGroup(pageId, {
      metisId: knowledgeId,
      groups: resultList.map(({ id, perm }) => {
        return {
          id,
          role: perm,
        };
      }),
    }).then(() => {
      closeAddGroupModal();
      window.__OmegaEvent('ep_dkpc_pagedetail_invitegroup_addnotify_ck');
    });
  };

  const hasRecommand = customGroupList.length > 0 || departGroupList.length > 0;

  return (
    <div className={cx('addGroup')}>
      <div className={cx('header')}>
        <div className={cx('tip')}>
          {intl.t(
            '支持添加当前知识库内的自定义成员组和组织架构，如需在页面中添加自定义成员组，请联系知识库所有者/管理员进行创建',
          )}
        </div>
        <MemberInput
          onInputChange={onInputChange}
          onFocus={onFocus}
          placeholder={intl.t('搜索知识库内自定义成员组和组织架构')}
        />
      </div>
      <div className={cx('memberList-wrap')}>
        <div className={cx('memberList')}>
          {showConfrimBtn ? (
            resultList.map(({ name, id, perm, memberCount, iconUrl }) => {
              return (
                <MemberItem
                  name={name}
                  key={id}
                  number={''}
                  avatar={iconUrl}>
                  <PermPopover
                    permList={[
                      {
                        value: PAGE_MEMBER_TYPE.PAGE_MEMBER,
                        name: intl.t('成员'),
                      },
                      {
                        value: PAGE_MEMBER_TYPE.PAGE_READ_MEMBER,
                        name: intl.t('只读成员'),
                      },
                    ]}
                    id={id}
                    setPerm={setPerm}
                    perm={perm}
                    del={del}
                  />
                </MemberItem>
              );
            })
          ) : (
            <div>
              {hasRecommand ? (
                <>
                  {showRecommandWord && (
                    <div className={cx('title')}>{intl.t('推荐')}</div>
                  )}
                  <GroupList
                    list={customGroupList}
                    title={intl.t('自定义成员组')}
                    choose={choose}
                  />
                  <GroupList
                    isDepartGroup={true}
                    list={departGroupList}
                    title={intl.t('组织架构')}
                    choose={choose}
                  />
                </>
              ) : loading ? (
                <SpinRender loading={loading} />
              ) : (
                <EmptySearch
                  text={
                    inputValue
                      ? intl.t('未搜索到该成员组')
                      : intl.t('暂无推荐成员组')
                  }
                />
              )}
            </div>
          )}
        </div>
      </div>
      {showConfrimBtn && (
        <MemberFooter
          okBtnDisabled={resultList.length === 0}
          cancel={closeAddGroupModal}
          ok={addGroup}
        />
      )}
    </div>
  );
};

export default AddGroup;
