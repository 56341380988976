import React, { useState, useContext, useMemo, useEffect } from 'react';
import { Input, Spin } from 'antd';
import { intl } from 'di18n-react';
import { debounce } from 'lodash-es';
import InfiniteScroll from 'react-infinite-scroller';
import AddMember from '../addMember';
import MemberList from './memberList';
import InheritContent from './InheritContent';
import TeamContext from '@/components/CooperFoldAuth/TeamContext';
import EmptyInfo from '../emptyInfo';
import { containKeyword } from '../util';
import { getFolderMember, searchFolderMember } from '../service';
import AddMemberIcon from '../icon/add-member-icon.png';
import useLoadMore from '@/hooks/useLoadMore';
import BatchManagement from '../BatchManagement/index'
import { TEAM_ADMIN, TEAM_OWNER } from '@/utils/cooperutils';

import './index.less';

const MemberPermContent = ({
  addMemberOpen,
  openAddMember,
  closeAddMember,
  showAddMemberBtn,
  showInherit,
  isInherit,
  inheritRoleCallback,
  showBatchMemberBtn,
}) => {
  const { resourceId, teamId } = useContext(TeamContext);
  const [keyword, setKeyword] = useState('');
  const {
    loadMore: loadMoreCurrList,
    loading: loadingMoreCurr,
    list: currUserList,
    hasMore: hasMoreCurrList,
    totalCount,
  } = useLoadMore((args) => getFolderMember({ resourceId, ...args }));

  const {
    loadMore: loadMoreSearchList,
    loading: loadingMoreSearch,
    list: searchList,
    hasMore: hasMoreSearchList,
  } = useLoadMore((args) => searchFolderMember({ resourceId, q: keyword, ...args }, null));

  const onInputChange = debounce((value) => {
    setKeyword(value);
    if (value === '') {
      loadMoreCurrList({ resourceId }, true);
    } else {
      loadMoreSearchList({ q: value, resourceId }, true);
    }
    window.__OmegaEvent('ep_teamspace_more_permisionset_searchmember_ck', '', {
      platform: 'new',
    });
  }, 500);

  const searchListView = useMemo(() => {
    if (searchList.length > 0) {
      let tempList = searchList.filter(({ cooperate_with_cn: name, email }) => {
        return containKeyword(name, keyword) || containKeyword(email, keyword);
      });
      return tempList;
    }
    return [];
  }, [searchList]);

  const inheritCallback = (value) => {
    loadMoreCurrList({ resourceId }, true); // 更新用户列表
    loadMoreSearchList({ q: keyword, resourceId }, true); // 更新搜索列表
    inheritRoleCallback(value); // 更新继承状态，进而更新“添加按钮”
  };

  return (
    <div>
      {addMemberOpen ? (
        <AddMember
          goBack={closeAddMember}
          refresh={() => {
            loadMoreCurrList({ resourceId }, true);
          }}
        />
      ) : (
        <div
          className={`powerSetOpened ${showInherit ? '' : 'notShowInherit'}`}
        >
          {showInherit && (
            <InheritContent
              key={isInherit}
              isInherit={isInherit}
              inheritCallback={inheritCallback}
              resourceId={resourceId}
              teamId={teamId}
            />
          )}
          <div className="searchArea">
            <div className="title-wrap">
              <div className="title-text">
                {`${intl.t('协作成员')}(${totalCount})`}
              </div>
              <div className='title-action'>
                {
                  showBatchMemberBtn && <BatchManagement
                    resourceId={resourceId}
                    teamId={teamId}
                    callback={inheritCallback}
                    isInherit={isInherit}
                    />
                }
                {showAddMemberBtn && (
                  <button
                    className="add-member-btn"
                    onClick={openAddMember}>
                    <img
                      src={AddMemberIcon}
                      alt=""
                      className="add-icon" />
                    <span className="add-text">{intl.t('添加成员')}</span>
                  </button>
                )}
              </div>

            </div>
            <Input
              onChange={(e) => onInputChange(e.target.value)}
              placeholder={intl.t('搜索协作成员')}
            />

            <div className="list-wrap">
              {keyword ? (
                searchList && searchList.length > 0 ? (
                  <InfiniteScroll
                    initialLoad={false}
                    pageStart={0}
                    loadMore={loadMoreSearchList}
                    hasMore={!loadingMoreSearch && hasMoreSearchList}
                    useWindow={false}
                    getScrollParent={() => document.querySelector('.list-wrap')}
                  >
                    <MemberList
                      list={searchListView}
                      refresh={() => {
                        loadMoreSearchList({ q: keyword, resourceId }, true);
                      }}
                      keyWord={keyword}
                    />
                  </InfiniteScroll>
                ) : loadingMoreSearch ? (
                  <Spin loading={loadingMoreSearch} />
                ) : (
                  <EmptyInfo desc={intl.t('暂无搜索结果')} />
                )
              ) : currUserList && currUserList.length > 0 ? (
                <InfiniteScroll
                  initialLoad={false}
                  pageStart={0}
                  loadMore={loadMoreCurrList}
                  hasMore={!loadingMoreCurr && hasMoreCurrList}
                  useWindow={false}
                  getScrollParent={() => document.querySelector('.list-wrap')}
                >
                  <MemberList
                    list={currUserList}
                    refresh={() => {
                      loadMoreCurrList({ resourceId }, true);
                    }}
                  />
                </InfiniteScroll>
              ) : loadingMoreCurr ? (
                <Spin loading={loadingMoreCurr} />
              ) : (
                <EmptyInfo desc={intl.t('暂无数据')} />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MemberPermContent;
