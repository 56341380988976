import SpaceTitleContent from '@/components/SpaceHeader/SpaceTitleContent';


function SpaceHeaderV1() {
  return (
    <div className={'space-header'} >
      <SpaceTitleContent />
    </div>
  );
}

export default SpaceHeaderV1;
