import { get, post } from '@/utils/request/index';
import CooperApi from '@/utils/request/api/CooperApi';
import DkApi from '@/utils/request/api/DkApi';

export function getCooperatorByName({ resourceId }) {
  if (!resourceId) return;
  return get(CooperApi.GET_COOPERATOR.replace(':resourceId', resourceId)).then((data) => {
    if (data) {
      return {
        id: data.id,
        username: data.cooperate_with,
        usernameCn: data.cooperate_with_cn,
        avatar: data.avatar,
        permission: data.permission,
        cooperatorType: data.cooperator_type,
        // A: 在职，I: 离职，其他：未知
        dimissed: data.hrStatus === 'I',
        totalCount: data.cooperator_total_count,
        resourceRole: data.resourceRole,
        spaceRole: data.spaceRole,
      };
    }
  });
}

export async function applyHigherPermFn(params) {
  const data = await post(CooperApi.APPLY_MEMBER_PERM.replace(':resourceId', params.resourceId), params);
  return data;
}


export async function applyOwnerPermCheck(params) {
  const res = await get(CooperApi.OWNER_CHECK_APPLY_PERM.replace(':id', params.resourceId));
  return res;
}


export async function applyOwnerPermFn(params) {
  const res = await post(CooperApi.APPLY_OWNER_APPLY_PERM.replace(':id', params.resourceId), params);
  return res;
}

// dk
export function getPagePermissionDk({ pageId }) {
  return get(DkApi.GET_PAGE_PERMISSION.replace(':pageId', pageId));
}


