/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-07-22 15:41:23
 * @LastEditTime: 2023-07-22 15:44:54
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/CooperFoldAuth/emptyInfo/index.js
 *
 */
import React from 'react';
import { intl } from 'di18n-react';
import './index.less';

const EmptyInfo = ({ desc = intl.t('暂无搜索结果') }) => {
  return (
    <div className="emptyInfo">
      <img
        className="pic"
        src={require('../../../assets/icon/empty13.png')} />
      {intl.t(desc)}
    </div>
  );
};

export default EmptyInfo;
