import { Menu } from 'antd';
import Popover from './Popover';
import classnames from 'classnames/bind';
import styles from './style.module.less';

const cls = classnames.bind(styles);

const PageStyle = ({ title, summary, ...props }) => {
  return (
    <Menu.Item
      key="togglePageStyle"
      className={cls('page-style-trigger')}
      disabled={true}
    >
      <div className={cls('page-style-title')}>
        <div>
          <i className={cls('dk-iconfont', 'dk-icon-wendangyangshi', 'icon-page-style')}/>
          <span>{title}</span>
        </div>
        <i className={cls('dk-iconfont', 'dk-icon-shouqi', 'icon-expand')}/>
      </div>
      <div className={cls('summer-tip')}>{summary}</div>
      <Popover {...props}/>
    </Menu.Item>
  );
}

export default PageStyle;
