import { intl } from 'di18n-react';
import { useState, useMemo, useContext } from 'react';
import { connect, useSelector } from 'react-redux';
import { Popover, Tooltip, Modal } from 'antd';
import classNames from 'classnames/bind';
import {
  addPageMembers,
  batchAddPageMember,
  reqInheritType,
  changeInheritType,
  getPagePermission,
} from '@/service/knowledge/page';
import usePermission from '@/hooks/usePermission';
import AddMember from '@/components/serviceComponents/AddMember';
import InheritSelect from '@/components/serviceComponents/PageMemberList/InheritSelect';
import { debounce } from 'lodash-es';
import {
  PAGE_MEMBER_TYPE,
  ADD_MEMBER_FROM_TYPE,
  EpClickAddPageMemberBatchAddFns,
} from '@/constants/setup';
import { InheritConfigRoot } from './config.js';
import { getPageRole } from '@/utils';
import useNotification from '@/hooks/useNotification';
import NotificationStatus from '@/constants/notification';
import LayoutContext from '@/components/serviceComponents/Layout/layoutContext';
import ModalTitleTip from '@/components/ModalTitleTip';
import { DKDefaultIcon } from '@/assets/icon';
import ExistMemberList from './existMemberList';
import ChangePageOwnerEntry from './ChangePageOwnerEntry';
import PeopleSelectEmpty from '@/components/serviceComponents/PeopleSelectEmpty';
import AddGroup from './addGroup';
import styles from './style.module.less';

const cls = classNames.bind(styles);

function PageMemberList(props) {
  const {
    searchMembers,
    inheritMembers,
    individualMembers,
    searchPageMembers,
    getPageInheritMembers,
    getPageIndividualMembers,
    docInfo,
    knowledgeDetail,
  } = props;

  const [popoverVisible, setPopoverVisible] = useState(false);
  const [inheritValue, setInheritValue] = useState(null);
  const [inheritMemberListVisible, setInheritMemberListVisible] = useState(true);
  const [individualMembersVisible, setIndividualMembersVisible] = useState(true);
  const [inputValue, setInputValue] = useState('');
  const [showAddGroup, setShowAddGroup] = useState(false);
  const [inheritListInfo, setInheritListInfo] = useState({
    loading: false,
    hasMore: false,
    pageNum: 0,
  });
  const [individualInfo, setIndividualListInfo] = useState({
    loading: false,
    hasMore: false,
    pageNum: 0,
  });
  const [searchListInfo, setSearchListInfo] = useState({
    loading: false,
    hasMore: false,
    pageNum: 0,
  });
  const [permission, setPermission] = useState();
  const { checkOperationPermission } = usePermission();
  const [openAddMemberModal, setOpenAddMemberModal] = useState();

  const notification = useNotification();

  const { globalOutsideChain } = useSelector((state) => state.CooperIndex);

  const { multiPath } = docInfo;
  const { pageId, knowledgeId } = useContext(LayoutContext);

  const managerMemberPermis = useMemo(() => {
    return checkOperationPermission('MANAGE_PAGE_MEMBER', permission);
  }, [permission]);
  const changeInheritPerm = useMemo(() => {
    const hasPerm = checkOperationPermission(
      'MANAGE_PAGE_SETTING_LIMIT',
      permission,
    );
    return hasPerm && multiPath?.length === 1;
  }, [multiPath, permission]);

  const getPermission = async () => {
    let { perm } = await getPagePermission({ pageId });
    setPermission(perm);
  };

  const calculateHasMore = (data) => {
    const { currentPage, pageSize, totalCount } = data;
    const more = (currentPage + 1) * pageSize < totalCount;

    return more;
  };

  const getInheritList = ({ refresh }) => {
    const pageNumber = refresh ? 0 : inheritListInfo.pageNum;
    const params = { pageId, pageSize: 10, pageNum: pageNumber, knowledgeId };
    setInheritListInfo({
      pageNum: pageNumber + 1,
      loading: true,
    });
    getPageInheritMembers({ refresh, params })
      .then((data) => {
        if (data) {
          setInheritListInfo({
            hasMore: calculateHasMore(data),
            pageNum: pageNumber + 1,
            loading: false,
          });
        }
      })
      .catch(() => {
        setInheritListInfo({
          ...inheritListInfo,
          loading: false,
        });
      });
  };

  const getIndividualList = ({ refresh = false }) => {
    const pageNumber = refresh ? 0 : individualInfo.pageNum;
    const params = { pageId, pageSize: 10, pageNum: pageNumber, knowledgeId };
    setIndividualListInfo({
      pageNum: pageNumber + 1,
      loading: true,
    });
    getPageIndividualMembers({ refresh, params })
      .then((data) => {
        if (data) {
          setIndividualListInfo({
            hasMore: calculateHasMore(data),
            pageNum: pageNumber + 1,
            loading: false,
          });
        }
      })
      .catch(() => {
        setIndividualListInfo({
          ...individualInfo,
          loading: false,
        });
      });
  };

  const getInheritType = () => {
    reqInheritType({ pageId, knowledgeId }).then((res) => {
      setInheritValue(res.extensionType);
    });
  };

  const search = (keyword, refresh = false) => {
    const pageNumber = refresh ? 0 : searchListInfo.pageNum;
    let params = {
      pageId,
      pageSize: 15,
      pageNum: pageNumber,
      keyword,
      knowledgeId,
    };
    searchPageMembers({ refresh, params })
      .then((data) => {
        if (data) {
          setSearchListInfo({
            hasMore: calculateHasMore(data),
            pageNum: pageNumber + 1,
            loading: false,
          });
        }
      })
      .catch(() => {
        setSearchListInfo({
          ...searchListInfo,
          loading: false,
        });
      });
  };

  const onInputChange = debounce((e) => {
    const keyword = e.target.value;
    setSearchListInfo({ ...searchListInfo, loading: true });
    setInputValue(keyword);
    if (keyword) {
      search(keyword, true);
      window.__OmegaEvent('ep_dkpc_pagedetail_searchmember_ck');
    } else {
      getInheritList({ refresh: true });
      getIndividualList({ refresh: true });
    }
  }, 500);

  const openPopover = () => {
    if (pageId) {
      getInheritList({ refresh: true });
      getIndividualList({ refresh: true });
    }
    getInheritType();
    getPermission();
    window.__OmegaEvent('ep_dkpc_pagedetail_member_ck');
    setInputValue('');
    setInheritMemberListVisible(true);
  };

  const onVisibleChange = (visible) => {
    setPopoverVisible(visible);
  };

  const switchInheritMemberListVisible = () => {
    setInheritMemberListVisible(!inheritMemberListVisible);
    window.__OmegaEvent('ep_dkpc_pagedetail_dkmember_ck');
  };

  const switchIndividualMembersVisible = () => {
    window.__OmegaEvent('ep_dkpc_pagedetail_outsidemember_ck');
    setIndividualMembersVisible(!individualMembersVisible);
  };

  const invite = async (currId, memberData) => {
    const invitees = memberData.map((item) => {
      return {
        id: item.id,
        inviteeType: item.inviteeType,
        role: getPageRole(item.permis[0], PAGE_MEMBER_TYPE),
      };
    });
    return addPageMembers(pageId, { invitees }).then(() => {
      window.__OmegaEvent('ep__dkpc_pagedetail_invitesuccess_ck');
      getIndividualList({ refresh: true });
      getInheritList({ refresh: true });
    });
  };
  const batchAdd = (invitees, perm, checkInvitees) => {
    return batchAddPageMember({
      pageId,
      invitees,
      role: getPageRole(perm, PAGE_MEMBER_TYPE),
      limitCount: 500,
      checkInvitees,
    }).then((res) => {
      if (res.addStatus === 1) {
        getIndividualList({ refresh: true });
        getInheritList({ refresh: true });
        notification(NotificationStatus.SUCCESS, intl.t('添加成功'));
      }
      return res;
    });
  };

  const changeInherit = async ({ value }) => {
    window.__OmegaEvent('ep_dkpc_pagedetail_member_inheritmode_ck');

    try {
      let { extensionType } = await changeInheritType({
        pageId,
        extensionType: value,
        knowledgeId,
      });
      setInheritValue(extensionType);
      notification(NotificationStatus.SUCCESS, intl.t('已修改继承方式'));
      getIndividualList({ refresh: true });
      getInheritList({ refresh: true });
    } catch (error) {
      notification(NotificationStatus.ERROR, error.errorMessage);
    }
  };
  const openAddGroupModal = () => {
    setShowAddGroup(true);
    window.__OmegaEvent('ep_dkpc_pagedetail_invitegroup_ck');
  };
  const closeAddGroupModal = () => {
    setShowAddGroup(false);
  };

  const MemberListContent = () => {
    return (
      <>
        <div
          className={cls('inheritMemberHeader')}
          onClick={switchInheritMemberListVisible}
        >
          <div
            className={cls('inheritMember-triangle', {
              spread: inheritMemberListVisible,
            })}
          />

          <img
            className={cls('inheritMember-avatar')}
            src={knowledgeDetail.exPicture || DKDefaultIcon}
          />

          <div className={cls('inheritMember-title')}>
            {intl.t('知识库成员')}
          </div>
        </div>
        {inheritMemberListVisible && (
          <div
            className={cls('inheritMemberList')}
            id="knowedge-inheritMemberList"
          >
            <ExistMemberList
              members={inheritMembers}
              loadMore={({ refresh }) => {
                getInheritList({ refresh });
              }}
              refreshRelatedList={({ refresh }) => getIndividualList({ refresh })
              }
              loading={inheritListInfo.loading}
              hasMore={inheritListInfo.hasMore}
              managerMemberPermis={managerMemberPermis}
              isRootNode={multiPath?.length === 1}
              getScrollParent={() => document.querySelector('#knowedge-inheritMemberList')
              }
              knowledgeMember={true} // 知识库成员
            />
          </div>
        )}
        {individualMembers?.length > 0 && (
          <>
            <div
              className={cls('inheritMemberHeader')}
              onClick={switchIndividualMembersVisible}
            >
              <div
                className={cls('inheritMember-triangle', {
                  spread: individualMembersVisible,
                })}
              />

              <div className={cls('inheritMember-title')}>
                {intl.t('知识库外受邀成员')}
              </div>
            </div>
            {individualMembersVisible && (
              <div
                className={cls('individualList')}
                id="knowedge-individuaMemberList"
              >
                <ExistMemberList
                  loadMore={({ refresh }) => {
                    getIndividualList({ refresh })
                  }}
                  loading={individualInfo.loading}
                  refreshRelatedList={({ refresh }) => {
                    getInheritList({ refresh })
                  }}
                  hasMore={individualInfo.hasMore}
                  members={individualMembers}
                  isRootNode={multiPath?.length === 1}
                  managerMemberPermis={managerMemberPermis}
                  getScrollParent={() => document.querySelector('#knowedge-individuaMemberList')
                  }
                  knowledgeMember={false} // 知识库外受邀成员
                />
              </div>
            )}
          </>
        )}
      </>
    );
  };
  const addMemberMenu = (
    <ul className={cls('addMemberMenu')}>
      <li
        className={cls('addMemberMenu-Item')}
        onClick={() => openAddMemberModal(ADD_MEMBER_FROM_TYPE.DK_PAGE)}
      >
        <i className={cls('dk-iconfont', 'dk-icon-tianjiachengyuan1')} />
        {intl.t('添加成员')}
      </li>
      <li
        className={cls('addMemberMenu-Item')}
        onClick={openAddGroupModal}>
        <i className={cls('dk-iconfont', 'dk-icon-zu2')} />
        {intl.t('添加成员组')}
      </li>
    </ul>
  );

  const MemberContent = () => {
    return (
      <div className={cls('member-content-wrap')}>
        <div className={cls('flex-top')}>
          <div className={cls('member-content-header')}>
            <div className={cls('member-title')}>
              {intl.t('页面成员')}
              <ModalTitleTip
                title={intl.t('角色说明：')}
                desc={[
                  intl.t('管理员：所有权限（编辑页面，管理页面成员）'),
                  intl.t('只读成员：查看页面'),
                  intl.t('无权限成员：无法访问页面'),
                ]}
              />
            </div>
            <div className={cls('member-operate')}>
              {changeInheritPerm && (
                <div className={cls('inherit-type-wrap')}>
                  <InheritSelect
                    inheritValue={inheritValue}
                    InheritConfig={InheritConfigRoot}
                    changeInherit={changeInherit}
                    bottomLink={{
                      text: intl.t('了解页面权限'),
                      link: globalOutsideChain?.is_inherit_perm_explain,
                    }}
                  />
                </div>
              )}
              {managerMemberPermis && (
                <Popover
                  placement="bottom"
                  trigger="click"
                  overlayClassName="dk-ant-popover__reset"
                  content={addMemberMenu}
                >
                  <div className={cls('addMemberBtn-inPage')}>
                    <i
                      className={cls(
                        'dk-iconfont',
                        'dk-icon-tianjiachengyuan1',
                        'addMemberBtn-inPage-icon',
                      )}
                    />
                  </div>
                </Popover>
              )}
            </div>
          </div>
          <AddMember
            currId={pageId}
            permissionType={ADD_MEMBER_FROM_TYPE.DK_PAGE}
            inSetup={false}
            onInputChange={onInputChange}
            invite={invite}
            batchAdd={batchAdd}
            addMemberPermission={managerMemberPermis}
            getOpenAddMemberModalFn={setOpenAddMemberModal}
            existMessage={intl.t('该用户已是页面成员')}
            placeholder={intl.t('搜索当前页面成员')}
            EpClickBatchAddFns={EpClickAddPageMemberBatchAddFns}
            inDk={true}
          />

          <div
            className={cls('member-content-body')}
            id="knowedge-pageMember-chooseArea"
          >
            {inputValue ? (
              <div className={cls('searchResult')}>
                {!searchListInfo.loading && (
                  <>
                    {searchMembers?.length > 0 ? (
                      <ExistMemberList
                        keyword={inputValue}
                        members={searchMembers}
                        loadMore={(refresh) => search(inputValue, refresh)}
                        loading={searchListInfo.loading}
                        hasMore={searchListInfo.hasMore}
                        managerMemberPermis={managerMemberPermis}
                        getScrollParent={() => document.querySelector(
                          '#knowedge-pageMember-chooseArea',
                        )
                        }
                        isRootNode={multiPath?.length === 1}
                      />
                    ) : (
                      <PeopleSelectEmpty />
                    )}
                  </>
                )}
              </div>
            ) : (
              MemberListContent()
            )}
          </div>
        </div>

        <div className={cls('member-content-footer')}>
          <ChangePageOwnerEntry
            onVisibleChange={onVisibleChange}
          />

        </div>
      </div>
    );
  };

  return (
    <div className={cls('page-member-wrap')}>
      <Popover
        trigger="click"
        content={MemberContent}
        placement={'bottomRight'}
        overlayClassName={cls('dk-ant-popover__reset', 'member-popover')}
        onVisibleChange={onVisibleChange}
        visible={popoverVisible}
        destroyTooltipOnHide={true}
      >
        <Tooltip
          title={intl.t('页面成员')}
          placement="bottom"
          overlayClassName="dk-ant-tooltip__reset"
        >
          <span
            className={cls('member-trigger', { 'is-active': popoverVisible })}
            onClick={openPopover}
          >
            <i
              className={cls('dk-iconfont', 'dk-icon-duoren', 'member-icon')}
            />
          </span>
        </Tooltip>
      </Popover>
      {showAddGroup && (
        <Modal
          title={
            <>
              {intl.t('添加成员组')}

              <ModalTitleTip
                title={intl.t('权限说明：')}
                desc={intl.t(
                  '同时添加包含某个用户的多个成员组时，该用户取较高权限（成员>只读成员）',
                )}
              />
            </>
          }
          visible={true}
          closeIcon={
            <i className={cls('dk-iconfont', 'dk-icon-guanbi', 'close')} />
          }
          onCancel={closeAddGroupModal}
          footer={null}
          width={640}
          wrapClassName={cls('knowledge_component_addMember', 'addGroupModal')}
          maskStyle={{ zIndex: 1030 }}
        >
          <AddGroup
            pageId={pageId}
            knowledgeId={knowledgeId}
            closeAddGroupModal={closeAddGroupModal}
          />
        </Modal>
      )}
    </div>
  );
}

function mapStateToProps({ pageDetail, KnowledgeData }) {
  const { inheritMembers, individualMembers, searchMembers } = pageDetail;
  const { knowledgeDetail } = KnowledgeData;

  return {
    searchMembers,
    knowledgeDetail,
    inheritMembers,
    individualMembers,
  };
}

function mapDispatchToProps({ pageDetail }) {
  const { searchPageMembers, getPageInheritMembers, getPageIndividualMembers } = pageDetail;
  return {
    searchPageMembers,
    getPageInheritMembers,
    getPageIndividualMembers,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PageMemberList);
