/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-07-11 17:11:35
 * @LastEditTime: 2023-07-11 17:12:02
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/CooperFilesList/Resizer/index.js
 *
 */
import React from 'react';
import { noop } from 'lodash-es';
import './index.less';

class Resizer extends React.Component {
  static defaultProps = { onBegin: noop, onResize: noop };

  constructor(props) {
    super(props);
    this.lastOffsetX = 0;
    this.mouseX = 0;
    this.resizing = false;
  }

  handleDown = (e) => {
    this.resizing = true;
    this.mouseX = e.clientX;
    document.addEventListener('mousemove', this.handleMove, false);
    document.addEventListener('mouseup', this.handleUp, false);

    this.props.onBegin();
  }

  handleMove = (e) => {
    if (this.resizing) {
      const offsetX = e.clientX - this.mouseX;
      this.props.onResize(offsetX);
    }
  }

  handleUp = () => {
    this.resizing = false;
    document.removeEventListener('mousemove', this.handleMove, false);
    document.removeEventListener('mouseup', this.handleUp, false);
    this.props.onEnd();
  }

  render() {
    return (
      <i
        className='resizer'
        onMouseDown={this.handleDown}
      >
        | |
      </i>
    );
  }
}

export default Resizer;
