import React from 'react';
// import isMobile from 'ismobilejs';
import { inPhone, isPreviewFile } from '@/utils';
import './style.less';
import { intl } from 'di18n-react';

const HELP_DOC = 'https://cooper.didichuxing.com/knowledge/share/book/ezHH8cb4mbaP/2199512611420';
const UPDATE_LOG = 'https://cooper.didichuxing.com/knowledge/share/book/ezHH8cb4mbaP/2199833433759';

export default (props) => {
  const jumpLink = (url) => {
    if (inPhone && window.dcH5Sdk) {
      window.dcH5Sdk.navigation.openWebview({
        url,
        onSuccess() {},
        onFail(err) {},
      });
    } else {
      window.open(url, '_blank');
    }
  };

  return (
    <div className='didoc-editor-help-center'>
      <i className='dk-iconfont dk-icon-a-bangzhuzhongxin4px'/>
      <ul className='options-popover'>
        <li className='option-item'>
          <a
            className='help-link'
            href="https://im.xiaojukeji.com/channel?uid=166036&token=f26b73e786c6c54339f60f3c558fe931&id=1394126817064031232"
            target='_blank'
            rel='noopener noreferrer'>
            <i
              className='dk-iconfont dk-icon-kefu'
              style={{ margin: '0 12px' }}/>
            <span className='option-title'>{intl.t('联系客服')}</span>
          </a>
        </li>
        <li className='option-item'>
          <span
            className='help-link'
            onClick={() => jumpLink(HELP_DOC)}>
            <i
              className='dk-iconfont dk-icon-bangzhuwendang'
              style={{ margin: '0 12px' }}/>
            <span className='option-title'>{intl.t('帮助文档')}</span>
          </span>
        </li>
        <li className='option-item'>
          <span
            className='help-link'
            onClick={() => jumpLink(UPDATE_LOG)}>
            <i
              className='dk-iconfont dk-icon-gengxinrizhi'
              style={{ margin: '0 12px' }}/>
            <span className='option-title'>{intl.t('更新日志')}</span>
          </span>
        </li>
      </ul>
    </div>
  );
}
