/*
 * @Author: guanzhong
 * @Date: 2023-11-09 16:00:00
 * @LastEditors: guanzhong
 * @LastEditTime: 2023-11-10 11:24:50
 * @FilePath: /knowledgeforge/src/hooks/useGetPreviewFileInfo.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { getPreviewFileInfo } from '@/service/knowledge/page';
import LayoutContext from '@/components/serviceComponents/Layout/layoutContext';


export default function useGetPreviewFileInfo(isPreview, fileId, contentStatus) {
  const dispatch = useDispatch();
  const { pageId } = useContext(LayoutContext);
  const [loading, setLoading] = useState(false);
  const { changePreviewFileInfo } = dispatch.pageDetail;


  useEffect(() => {
    if (isPreview) {
      setLoading(true);
      getPreviewFileInfo(pageId, { contentStatus }).then((data) => {
        const { pageName, fileSize, createdBy, lastModifiedBy, createdOn, lastModifiedOn } = data;

        changePreviewFileInfo({
          previewFileName: pageName,
          previewFileSize: fileSize,
          createdBy: createdBy.chineseName,
          lastModifiedBy: lastModifiedBy.chineseName,
          createdOn,
          lastModifiedOn,
        });
        setLoading(false);
      });
    }
  }, [fileId]);

  return {
    loading,
  };
}
