import { intl } from 'di18n-react';
import { useEffect, useState, useMemo, useContext } from 'react';
import { Button, Spin } from 'antd';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import { NotSupportPreviewIcon } from '@/assets/icon';
import usePermission from '@/hooks/usePermission';
import {
  fileSupportPreview,
  chooseFileAndUpload,
  bytesToSize,
  inPhone,
  getUserNameFromCookie,
} from '@/utils';
import LayoutContext from '@/components/serviceComponents/Layout/layoutContext';
import { getPreviewUrl, downloadFile } from '@/service/knowledge/page';
import ErrorTips from '@/components/ErrorTipsDk';
import useUpload from '@/hooks/uploadWiki';
import styles from './style.module.less';

const cx = classNames.bind(styles);
const isInPhone = inPhone();

const Preview = ({
  hasHeader = true,
  isEdit,
  permission,
  historyFileSize,
  parentId,
  historyFileId,
  historyFileName,
}) => {
  const { knowledgeId, pageId } = useContext(LayoutContext);
  const { previewFileId, previewFileInfo, docInfo } = useSelector(
    (state) => state.pageDetail,
  );
  const { previewFileName } = previewFileInfo;
  const fileName = historyFileName || (isEdit ? previewFileName : docInfo.pageName);
  const [previewUrl, setPreviewUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const { checkOperationPermission } = usePermission();
  const { isUploading, addUploadTask } = useUpload();
  const supportPreview = fileSupportPreview(fileName);
  const fileId = historyFileId || (isEdit ? previewFileId.inEdit : previewFileId.inDetail);
  const fileSize = historyFileSize || previewFileInfo.previewFileSize;

  const uploadPerm = useMemo(() => {
    return checkOperationPermission('MANAGE_PAGE_CONTEXT_LIMIT', permission);
  }, [permission]);

  const downloadPerm = useMemo(() => {
    return checkOperationPermission('MANAGE_PAGE_CONTEXT', permission);
  }, [permission]);

  // useEffect(() => {
  //   let afterSlot = document.getElementById('comment-content-wrap');
  //   let bottomContent = document.getElementById('dk-editor-bottom-wrap');
  //   if (bottomContent) {
  //     bottomContent.style = 'display:block';
  //     afterSlot?.appendChild(bottomContent);
  //   }
  // }, []);
  useEffect(() => {
    if (supportPreview) {
      fileId
        && getPreviewUrl({ pageId, fileId, pageName: fileName }).then((res) => {
          setPreviewUrl(res.url);
        });
    }
    // supportPreview取决于名字，编辑态时名字来自于info接口，info接口晚于detail接口，所以需要监控supportPreview的变化
  }, [fileId, supportPreview]);

  const upload = () => {
    let maxFileCount = 1;
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    return chooseFileAndUpload(
      'file',
      ({ file }) => {
        addUploadTask(file, {
          knowledgeId,
          parentId,
          currPageId: pageId,
          type: 'dk_file',
        });
      },
      maxFileCount,
    );
  };

  const download = async () => {
    setLoading(true);
    downloadFile(knowledgeId, { pageId, fileId, pageName: fileName })
      .then((res) => {
        let { url } = res;
        if (url) {
          const downloadUrl = url.charAt(4) !== 's' ? `${url.slice(0, 4)}s${url.slice(4)}` : url;
          window.location.href = downloadUrl;
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const Btns = (classNamePrefix) => {
    return (
      <>
        {isEdit ? (
          <>
            {uploadPerm && classNamePrefix === 'can-preview' && (
              <Button
                onClick={upload}
                loading={loading}
                disabled={isUploading(pageId)}
                className={cx(`${classNamePrefix}-btn`, 'preview-btn')}
              >
                {classNamePrefix === 'can-preview' && (
                  <i
                    className={`${cx(
                      'dk-icon-shangchuan1',
                      'dk-iconfont',
                      'icon',
                    )}`}
                  />
                )}
                {intl.t('上传新版本')}
              </Button>
            )}
          </>
        ) : (
          <>
            {downloadPerm
              && (supportPreview
                ? true
                : classNamePrefix === 'cannot-preview') && (
                  <Button
                    onClick={download}
                    loading={loading}
                    className={cx(`${classNamePrefix}-btn`, 'preview-btn')}
                >
                    {classNamePrefix === 'can-preview' && (
                      <i
                        className={`${cx(
                          'dk-icon-icon_xiazai',
                          'dk-iconfont',
                          'icon',
                        )}`}
                    />
                    )}
                    {intl.t('下载')}
                  </Button>
            )}
          </>
        )}
      </>
    );
  };

  return (
    <div className={cx('preview-wrap')}>
      {hasHeader && (
        <div className={cx('header')}>
          <div className={cx('title')}>{fileName}</div>
          {!isInPhone && Btns('can-preview')}
        </div>
      )}
      {supportPreview ? (
        <>
          {' '}
          {previewUrl ? (
            <iframe
              allowFullScreen
              className={cx('preview', { hasHeader })}
              src={`${previewUrl}#ldap=${getUserNameFromCookie()}`}
            />
          ) : (
            <div className={'page-detail-loading'}>
              <Spin />
            </div>
          )}
        </>
      ) : (
        <ErrorTips
          img={NotSupportPreviewIcon}
          title={<div>{intl.t('文件类型不支持预览')}</div>}
          desc={
            <div className={cx('error-desc')}>
              <p>{fileName}</p>
              <p>{bytesToSize(fileSize)}</p>
            </div>
          }
        >
          {!isInPhone && Btns('cannot-preview')}
        </ErrorTips>
      )}
    </div>
  );
};

export default Preview;
