import { intl } from 'di18n-react';
import React from 'react';
import { message } from 'antd';
// eslint-disable-next-line import/no-extraneous-dependencies
import { noop } from 'lodash-es';
import BatchButton from '@/components/common/BatchButton/index';
import deleteFile from '@/components/CooperOperation/DeleteFile';
import starFile from '@/components/CooperOperation/StarFile';
import downloadFile from '@/components/CooperOperation/DownloadFile';
import moveFile from '@/components/CooperOperation/MoveFile';
import copyFile from '@/components/CooperOperation/CopyFile';
import classBind from 'classnames/bind';
import styles from './style.module.less';

const cx = classBind.bind(styles);

let copying = false;
function msgWaining() {
  message.warning(intl.t('请选择批量操作对象'));
}

function BatchOperate({
  checks = [],
  doneCallback = noop,
  children,
  deleteCallback = noop,
}) {
  if (checks.length === 0) {
    const batch = [
      {
        name: intl.t('下载'),
        callback: () => msgWaining(),
      },
      {
        name: intl.t('删除'),
        callback: () => msgWaining(),
      },
      {
        name: intl.t('移动'),
        callback: () => msgWaining(),
      },
      {
        name: intl.t('复制'),
        callback: () => msgWaining(),
      },
      {
        name: intl.t('收藏'),
        callback: () => msgWaining(),
      },
    ];

    return (
      <div className={cx('batch-operate')}>
        <div className={cx('batch-operate-main')}>
          <BatchButton batches={batch} />
        </div>

        <div className={cx('batch-operate-cancel')}>
          {children && children}
        </div>

      </div>
    );
  }
  const { teamId } = checks[0];

  const batches = [
    {
      name: intl.t('下载'),
      callback: () => {
        window.__OmegaEvent('ep_batch_download_ck', '', {
          platform: 'new',
          source: teamId ? 'team' : 'personal',
        });

        const { __connectSiteId = 0 } = window;
        downloadFile(checks, __connectSiteId, 0, teamId);
      },
    },
    {
      name: intl.t('删除'),
      callback: () => {
        deleteFile(
          checks.map((f) => f.id),
          deleteCallback,
          Number(teamId) !== 0,
          teamId,
        );
        window.__OmegaEvent('ep_batch_delete_ck', '', {
          platform: 'new',
          source: teamId ? 'team' : 'personal',
        });
      },
    },
    {
      name: intl.t('移动'),
      callback: () => {
        moveFile(checks, teamId, doneCallback, !teamId);
        window.__OmegaEvent('ep_batch_moveto_ck', '', {
          platform: 'new',
          source: teamId ? 'team' : 'personal',
        });
      },
    },
    {
      name: intl.t('复制'),
      callback: () => {
        if (copying) {
          message.error(intl.t('正在复制中，请稍后'));
          return;
        }
        copying = true;
        copyFile(
          checks,
          () => {
            copying = false;
            doneCallback();
          },
          () => {
            copying = false;
          },
          !teamId,
          true, // isBatch
        );
        window.__OmegaEvent('ep_batch_copyto_ck', '', {
          platform: 'new',
          source: teamId ? 'team' : 'personal',
        });
      },
    },
    {
      name: checks.some((c) => c.markedStar === 0) ? intl.t('收藏') : intl.t('取消收藏'),
      callback: () => {
        starFile(checks, doneCallback);
      },
    },
  ];

  return (
    <div className={cx('batch-operate')}>
      <div className={cx('batch-operate-main')}>
        <BatchButton batches={batches} />
      </div>

      <div className={cx('batch-operate-cancel')}>
        {children && children}
      </div>

    </div>
  );
}

export default BatchOperate;
