import { intl } from 'di18n-react';
import { Popover } from 'antd';
import classBind from 'classnames/bind';
import { DK_MEMBER_TYPE, PAGE_MEMBER_NAME } from '@/constants/setup';
import { SwitchLocaleIcon } from '@/assets/icon';
import styles from './style.module.less';

const cx = classBind.bind(styles);

const PermPopover = ({
  permList = [
    { value: DK_MEMBER_TYPE.DK_MEMBER, name: intl.t('成员') },
    { value: DK_MEMBER_TYPE.DK_READ_MEMBER, name: intl.t('只读成员') },
  ],
  perm,
  setPerm,
  id,
  del,
}) => {
  const choosePermContent = (
    <ul>
      {' '}
      {permList.map(({ value, name }) => {
        return (
          <li
            key={value}
            onClick={() => setPerm(id, value)}
            className={cx('choosePermChoice')}
          >
            {name}
            {value === perm && (
              <i
                className={cx(
                  'dk-icon-gou',
                  'dk-iconfont',
                  'choosePermChoice-icon',
                )}
              />
            )}
          </li>
        );
      })}
      {del && (
        <li
          key="-1"
          onClick={() => del(id)}
          className={cx('choosePermChoice', 'del')}
        >
          {intl.t('移除')}
        </li>
      )}
    </ul>
  );

  return (
    <Popover
      placement="bottom"
      trigger="click"
      zIndex={1051}
      className={cx('choosePermResult-wrap')}
      overlayClassName={cx('dk-ant-popover__reset', 'choosePermPopover')}
      content={choosePermContent}
    >
      <span className={cx('choosePermResult')}>{PAGE_MEMBER_NAME()[perm]}</span>
      <img
        className={cx('choosePermIcon')}
        src={SwitchLocaleIcon} />
    </Popover>
  );
};
export default PermPopover;
