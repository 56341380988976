import classBind from 'classnames/bind';
import CompositionInput from '@/components/CompositionInput';
import { powerManagermentSearchIcon } from '@/assets/icon';
import styles from './style.module.less';

const cx = classBind.bind(styles);

const MemberInput = ({ onInputChange, onFocus, onBlur, customStyle, placeholder, defaultValue, error, autoFocus }) => {
  return <CompositionInput
    style={{ backgroundImage: `url(${powerManagermentSearchIcon})`, ...customStyle }}
    customClass={cx('memberInput', { error })}
    onInputChange={onInputChange}
    onFocus={onFocus}
    onBlur={onBlur}
    defaultValue={defaultValue}
    placeholder={placeholder}
    autoFocus={autoFocus}
  />;
};
export default MemberInput;
