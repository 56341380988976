import React, { useState, useRef, useEffect } from 'react';
import { GLOBAL_PERM, MULTI_PERM, NO_ADMIN_PERM, cooperOptionGlobal, cooperOptionMulti, cooperOptionNoAdmin } from '@/baseComponents/ChooseMemberPerm/constant';
import PermOptionItem from '../PermOptionItem';


const CooperOption = ({ currRole, permType, onSelectFinally, applyedPermObj, onVisibleChange }) => {
  const [optionPerms, setOptionPerms] = useState([]);
  const optionRef = useRef(null);

  useEffect(() => {
    let optionDefault = [];
    let formateCooperOption = [];

    if (permType === GLOBAL_PERM) {
      optionDefault = [...cooperOptionGlobal()];
    } else if (permType === MULTI_PERM) {
      optionDefault = [...cooperOptionMulti()];
    } else if (permType === NO_ADMIN_PERM) {
      optionDefault = [...cooperOptionNoAdmin()];
    }


    if (applyedPermObj !== JSON.stringify('{}')) {
      const { value = '', type = '' } = applyedPermObj;
      const arr = value.length > 0 ? value.split(',') : [];

      formateCooperOption = optionDefault.map((option) => {
        return {
          ...option,
          checked: (type === 'multiple' ? arr.includes(option.value) : applyedPermObj.value === option.value) || (currRole.value.split(',').includes(option.value) && applyedPermObj.type !== 'single'),
        };
      });
    } else {
      formateCooperOption = [...optionDefault];
    }
    setOptionPerms(formateCooperOption); // applyedPermObj, permType

    return () => {
      optionDefault = [];
      formateCooperOption = [];
    };
  }, [currRole]);

  const onChoose = (clickOption) => { // 全部结束告诉上层组件
    if (clickOption.multiple) { // 多选 - 单选全都不选中；多选选中
      const newOptions = optionPerms.map((item) => {
        if (item.value === clickOption.value) return clickOption;
        if (!item.multiple) { // 选择多选时，单选变成不选中
          item.checked = false;
        }
        if (currRole.value.split(',').includes(item.value)) { // 选择多选时，默认多选选中
          item.checked = true;
        }
        return item;
      });

      setOptionPerms(newOptions); // 控制当前组件状态

      const checkedOptions = newOptions.filter((item) => {
        if (item.checked) return item;
        if (currRole.value.split(',').includes(item.value)) return item;
      });

      const value = checkedOptions.map((item) => item.value).join(',');

      const label = checkedOptions.map((item) => item.label).join('/');

      onSelectFinally({
        valueFinally: value,
        labelFinally: label,
        type: 'multiple',
      });
    } else { // 单选
      onSelectFinally({
        valueFinally: clickOption.value,
        labelFinally: clickOption.label,
        type: 'single',
      });
      onVisibleChange(false);
    }
  };

  const canApply = (option) => {
    // currRole.type === 'multiple' ?  currRole.value.split(',').includes(option.value) : currRole.value === option.value

    if (currRole.type === 'multiple') {
      if (!option.multiple) return true;
      return !(currRole.value.split(',').includes(option.value));
    }
    if (option.multiple) return false;
    return !(currRole.value === option.value);
  };


  let multiIndex = null;

  return (
    <div
      className={'cooper-option-wrap'}
      ref={optionRef}>
      {
       optionPerms.length > 0 && optionPerms.map((option, index) => {
         if (!option.multiple && multiIndex === null) {
           multiIndex = index;
           return <React.Fragment>
             <div className={'line-multi'} />
             <PermOptionItem
               key={option.value}
               optionItem={option}
               onOptionClickCallback={onChoose}
               canApply={canApply(option)}
              />
           </React.Fragment>;
         }

         return <PermOptionItem
           key={option.value}
           optionItem={option}
           onOptionClickCallback={onChoose}
           canApply={canApply(option)}
          />;
       })
      }
    </div>
  );
};


export default CooperOption;
