import { useRef, useMemo } from 'react';
import { GLOBAL_PERM_DK, DkOptionGlobal, DkOptionNoAdmin } from '@/baseComponents/ChooseMemberPerm/constant';
import PermOptionItem from '../PermOptionItem';


// 都是单选，简单处理
const DkOption = ({ currRole, onSelectFinally, applyedPerm, onVisibleChange, permType }) => {
  const optionRefDk = useRef(null);

  const formateDkOption = useMemo(() => {
    const defaultPerm = permType === GLOBAL_PERM_DK ? DkOptionGlobal() : DkOptionNoAdmin();
    return defaultPerm.map((option, _) => {
      return {
        ...option,
        checked: applyedPerm ? option.value === applyedPerm : option.value === currRole.value,
      }
    })
  }, [permType, applyedPerm, currRole])


  const onChoose = (newOptions) => {
    onSelectFinally({
      valueFinally: newOptions.value,
      labelFinally: newOptions.label,
    })
    onVisibleChange(false);
  }


  return (
    <div
      className={'dk-option-wrap'}
      ref={optionRefDk}>
      {
        formateDkOption.map((option, _) => {
          return <PermOptionItem
            key={option.value}
            optionItem={option}
            onOptionClickCallback={onChoose}
            canApply={option.roleKeyFront > currRole.roleKeyFront}
          />
        })
      }
    </div>
  );
};

export default DkOption;
