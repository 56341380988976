import { Button, Modal, Progress } from 'antd';
import classBind from 'classnames/bind';
import { intl } from 'di18n-react';
import React from 'react';
import convertingImage from './image.png';
import styles from './style.module.less';

const cx = classBind.bind(styles);
const STATUS = {
  // 询问框
  CONFIRMING: 'CONFIRMING',
  // 转换中
  CONVERTING: 'CONVERTING',
  // 转换成功
  SUCCESS: 'SUCCESS',
  // 转换失败
  FAILED: 'FAILED',
};


class ConvertWikiToDidocModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: STATUS.CONFIRMING,
      percent: 0,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.convertError) {
      this.setState({
        status: STATUS.FAILED,
      });
    }
  }

  getContent = () => {
    const { status, percent } = this.state;
    if (status === STATUS.CONFIRMING) {
      return <div>{intl.t('编辑前需进行样式转换，将Wiki html页面转换为知识库页面，转换后会导致部分样式丢失，请确认是否转换？')}</div>
    }
    if (status === STATUS.CONVERTING) {
      return (
        <div className={cx('convert')}>
          <div
            className={cx('convert-flex')}
            style={{ flexWrap: 'wrap' }}>
            <img src={convertingImage} />
            <p
              className={cx('convert-des')}
              style={{ width: '100%', textAlign: 'center' }}>{ intl.t('转换中...') }</p>
          </div>
          <Progress
            percent={percent}
            showInfo={false} />
          <div className={cx('convert-flex')}>
            <p>{intl.t('预计需要2分钟')}</p>
          </div>
        </div>
      )
    }
    if (status === STATUS.FAILED) {
      return (
        <div className={cx('convert')}>
          <div
            className={cx('convert-flex')}
            style={{ flexWrap: 'wrap' }}>
            <img src={convertingImage} />
          </div>
          <div className={cx('convert-flex')}>
            <></>
            <p>{intl.t('转换失败，请重试')}</p>
          </div>
        </div>
      )
    }
    return null;
  }

  render() {
    const { visible } = this.props;
    const { status } = this.state;
    const { getContent } = this;
    return (
      <Modal
        className={cx({
          'cooper-confirm': true,
          'cooper-confirm-no-cancel': !true,
          'convert-footer-center': status !== STATUS.CONFIRMING,
        })}
        visible={visible}
        closable={false}
        destroyOnClose={true}
        footer={[
          status !== STATUS.CONVERTING && <Button
            key="convert_wiki_to_didoc_cancel"
            onClick={() => {
              this.setState({
                status: STATUS.CONFIRMING,
                percent: 0,
              });
              this.props.close();
            }}>
            {intl.t('取消')}
          </Button>,
          status === STATUS.CONFIRMING && <Button
            key="convert_wiki_to_didoc_ok"
            type="primary"
            onClick={() => {
              this.setState({
                status: STATUS.CONVERTING,
              });
              this.props.next();
              setTimeout(() => {
                this.setState({
                  percent: 99,
                });
              }, 10);
            }}
        >
            {intl.t('确定')}
          </Button>,
          status === STATUS.FAILED && <Button
            key="convert_wiki_to_didoc_retry"
            type="primary"
            onClick={() => {
              this.setState({
                status: STATUS.CONVERTING,
                percent: 0,
              });
              this.props.retry();
              this.props.next();
              setTimeout(() => {
                this.setState({
                  percent: 99,
                });
              }, 10);
            }}
      >
            {intl.t('重试')}
          </Button>,
        ]}
      >
        <div style={{ paddingTop: '24px' }}>
          { getContent() }
        </div>
      </Modal>
    )
  }
}

export default ConvertWikiToDidocModal;
