import api from '@/utils/request/api/CooperApi';
import { get, post, del } from '@/utils/request/cooper';

export const getPersonalTrashList = async (pageNum) => {
  const data = await get(`${api.PERSONAL_TRASH_LIST}?pageNum=${pageNum ?? 0}&pageSize=30`);
  return data;
};

export const otherAPI = () => {
  //
};
