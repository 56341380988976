import { intl } from 'di18n-react';
import { Modal, Button } from 'antd';
import { useContext, useMemo, useState } from 'react';
import UserSelect from '@/components/UserSelect';
import classNames from 'classnames/bind';
import api from '@/utils/request/api/DkApi';
import * as service from '@/service/knowledge/setup';
import { get } from '@/utils/request';
import LayoutContext from '@/components/serviceComponents/Layout/layoutContext';
import styles from './style.module.less';
import { useSelector } from 'react-redux';
import NotificationStatus from '@/constants/notification';
import useNotification from '@/hooks/useNotification';
import customModal from '@/components/CustomModal';

const cx = classNames.bind(styles);

const TransferOwner = ({
  onClose,
  visible,
  isDK,
  pageOwnerId,
  onTransferCallback,
  transferRequest,
}) => {
  const desc = useMemo(() => {
    let text = isDK ? intl.t('支持将-知识库') : intl.t('支持将-页面');
    return intl.t(
      '支持将{text}所有权移交给{slot0}，移交后你将自动变为{text}管理员',
      { text, slot0: isDK ? intl.t('支持将-司内用户') : intl.t('支持将-知识库内成员') },
    );
  }, [isDK]);

  const [chooseUser, setChooseUser] = useState(null);
  const { knowledgeId, pageId } = useContext(LayoutContext);
  const { knowledgeDetail } = useSelector((state) => state.KnowledgeData);
  const notification = useNotification();

  const onTransfer = () => {
    if (chooseUser.isNotDKUser) {
      customModal({
        title: intl.t('权限移交提示'),
        content: intl.t(
          '该用户不是知识库内成员，如确定移交，系统会自动将该用户邀请进入该知识库',
        ),
        okText: intl.t('确认移交'),
        cancelText: intl.t('取消'),
        onOk() {
          requestTransfer();
        },
      });
      return;
    }
    requestTransfer();
  };

  const tipsContent = useMemo(() => {
    return (
      <div>
        {intl.t('移交成功，已向')}

        <a
          style={{ color: '#127FF0' }}
          href={`dchat://im/start_conversation?name=${chooseUser?.mail?.substring(
            0,
            chooseUser?.mail?.indexOf('@'),
          )}`}
        >
          {chooseUser?.title}
        </a>
        {intl.t('发送D-Chat消息通知')}
      </div>
    );
  }, [chooseUser]);

  const requestTransfer = async () => {
    try {
      if (transferRequest) {
        await transferRequest({ forTranslateOrgId: chooseUser.id });
      } else {
        await service.transferDkOwner({
          spaceId: knowledgeId,
          spaceOwnerId: knowledgeDetail.ownerOrgMemberId,
          forTranslateOrgId: chooseUser.id,
        });
      }
      notification(NotificationStatus.SUCCESS, tipsContent);
      onTransferCallback && onTransferCallback();
    } catch (error) {
      notification(NotificationStatus.ERROR, error.errorMessage);
    }
  };

  const searchReq = async (keyword) => {
    const params = {
      searchType: 1, // 用户 1；群组 0
      knowledgeId,
      pageId: isDK ? null : pageId,
      keyword,
      pageOwnerOrgId: isDK ? null : pageOwnerId,
      itemSize: 100, // 后端分页了，这里大概率是确定性搜索不需要分页
    };
    let { in: inList, out: outList } = await get(api.GET_TRANSFER_USER, {
      params,
    });
    outList[0]
      && outList[0].items
      && outList[0].items.map((user) => {
        user.isNotDKUser = true;
      });
    let data = ((inList[0] ?? []).items || []).concat(
      (outList[0] ?? [])?.items || [],
    );
    return data;
  };

  return (
    <Modal
      visible={visible}
      maskClosable={false}
      destroyOnClose
      wrapClassName={cx('transfer-owner-modal', 'dk-ant-modal_reset')}
      title={intl.t('移交{slot0}所有权', { slot0: isDK ? intl.t('移交-知识库') : intl.t('移交-页面') })}
      maskStyle={{ zIndex: 1030 }}
      onCancel={onClose}
      footer={
        <>
          <Button
            onClick={onClose}
            className={cx('transfer-cancel-btn')}
          >
            {intl.t('取消')}
          </Button>
          <Button
            style={{ marginLeft: 4 }}
            type="primary"
            onClick={onTransfer}
            className={cx('transfer-ok-btn')}
            disabled={!chooseUser}
          >
            {intl.t('移交')}
          </Button>
        </>
      }
    >
      <p className={cx('apply-body-desc')}>{desc}</p>
      <UserSelect
        placeholder={intl.t('搜索{slot0}（用户名、邮箱前缀）', {
          slot0: isDK ? intl.t('搜索用户名邮箱前缀-司内') : intl.t('搜索用户名邮箱前缀-页面'),
        })}
        onChange={(_, vObject) => {
          setChooseUser(vObject?.data);
        }}
        allowClear
        itemRequest={searchReq}
        getlabelKey={(i) => `${i.title}(${i.mail?.replace('@didiglobal.com', '')})`}
        styles={{ width: 580, margin: '24px 0' }}
        isNoHoverTips={true}
        isImgEmpty={true}
        // getPopupContainer={() => document.querySelector('.apply-owner-modal')}
      />
      <div className={cx('blank-content')} />
    </Modal>
  );
};

export default TransferOwner;
