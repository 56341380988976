import { Collapse } from 'antd';
import { useMemo } from 'react';
import classNames from 'classnames/bind';
import styles from './style.module.less';

const cls = classNames.bind(styles);
const { Panel } = Collapse;
function CollapsePanel(props) {
  const { data = [], active = {}, onChange } = props;
  const expandIcon = ({ isActive }) => {
    return (<i className={cls('dk-iconfont', (isActive ? 'dk-icon-xiayitiaopinglun' : 'dk-icon-shangyitiaopinglun'))}/>);
  };
  const defaultActiveKey = useMemo(() => {
    return data.map((item, index) => index);
  }, [data]);
  const handleClick = (template) => {
    if (template.templateId !== active.templateId) {
      onChange(template);
    }
  };

  return (
    <div className={cls('collapse-panel-container')}>
      <Collapse
        className={cls('collapse-panel')}
        expandIconPosition={'right'}
        expandIcon={expandIcon}
        defaultActiveKey={defaultActiveKey}
        ghost
      >
        {
          data.map((category, index) => {
            return category.children.length > 0
              ? (
                <Panel
                  header={category.title}
                  key={index}
                >
                  <ul className={cls('menu')}>
                    {
                      category.children.map((item, j) => (
                        <li
                          key={j}
                          className={cls('option', { active: item.templateId === active.templateId })}
                          onClick={() => handleClick(item)}
                        >
                          {item.name}
                        </li>
                      ))
                    }
                  </ul>
                </Panel>
              ) : null;
          })
        }
      </Collapse>
    </div>
  );
}

export default CollapsePanel;
