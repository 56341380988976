/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-07-22 15:41:23
 * @LastEditTime: 2023-07-22 15:42:56
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/CooperFoldAuth/addMember/memberList/index.js
 *
 */
import React, { useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Checkbox, Spin } from 'antd';
import { cloneDeep } from 'lodash-es';
import MemberItem from '../memberItem';
import { FULL_AUTH_PERM } from '../../contants';
import './index.less';


const MemberList = ({ search, keyWord, listInfo, checkList, changeCheckList }) => {
  const { list, hasMore, loading } = listInfo;

  const getCheck = ({ exist, id }) => {
    if (exist) return true;
    let index = checkList.findIndex((item) => {
      return item.id === id;
    });
    return index !== -1;
  };

  const onChange = (checked, member) => {
    let tempList = cloneDeep(checkList);
    const { user_name: user, id, user_cn: username, role } = member;
    if (checked) {
      tempList.push({
        user,
        id,
        username,
        perm: FULL_AUTH_PERM,
        role,
      });
    } else {
      const index = checkList.findIndex((item) => item.id === member.id);
      tempList.splice(index, 1);
    }
    window.__OmegaEvent('ep_teamspace_more_permisionset_selectadd_ck', '', {
      platform: 'new',
    });
    changeCheckList(tempList);
  };


  const onChangePermission = ({ member, permission, role }) => {
    const index = checkList.findIndex((item) => item.id === member.id);
    if (index !== -1) {
      let tempList = cloneDeep(checkList);
      tempList[index].perm = permission;
      tempList[index].role = role;
      changeCheckList(tempList);
    }
  };

  const viewMemberList = useMemo(() => {
    return list.map((member) => {
      const checkedItem = checkList.find((item) => item.id === member.id);
      if (checkedItem) {
        return { ...member, perm: checkedItem.perm, role: checkedItem.role };
      }
      return member;
    });
  }, [checkList, list]);

  return <Spin spinning={loading}>
    <div className='memberList'>
      <InfiniteScroll
        pageStart={0}
        loadMore={() => search('')}
        initialLoad={false}
        useWindow={false}
        hasMore={!loading && hasMore}
      >
        {viewMemberList.map((item) => {
          const isCheck = getCheck(item);
          return <div
            className='memberItem'
            key={item.id}>
            <Checkbox
              defaultChecked={isCheck}
              check={isCheck}
              onChange={(e) => onChange(e.target.checked, item)}
              disabled={item.exist}
              value={item.id}
              className={`${item.exist ? 'disabled' : ''}  check`}
          />
            <MemberItem
              keyWord={keyWord}
              onChangePermission={onChangePermission}
              memberInfo={item}
              isShowOperate={isCheck}
          />
          </div>;
        }) }
      </InfiniteScroll>
    </div>
  </Spin>;
};

export default MemberList;

