import { useState, useEffect } from 'react';
import { debounce } from 'lodash-es';
import { PAGE_SIZE } from '@/constants';

// fn:搜索函数
// initialParams:第一次请求参数，传null时useEffect时不发请求
export default function useLoadMore(fn, initialParams = {}) {
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [list, setList] = useState([]);
  const [pageNum, setPageNum] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    if (initialParams !== null) {
      loadMore(initialParams);
    } else {
      setLoading(false);
    }

    return () => {
      loadMore.cancel();
    };
  }, []);

  // params:调用方传递的搜索参数； refresh:是否从第0页开始: clearList：是否清空数据(refresh 同时为true)
  const loadMore = debounce((params = {}, refresh = false, clearList = true) => {
    setLoading(true);
    let pageNumber = refresh ? 0 : pageNum;
    if (refresh && clearList) {
      setList([]);
    }
    let member = refresh ? [] : list;
    let pageParams = { pageSize: PAGE_SIZE, pageNum: pageNumber }; // pageSize过小无法触发加载

    return fn({ ...params, ...pageParams })
      .then((data) => {
        if (data) {
          const { currentPage, pageSize, totalCount, hasMore: hasMoreData } = data;
          // 兼容 无法给出totalCount的接口
          const more = typeof hasMoreData === 'boolean' ? hasMoreData : (currentPage + 1) * pageSize < totalCount;
          setHasMore(more);
          setList(member.concat(data.items || []).map((item) => { // 默认格式items为接口的数据列表
            item.key = item.id;
            return item;
          }));
          setPageNum(pageNumber + 1);
          setTotalCount(totalCount)
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, 500);

  return {
    loading,
    hasMore, // 是否还要加载
    loadMore, // 分页加载函数
    list, // 数据列表
    setList, // 重置列表，一般不用
    totalCount, // 总数
  };
}
