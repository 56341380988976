import { intl } from 'di18n-react';
import React from 'react';
// import { connect } from 'react-redux';
import { ExclamationCircleFilled, LoadingOutlined } from '@ant-design/icons';
import SmallBtn from '@/components/common/small-btn';
import cooperConfirm from '@/components/common/CooperConfirm';
import { spaceQuotaApply } from '@/service/cooper';
import './style.less';

class QuotaBtn extends React.Component {
  __junmpTo = (url) => {
    if (url) window.open(url);
  };

  __renderOperationTooltipInner = (bpmUrl) => (
    <div className='quota-detail-tooltip-inner'>
      <div className='tooltip-inner-front'>
        {intl.t('扩容申请待审核，详情请查看')}
      </div>
      <div
        onClick={() => this.__junmpTo(bpmUrl)}
        className='tooltip-inner-end'>
        {'BPM'}
      </div>
    </div>
  );

  __renderTooltipInner = () => (
    <div className='quota-detail-tooltip-inner'>
      <div className='tooltip-inner-front'>
        {intl.t('团队创建者可申请扩容')}
      </div>
    </div>
  );

  __renderHint = (isOwner, applied, bpmUrl) => {
    if (isOwner) {
      if (applied) {
        return this.__renderOperationTooltipInner(bpmUrl);
      }
      return null;
    }
    return this.__renderTooltipInner();
  };

  qoutaSuccess = ({ approver = {}, bpmUrl }) => {
    cooperConfirm({
      type: 'success',
      title: intl.t(
        '您的申请已提交到BPM(倚天流程管理平台)中，将由 {one} 进行审批，详情请到BPM中查看',
        {
          one: approver.name || '',
        },
      ),
      okText: intl.t('去查看'),
      cancelText: intl.t('知道了'),
      onOk: () => {
        window.open(bpmUrl);
      },
    });
  };

  __requestQuota = (e) => {
    e.stopPropagation();
    const { teamId, success = () => {} } = this.props;
    cooperConfirm({
      className: 'space-quota-modal',
      type: 'warn',
      title: teamId === 0 ? intl.t('个人空间扩容') : intl.t('团队空间扩容'),
      content: (
        <div className='team-quota-hint'>
          {teamId === 0 ? (
            <p>
              {intl.t('个人空间每次可申请扩容')}
              <span className='team-quota-hint-num'>{'50G'}</span>
              {intl.t('，申请后需等待您的直属上级审核')}
            </p>
          ) : (
            <p>
              {intl.t('每个团队空间每次可申请扩容')}
              <span className='team-quota-hint-num'>{'50G'}</span>
              {intl.t('，申请后需等待您的直属上级审核')}
            </p>
          )}
        </div>
      ),
      icon: <ExclamationCircleFilled/>,
      okText: (
        <div
          className='icon-local-container'
          onClick={() => {
            document.getElementById('icon-local').style = 'display: block';
            document.getElementById('icon-local-word').style = 'display: none';
          }}
        >
          <div
            id='icon-local'
            style={{ display: 'none' }}
          >
            <LoadingOutlined/>
          </div>
          <div
            id='icon-local-word'
            style={{ display: 'block' }}
          >
            {intl.t('确认申请')}
          </div>
        </div>
      ),
      cancelText: intl.t(' 取消'),
      onOk: (cb) => {
        spaceQuotaApply({
          teamId,
          surplusQuota: 50000000000,
          reason: '',
        })
          .then((data) => {
            cb();
            success();
            this.qoutaSuccess(data);
          })
          .catch(() => {
            cb();
          });
      },
    });
  };

  render() {
    return (
      <SmallBtn
        onClick={this.__requestQuota}
        className={this.props.className || ''}
        name={intl.t('申请扩容')}
      />
    );
  }
}

export default QuotaBtn;
