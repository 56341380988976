import api from '@/utils/request/api/CooperApi';
import { get, post, del } from '@/utils/request/cooper';

export const getTeamSpaceTrashList = async (pageNum) => {
  let data = await get(`${api.TEAM_SPACE_TRASH_LIST}?pageNum=${pageNum ?? 0}&pageSize=30`);
  return data;
};

export const getSpaceTrashList = async (spaceId, pageNum) => {
  let data = await get(`${api.TEAM_TRASH_LIST}?spaceId=${spaceId}&pageNum=${pageNum ?? 0}&pageSize=30`);
  return data;
};

export const restoreFromTrash = async (spaceId, resourceId, moveToRoot, appId) => {
  let data = await post(
    `${api.TEAM_TRASH_RESTORE}?moveToRoot=${moveToRoot}`.replace(':teamId', spaceId).replace(':resourceId', resourceId), {},
    {
      headers: {
        'X-App-Id': appId ?? 2,
      },
      keepSilent: true,
    },
  );
  return data;
}

export const checkParentExist = async (spaceId, resourceId, appId) => {
  let data = await get(`${api.TEAM_TRASH_PARENT_EXIST}`.replace(':teamId', spaceId).replace(':resourceId', resourceId),
    {
      headers: {
        'X-App-Id': appId ?? 2,
      },
      keepSilent: true,
    });
  return data;
}

export const removeFromTrash = async (spaceId, resourceId, appId) => {
  let data = await del(`${api.TEAM_TRASH_REMOVE}`.replace(':teamId', spaceId).replace(':resourceId', resourceId),
    {
      headers: {
        'X-App-Id': appId ?? 2,
      },
      keepSilent: true,
    });
  return data;
}

export const checkSpaceNameAction = async (spaceId) => {
  let data = await post(api.SPACE_NAME_CHECK, spaceId);
  return data;
}

export const spaceRestoreAction = async (spaceId) => {
  let data = await post(api.SPACE_RESTORE, spaceId)
  return data;
}

export const getReductionProgress = async (taskId) => {
  const data = await get(api.REDUCTION_PROGRESS.replace(':taskId', taskId));
  const { status, step } = data;
  return {
    status,
    step,
  };
}
