import React, { useEffect, useState } from 'react'
import BackTop from './BackTop';
import FullTextComment from './FullTextComment';
import './index.less';
import _ from 'lodash-es';
import HelpGuide from './HelpGuide';

export default (props) => {
  const { sourceType, editable, commentConfig, offline, backTopVisible } = props
  const [showBack, setShowBack] = useState(false)
  const isShowBackTop = _.debounce((event) => {
    const distance = event.target.scrollTop;
    let screenHeight = window.screen.height
    if (distance > screenHeight) {
      setShowBack(true)
    } else {
      setShowBack(false)
    }
  }, 30, {
    leading: false,
    trailing: true,
  })
  useEffect(() => {
    let scrollDoc = document.querySelector('#knowledge_editor_box');
    scrollDoc?.addEventListener('scroll', isShowBackTop, false);

    return () => {
      scrollDoc?.removeEventListener('scroll', isShowBackTop, false);
    };
  }, []);

  const container = (
    <div className='utils-drawer'>
      {backTopVisible && showBack && <BackTop sourceType={sourceType} />}
      {commentConfig?.show && !editable && <FullTextComment />}
      {!offline && <HelpGuide editable={editable}/>}
    </div>
  )

  return container;
}
