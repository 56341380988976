/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-07-11 11:10:40
 * @LastEditTime: 2023-07-11 11:11:11
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/common/PathEllipsis/index.js
 *
 */
import React from 'react';
import { Tooltip } from 'antd';
import './index.less';

class PathEllipsis extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showTip: false };

    this.outerRef = null;
    this.innerRef = null;

    this.shouldCalc = false;
  }

  componentDidMount() {
    const showTip = this.innerRef.offsetWidth > this.outerRef.offsetWidth;
    this.setState({ showTip });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      const showTip = this.innerRef.offsetWidth > this.outerRef.offsetWidth;
      this.setState({ showTip }); // eslint-disable-line react/no-did-update-set-state
    }
  }

  render() {
    const { value } = this.props;
    const { showTip } = this.state;

    return (
      <Tooltip
        title={ showTip ? value : '' }
        placement='topLeft'>
        <div
          className='path-ellipsis'
          ref={ (element) => this.outerRef = element } // eslint-disable-line no-return-assign
          >
          <span
            className='path-ellipsis-inner-span'
            ref={ (element) => this.innerRef = element } // eslint-disable-line no-return-assign
          >
            { value }
          </span>
        </div>
      </Tooltip>
    );
  }
}

export default PathEllipsis;
