import { intl } from 'di18n-react';
import classBind from 'classnames/bind';
import styles from './style.module.less';

const cx = classBind.bind(styles);

const MemberItem = ({
  customClass = '',
  avatar,
  AvatarIcon,
  name,
  number,
  email,
  children,
  id,
  exist,
  nameFlex,
  department,
}) => {
  const AvatarAndInfo = (
    <>
      {avatar && <img
        className={cx('avatar')}
        src={avatar} />}
      {AvatarIcon && AvatarIcon}
      <div className={cx('info')}>
        <p className={cx('name-wrap', !email && 'online')}>
          <span className={cx('name', { nameLen: department })}>{name}</span>
          {(number || number === 0) && (
            <span className={cx('number')}> · {number}</span>
          )}
          {department && <span className={cx('department')}>{department}</span>}
          {exist && <span className={cx('existTag')}>{intl.t('已添加')}</span>}
        </p>
        {email && <p className={cx('from')}>{email}</p>}
      </div>
    </>
  );

  if (Array.isArray(children)) {
    return (
      <div
        className={`${cx('memberItem')} ${customClass}`}
        key={id}>
        {children[0]}

        {AvatarAndInfo}
        {children[1]}
      </div>
    );
  }
  return (
    <div
      className={`${cx('memberItem')} ${customClass} ${
        nameFlex ? cx('name-flex') : ''
      }`}
      key={id}
    >
      {AvatarAndInfo}
      {children}
    </div>
  );
};
export default MemberItem;
