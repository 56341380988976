import { intl } from 'di18n-react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import classNames from 'classnames/bind';
import { DKLogoIcon } from '@/assets/icon';
import usePermission from '@/hooks/usePermission';
import { getUrlParams, inPhone } from '@/utils/index';
import ErrorTips from '@/components/ErrorTipsDk';
import styles from './style.module.less';
import { useEffect, useMemo, useState, useRef } from 'react';
import { getPagePermission } from '@/service/knowledge/page';
import getDKPermission from '@/service/knowledge/permission';
import { useDispatch } from 'react-redux';

const cls = classNames.bind(styles);

function ExpireLink() {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const { getIdsFormShare } = dispatch.SharePage;
  const [count, setCount] = useState(5);

  const [jumpObj, setJumpObj] = useState({});
  const refTimerId = useRef(null);
  const { checkOperationPermission } = usePermission();

  const type = useMemo(() => {
    return getUrlParams('type') ?? '';
  }, [search]);

  const shareId = useMemo(() => {
    return getUrlParams('shareId') ?? '';
  }, [search]);

  useEffect(() => {
    if (shareId.length < 1) {
      return;
    }
    // 根据shareId获取到跳转的普通页面url
    getIdsFormShare({ shareId }).then((res) => {
      const { metisId, pageId } = res;
      if (type !== 'book') {
        getPagePermission({ pageId }).then((permission) => {
          const readPerm = checkOperationPermission(
            'READ_PAGE',
            permission.perm,
          );
          setJumpObj({
            hasJumpPerm: readPerm,
            link: `/knowledge/${metisId}/${pageId}`,
          });
        });
      } else {
        // 判断是否为知识库只读成员及以上
        getDKPermission(metisId).then((permission) => {
          const readPerm = checkOperationPermission('READ_DK', permission.perm);
          setJumpObj({
            hasJumpPerm: readPerm,
            link: `/knowledge/${metisId}/home`,
          });
        });
      }
    });
  }, []);

  useEffect(() => {
    refTimerId.current = setInterval(() => {
      setCount(prevCount => {
        if (prevCount > 0) {
          return prevCount - 1;
        } else {
          clearInterval(refTimerId.current);
          return 0;
        }
      });
    }, 1000);
  
    return () => clearInterval(refTimerId.current);
  }, []);

  useEffect(() => {
    if (shareId.length > 0 && jumpObj.hasJumpPerm && count === 0) {
      clearInterval(refTimerId.current);
      if (inPhone()) {
        window.dcH5Sdk.navigation.openWebview({
          url: window.location.origin + jumpObj.link,
          onSuccess() {},
          onFail() {},
        });
      } else {
        window.location.href = jumpObj.link;
      }
    }
  }, [count, shareId, jumpObj]);

  const desc = useMemo(() => {
    if (shareId.length > 0 && jumpObj.hasJumpPerm) {
      if (type !== 'book') {
        return intl.t('您是页面成员，{count}s后自动为您跳转', { count });
      }
      // if (inPhone()) {
      //   return false;
      // }
      return intl.t('您是知识库成员，{count}s后自动为您跳转', { count });
    }
    return false;
  }, [shareId, jumpObj, count]);

  return (
    <div>
      <Helmet>
        <link
          rel="shortcut icon"
          type="image/png"
          href="//img-ys011.didistatic.com/static/cooper_cn/ico-dbook.png" />
      </Helmet>
      <ErrorTips
        title={
          shareId.length > 0
            ? intl.t('分享已失效，不可访问')
            : intl.t('页面已被删除，不可访问')
        }
        desc={desc}
        overlayClassName={cls('error-tips')}
      />

      <p className={cls('share-close-tips')}>
        <span>{intl.t('源自')}</span>
        <img
          src={DKLogoIcon}
          className={cls('dk-logo')}
          alt={intl.t('知识库')}
        />
        <span>{intl.t('，前往使用')}</span>

        <a href="/knowledge">
          <i
            className={cls(
              'dk-icon-quanbumobanjiantou',
              'dk-iconfont',
              'goto-dk-menhu',
            )}
          />
        </a>
      </p>
    </div>
  );
}

export default ExpireLink;
