import React, { useEffect, useState, useContext } from 'react';
import { Input, message, Button } from 'antd';
import { intl } from 'di18n-react';
import { debounce } from 'lodash-es';
import MemberList from './memberList';
import { addAuthMembers, getTeamMembers } from '../service';
import { FOLDAUTH_MEMBERS_PAGESIZE } from '../contants';
import EmptyInfo from '../emptyInfo';
import TeamContext from '@/components/CooperFoldAuth/TeamContext';
import './index.less';
// 注意：这个文件存在样式污染，改成css moduels后缺少样式，待排查

const AddMember = ({ goBack, refresh }) => {
  const { teamId, resourceId } = useContext(TeamContext);
  const [keyWord, setKeyWord] = useState('');
  const [memberCount, setMemberCount] = useState(0);
  const [searchListInfo, setSearchListInfo] = useState({ loading: false, hasMore: false, pageNum: 0, list: [] });
  const [defaultListInfo, setDefaultListInfo] = useState({ loading: false, hasMore: false, pageNum: 0, list: [] });
  const [checkList, setCheckList] = useState([]);
  const [addAuthLoading, setAddAuthLoading] = useState(false);

  useEffect(() => {
    search('');
  }, []);

  const search = (key, clear = false) => {
    if (key) {
      setSearchListInfo({ ...searchListInfo, loading: true });
    } else {
      setDefaultListInfo({ ...defaultListInfo, loading: true });
    }
    let pageNum = key ? searchListInfo.pageNum : defaultListInfo.pageNum;
    if (clear) {
      pageNum = 0;
    }
    getTeamMembers({
      teamId,
      resourceId,
      pageNum,
      key,
    }).then((res) => {
      let items = ((res && res.items) || []);
      const tempList = items.map((item) => {
        return {
          ...item,
          role: 'RESOURCE_MEMBER',
        };
      });
      const more = (pageNum + 1) * FOLDAUTH_MEMBERS_PAGESIZE < res.totalCount;
      const info = { hasMore: more, pageNum: pageNum + 1, loading: false };
      if (key) {
        const temp = clear ? tempList : searchedDk.list.concat(tempList);
        setSearchListInfo({ ...info, list: temp });
      } else {
        setMemberCount(res.totalCount);
        const temp = clear ? tempList : defaultListInfo.list.concat(tempList);
        setDefaultListInfo({ ...info, list: temp });
      }
    });
  };

  const onInputChange = debounce((value) => {
    search(value, true);
    setKeyWord(value);
    window.__OmegaEvent('ep_teamspace_more_permisionset_searchadd_ck', '', {
      platform: 'new',
    });
  }, 500);

  const addAuthMember = () => {
    setAddAuthLoading(true);
    const memberList = checkList.map((item) => {
      const temp = {
        ...item,
        inviteeType: 0,
        role: item.role,
      };
      return temp;
    });
    addAuthMembers(resourceId, memberList).then(() => {
      message.success(intl.t('添加成功'));
      refresh();
      goBack();
    }).catch(() => {
      message.error(intl.t('添加失败，请重试...'));
    }).finally(() => {
      setAddAuthLoading(false);
    });
    window.__OmegaEvent('ep_teamspace_more_permisionset_successadd_ck', '', {
      platform: 'new',
    });
  };
  const changeCheckList = (val) => {
    setCheckList(val);
  };

  return <div className='addMember'>
    <Input
      onChange={(e) => onInputChange(e.target.value)}
      placeholder={intl.t('搜索添加当前团队空间成员')}
    />
    <div className='memberList-title'>{intl.t('空间团队成员{memberCount}人', { memberCount })}：</div>
    { keyWord === '' && <MemberList
      checkList={checkList}
      changeCheckList={changeCheckList}
      listInfo={defaultListInfo}
      search={search} /> }
    { keyWord !== ''
      && <div className='searchResult'>
        { searchListInfo.list.length === 0 && !searchListInfo.loading
          ? <EmptyInfo />
          : <MemberList
              keyWord={keyWord}
              checkList={checkList}
              changeCheckList={changeCheckList}
              listInfo={searchListInfo}
              search={search} /> }
      </div>
    }

    <div className='footer'>
      <p className='num'>
        {intl.t('已选择: {num}名成员', { num: checkList.length })}
      </p>
      <div className='btnArea'>
        <Button
          className='cancel btn'
          onClick={goBack}>
          {intl.t('取消')}
        </Button>
        <Button
          loading={addAuthLoading}
          disabled={checkList.length === 0}
          className='confirm btn'
          onClick={addAuthMember}>
          {intl.t('确定')}
        </Button>
      </div>
    </div>
  </div>;
};
export default AddMember;
