import classNames from 'classnames/bind';
import { intl } from 'di18n-react';
import React, { useState, useEffect } from 'react';
import { Popover } from 'antd';
import { MULTI_PERM } from './constant';
import CooperOption from './CooperOption';
import DkOption from './DkOption';
import styles from './style.module.less';


const cx = classNames.bind(styles);


const ChooseMemberPerm = ({ isDk, currRole, applyedPermObj, setChoosedData, permType }) => {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [permText, setPermText] = useState(currRole?.label);


  useEffect(() => {
    setPermText(applyedPermObj.text || currRole.label);
  }, [applyedPermObj, currRole]);


  const onSelectFinally = ({ valueFinally, labelFinally, type }) => { // value && label
    setChoosedData({
      text: labelFinally || null,
      value: valueFinally || null,
      type: type || null,
    });
    setPermText(labelFinally);
  };


  return <div
    className={cx('choose-member-perm', {
      'active-choose': popoverVisible,
    })}

    id='choose-member-perm-wrap'>
    <Popover
      content={isDk
        ? <DkOption
            onSelectFinally={onSelectFinally}
            onVisibleChange={(v) => { !v && setPopoverVisible(v); }}
            applyedPerm={applyedPermObj?.value}
            currRole={currRole}
            permType={permType}
      />
        : <CooperOption
            onSelectFinally={onSelectFinally}
            onVisibleChange={(v) => { !v && setPopoverVisible(v); }}
            applyedPermObj={applyedPermObj}
            permType={permType}
            currRole={currRole}
      />}
      visible={popoverVisible}
      trigger='click'
      placement={'bottomLeft'}
      align={{ offset: [-14, 12] }}
      onVisibleChange={(v) => {
        !v && setPopoverVisible(v);
      }}
      destroyTooltipOnHide={true}
      overlayClassName={cx('member-perm-popover', {
        'member-perm-popover-small': isDk || (!isDk && permType === MULTI_PERM),
      })}
      getPopupContainer={() => document.getElementById('choose-member-perm-wrap')}
    >
      <div
        className={cx('choosed-perm', {
          placeholder: permText === intl.t('请选择'),
        })}
        onClick={() => {
          setPopoverVisible(true);
        }}
      >
        <div className={cx('choosed-perm-text')}>{permText}</div>
        <i
          className={cx('dk-iconfont', 'dk-icon-jiantouyou', 'choosed-perm-icon', {
            'icon-up': popoverVisible,
          })}
        />
      </div>

    </Popover>
  </div>;
};

export default ChooseMemberPerm;
