import React, { useState, useEffect } from 'react';
import { message } from 'antd';
import { intl } from 'di18n-react';
import { GLOBAL_PERM, MULTI_PERM, NO_ADMIN_PERM, TEAM_SPACE, DOCS_INVITE_TYPE, NO_ADMIN_PERM_DK, GLOBAL_PERM_DK } from '@/baseComponents/ChooseMemberPerm/constant';
import { getCooperatorByName, applyHigherPermFn, getPagePermissionDk } from './service';
import ApplyPermModal from './ApplyPermModal';
import NotificationStatus from '@/constants/notification';
import useNotification from '@/hooks/useNotification';


const PERM_DEFAULT_LIST = () => [
  {
    key: 1,
    value: intl.t('查看'),
  },
  {
    key: 2,
    value: intl.t('编辑'),
  },
  // {
  //   key: 8,
  //   value: intl.t('上传')
  // },
  // {
  //   key: 16,
  //   value: intl.t('分享'),
  // },
  {
    key: 32,
    value: intl.t('下载'),
  },
];

const perm2ValueString = (permission) => {
  let perms = [];
  let permsLabel = [];
  PERM_DEFAULT_LIST().forEach((item) => {
    // eslint-disable-next-line no-bitwise
    if (permission & item.key) {
      perms.push(item.key);
      permsLabel.push(item.value);
    }
  });
  return {
    value: perms.join(','),
    label: permsLabel.join('/'),
  };
};


const ApplyHigherPerm = ({ resourceId, isPersonalNormal = '', spaceType = '', onClose, isDk }) => {
  const [roleKey, setRoleKey] = useState({});
  // {
  //  value: 'PAGE_NOPERM_MEMBER',
  //   label: '无权限成员',
  //   type: 'multiple',
  //  }
  const [permType, setPermType] = useState(GLOBAL_PERM);
  const notification = useNotification();

  // 保证点击申请弹窗时是最新的权限
  const getCurrMemberMsg = () => {
    if (isDk) {
      getPagePermissionDk({ pageId: resourceId }).then((res) => {
        let { roleKey: roleKeyData, roleNameZh, isSpaceMember } = res;
        if (roleKeyData === 'PAGE_NOPERM_MEMBER') {
          // return {
          //   value: 'PAGE_NOPERM_MEMBER',
          //   roleKeyFront: -1,
          //   label: '无权限成员',
          //   desc: '',
          //   isGlobal: true,
          //   multiple: false,
          // }
          notification(NotificationStatus.ERROR, intl.t('您无法访问该文档，不能申请更多权限'));
          setTimeout(() => {
            window.location.reload()
          }, 3000)
        } else if (roleKeyData?.length <= 0) {
          // return {
          //   value: 'PAGE_NOPERM_MEMBER',
          //   roleKeyFront: -2,
          //   label: '无权限成员',
          //   desc: '',
          //   isGlobal: true,
          //   multiple: false,
          // }
          notification(NotificationStatus.ERROR, intl.t('您无法访问该文档，不能申请更多权限'));
          setTimeout(() => {
            window.location.reload()
          }, 3000)
        } else {
          setRoleKey({
            value: roleKeyData,
            label: intl.t(roleNameZh),
            type: 'single',
          })
          setPermType(isSpaceMember ? GLOBAL_PERM_DK : NO_ADMIN_PERM_DK);
        }
      }).catch((error) => {
        const { errorCode, errorMessage } = error;
        if (errorCode === 300002 || (errorCode === 100001 && errorMessage === '资源不存在')) {
          notification(NotificationStatus.ERROR, intl.t('页面已被删除，3s后为你自动刷新页面'));
          setTimeout(() => {
            window.location.reload()
          }, 3000)
        } else {
          notification(NotificationStatus.ERROR, errorMessage);
        }
      })
    } else {
      getCooperatorByName({
        resourceId,
      }).then((res) => {
        const { permission, cooperatorType, resourceRole } = res;
        if (resourceRole === undefined) return;
        if (resourceRole === 'PAGE_NOPERM_MEMBER') { // TODO:无权限成员，下一期会存在
          // setRoleKey({
          //   value: 'PAGE_NOPERM_MEMBER',
          //   label: '无权限成员',
          //   type: 'multiple',
          // });
          message.destroy();
          message.error(intl.t('您无法访问该文档，不能申请更多权限'));
          setTimeout(() => {
            window.location.reload();
          }, 3000);
          return;
        }
        if (resourceRole.length === 0) {
          // setRoleKey({
          //   value: 'PAGE_NOPERM_MEMBER',
          //   label: '无权限成员',
          //   type: 'multiple',
          // });
          message.destroy();
          message.error(intl.t('您无法访问该文档，不能申请更多权限'));
          setTimeout(() => {
            window.location.reload();
          }, 3000);
          return;
        }

        if (spaceType === TEAM_SPACE) {
          const isInvitee = cooperatorType === DOCS_INVITE_TYPE;
          if (isInvitee) setPermType(MULTI_PERM);
        } else if (isPersonalNormal) {
          setPermType(NO_ADMIN_PERM);
        } else {
          setPermType(GLOBAL_PERM);
        }

        if (resourceRole === 'member') { // owner admin member // permission 数字
          setRoleKey({
            value: perm2ValueString(permission).value,
            label: perm2ValueString(permission).label,
            type: 'multiple',
          });
        } else if (resourceRole === 'owner' || resourceRole === 'admin') { // owner admin member // permission 数字
          setRoleKey({
            value: resourceRole,
            label: resourceRole === 'admin' ? '管理员' : '所有者',
            type: 'single',
          });
        }
      }).catch((error) => {
        const { errorCode, errorMessage } = error;
        if (errorCode === 300002 || (errorCode === 100001 && errorMessage === '资源不存在')) {
          message.error(intl.t('文档已被删除，3s后为你自动刷新页面'));
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else {
          message.destroy();
          message.error(errorMessage);
        }
      });
    }
  };

  useEffect(() => {
    getCurrMemberMsg();
    try {
      applyHigherPermFn({
        resourceId,
        isCheck: true,
        message: '',
        isReApply: false,
        adminPerm: '',
      });
    } catch (error) {
      console.log(error);
    }
  }, [isPersonalNormal, spaceType, resourceId]);


  return <ApplyPermModal
    roleKey={roleKey}
    isDk={isDk}
    permType={permType}
    onClose={onClose}
    resourceId={resourceId}
  />;
};

export default ApplyHigherPerm;
