/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-07-12 16:15:43
 * @LastEditTime: 2023-08-28 14:35:52
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/CooperFilesList/ViewSwitcher/index.js
 *
 */
import React, { useEffect, useState } from 'react';
import { noop } from 'lodash-es';
import { intl } from 'di18n-react';
import { MY_COOPER, TEAM_COOPER } from '@/constants/cooper';
import classBind from 'classnames/bind';
import styles from './style.module.less';
import { Popover } from 'antd';

const cx = classBind.bind(styles);

const menu = [
  { type: 'LIST', name: intl.t('列表视图'), icon: 'dk-icon-liebiaoshitu' },
  { type: 'TREE', name: intl.t('目录视图'), icon: 'dk-icon-mulushitu1' },
];

function SwitchView({
  onSwitch = noop, viewType,
}) {
  const activeIndex = menu.findIndex((item) => item.type === viewType);
  const [active, setActive] = useState(activeIndex === -1 ? 1 : activeIndex);
  const [open, setOpen] = useState(false)
  const handleClick = (idx) => {
    setActive(idx);
    onSwitch(menu[idx].type);
    setOpen(false)
  }

  useEffect(() => {
    setActive(activeIndex === -1 ? 1 : activeIndex);
  }, [activeIndex]);

  const changePopver = () => {
    setOpen(!open)
  }

  const _renderContent = () => {
    return (
      <div className={cx('popover-content')}>
        {
          menu.map((item, index) => {
            return <li
              key={index}
              className={cx({
                'popover-content-li': true,
                'popover-content-li-selected': menu[active].type === item.type,
              })}
              onClick={() => handleClick(index)}>
              {item.name}
              <i
                className={
                  cx('dk-iconfont', 'dk-icon-gou', 'icon-selected')
                }
              />
            </li>
          })
        }
      </div>
    )
  }
  return (
    <Popover
      content={_renderContent()}
      placement='bottomRight'
      visible={open}
      onVisibleChange={changePopver}>
      <i
        className={cx({
          'dk-iconfont': true,
          'icon-switch': true,
          'dk-icon-liebiaoshitu': menu[active].type === 'LIST',
          'dk-icon-mulushitu1': menu[active].type === 'TREE',
        })} />
    </Popover>
  )
}

export default SwitchView
