import { intl } from 'di18n-react';
import { Input } from 'antd';
import { useRef, useState, useEffect } from 'react';
import EmptyRender from '@/components/SpinRender';
import classBind from 'classnames/bind';
import TagListNode from '@/components/serviceComponents/PageTag/TagListNode';
import { checkErrorCharacter } from '@/utils';
import useDebounceFn from '@/hooks/useDebounceFn';
import styles from './style.module.less';

const cx = classBind.bind(styles);

export default function DebounceSelect({
  fetchOptions,
  placeholder,
  renderNodeItem,
  currentId,
  onClick,
  notFoundContent = (
    <div style={{ textAlign: 'center', padding: 4, color: '#bbb' }}>
      {intl.t('暂无数据')}
    </div>
  ),
  ...props
}) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState(props.data || []);
  const [inputValue, setInputValue] = useState('');
  const fetchRef = useRef(0);
  const [nameCheckMsg, setNameCheckMsg] = useState('');

  const loadOptions = (value) => {
    fetchRef.current += 1;
    const fetchId = fetchRef.current;
    setOptions([]);
    setFetching(true);

    fetchOptions(value).then((newOptions) => {
      if (fetchId !== fetchRef.current) {
        return;
      }
      setOptions(newOptions || []);
      setFetching(false);
    });
  };

  const { run: debounceReq } = useDebounceFn(loadOptions);

  const onChange = (e) => {
    const { value } = e.target;
    let checkMsg = checkErrorCharacter(value);

    if (value.length > props.maxLength) {
      checkMsg = intl.t('最多支持{slot0}个字符', { slot0: props.maxLength });
    }

    setNameCheckMsg(checkMsg);

    setInputValue(value);

    if (checkMsg === '') {
      debounceReq(value);
    }
  };

  const onPressEnter = (e) => {
    if (props.onPressEnter) {
      props.onPressEnter(e.target.value);
      setInputValue('');
    }
  };

  const onChoose = (v) => {
    onClick && onClick(v);
    setInputValue('');
  };

  useEffect(() => {
    if (!props.data) {
      loadOptions();
    }
  }, []);

  return (
    <div className={cx('search-popover-content')}>
      {props.showSearch && (
        <i
          className={`${cx('dk-icon-sousuo', 'dk-iconfont')} ${cx(
            'switch-search-icon',
          )}`}
        />
      )}
      <Input
        className={cx('switch-search-input')}
        value={inputValue}
        autoFocus
        onChange={onChange}
        placeholder={placeholder}
        onPressEnter={onPressEnter}
      />

      {nameCheckMsg !== '' && (
        <div className={cx('check-msg')}>{nameCheckMsg}</div>
      )}

      {nameCheckMsg === '' && options.length ? (
        <ul>
          {options.map((v) => (
            <li
              key={v.id}
              className={cx({ active: currentId === v.id })}
              onClick={() => onChoose(v)}
            >
              {renderNodeItem ? (
                renderNodeItem(v)
              ) : (
                <TagListNode nodeData={v} />
              )}

              {currentId === v.id && (
                <i
                  className={cx(
                    'dk-icon-gou',
                    'dk-iconfont',
                    'check-icon',
                  )}
                />
              )}
            </li>
          ))}
        </ul>
      ) : (
        <>{fetching ? <EmptyRender loading={true} /> : notFoundContent}</>
      )}
    </div>
  );
}
