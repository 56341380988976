import { intl } from 'di18n-react';
import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { NotSupportPreviewIcon } from '@/assets/icon';
import { fileSupportPreview, bytesToSize } from '@/utils';
import { getSharePreviewUrl, getSharePreviewFileInfo, getBookSharePreviewUrl, getBookSharePreviewFileInfo } from '@/service/knowledge/share';
import ErrorTips from '@/components/ErrorTipsDk';
import styles from './style.module.less';
import { getSnapshotPreviewUrl, getPreviewFileInfo } from '@/service/knowledge/page';

const cx = classNames.bind(styles);

const SharePreview = ({
  isDkShare,
  fileId,
  fileName,
  shareId,
  pageId,
  isSnapshot = false,
  changeInitLoading,
}) => {
  const [previewUrl, setPreviewUrl] = useState('');
  const [fileSize, setFileSize] = useState(0);
  const supportPreview = fileSupportPreview(fileName);
  useEffect(async () => {
    const version = window.location.pathname.split('/').pop();
    const Url = window.location.href.split('&')[1];
    const mapId = Url ? Url.split('=')[1] : '';
    if (isSnapshot) {
      if (supportPreview) {
        const res = await getSnapshotPreviewUrl({
          pageId,
          fileId,
          pageName: fileName,
          version,
          mapId,
        });
        setPreviewUrl(res.url);
      } else {
        const res = await getPreviewFileInfo(pageId, { contentStatus: 1 });
        setFileSize(res.fileSize);
      }
      changeInitLoading(false);
    } else if (supportPreview) {
      const api = isDkShare ? getBookSharePreviewUrl : getSharePreviewUrl;
      const res = await api(shareId, { pageId, fileId, pageName: fileName });
      setPreviewUrl(res.url);
    } else {
      const api = isDkShare
        ? getBookSharePreviewFileInfo
        : getSharePreviewFileInfo;
      const data = await api(pageId, shareId, { contentStatus: 0 });
      setFileSize(data.fileSize);
    }
  }, [fileId]);

  return (
    <div className={cx('preview-wrap')}>
      {supportPreview ? (
        <>
          <iframe
            allowFullScreen
            className={cx('preview', { hasHeader: !isDkShare })}
            src={previewUrl}
          />
        </>
      ) : (
        <ErrorTips
          img={NotSupportPreviewIcon}
          title={<div>{intl.t('文件类型不支持预览')}</div>}
          desc={
            <div className={cx('error-desc')}>
              <p>{fileName}</p>
              <p>{bytesToSize(fileSize)}</p>
            </div>
          }
        />
      )}
    </div>
  );
};

export default SharePreview;
