import { intl } from 'di18n-react';

import classNames from 'classnames/bind';
import styles from './style.module.less';

const cls = classNames.bind(styles);

function ShareEntryDK({ isSharing }) {
  return (
    <span className={cls('share-trigger')}>
      <i
        className={cls('dk-iconfont', 'dk-icon-dian', 'status-icon', {
          'is-active': isSharing,
        })}
      />
      {isSharing ? intl.t('分享已开启') : intl.t('分享已关闭')}
    </span>
  );
}

export default ShareEntryDK;
