import { intl } from 'di18n-react';
import { useMemo } from 'react';
import { beautySub, highLightKeyword } from '@/utils';
import classBind from 'classnames/bind';
import { Tooltip } from 'antd';

const cx = classBind.bind();

const MemberEmail = ({
  groupUsers,
  keyword,
  memberEmail,
  customClass,
  maxlength,
}) => {
  const getSearchResultinGroup = useMemo(() => {
    if (!groupUsers) {
      return '';
    }
    return intl.t('包含:{slot0}', {
      slot0: groupUsers
        .map(({ cnName, email }) => {
          return `${cnName}(${email})`;
        })
        .join(','),
    });
  }, [groupUsers]);

  return (
    <Tooltip
      title={getSearchResultinGroup}
      placement="top"
      overlayClassName={cx('dk-ant-tooltip__reset', {
        hide: getSearchResultinGroup < maxlength,
      })}
    >
      <span
        className={customClass}
      >{
        groupUsers
          ? highLightKeyword(
            keyword,
            beautySub(getSearchResultinGroup, maxlength),
          )
          : highLightKeyword(keyword, memberEmail)
      }</span>
    </Tooltip>
  );
};

export default MemberEmail;
