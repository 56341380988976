import React from 'react';
import { useDragLayer } from 'react-dnd';
import classBind from 'classnames/bind';
import styles from './style.module.less';

const cx = classBind.bind(styles);

function snapToGrid(x, y) {
  const snappedX = Math.round(x / 32) * 32;
  const snappedY = Math.round(y / 32) * 32;
  return [snappedX, snappedY];
}


const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
};

function getItemStyles(initialOffset, currentOffset, isSnapToGrid) {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }

  let { x, y } = currentOffset;

  if (isSnapToGrid) {
    x -= initialOffset.x;
    y -= initialOffset.y;
    [x, y] = snapToGrid(x, y);
    x += initialOffset.x;
    y += initialOffset.y;
  }

  const transform = `translate(${x}px, ${y}px)`;

  return {
    transform,
    WebkitTransform: transform,
  };
}

function CustomDragLayer(props) {
  const { itemType, isDragging, item, initialOffset, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  if (!isDragging) {
    return null;
  }
  // 只针对 DragDropBox 做处理  否则会和全局文件上传的逻辑有耦合
  if (itemType === 'DragDropBox') {
    const { num } = props
    const { image = '', name = '' } = item?.idx
    return (
      <div style={layerStyles}>
        <div style={getItemStyles(initialOffset, currentOffset, props.snapToGrid)}>
          <div
            className={cx('drag-row')}
            style={num > 1 ? { boxShadow: '4px 4px 0 0 rgba(47,52,60,0.20)' } : {}}
          >
            <img src={image} />
            <span className={cx('drag-span')}>{name}</span>
            {num > 1 && <div className={cx('num')}>{num}</div>}
          </div>
        </div>
      </div>
    );
  }
  return <></>
}

export default CustomDragLayer
