import React, { useState } from 'react'
import { Tooltip } from 'antd';
import { intl } from 'di18n-react';

const backTop = () => {
  let scrollDoc = document.querySelector('#knowledge_editor_box')
  scrollDoc.scrollTop = 0
  // TODO 补充埋点
}

export default (props) => {
  return (
    <React.Fragment>
      <Tooltip
        multiline
        className="tooltip"
        place="left"
        effect="solid"
        backgroundColor="rgba(0, 0, 0, 0.8)"
        border={false}
        arrowColor="rgba(0, 0, 0, 0.75)"
        title={intl.t('回到顶部')}
      >
        <div
          className='icon-box'
          onClick={() => backTop(props.sourceType)}
        >
          <svg
            style={{ width: '20px' }}
            className="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="19448"
            width="512"
            height="512"><path
              d="M960.682667 90.168889v85.333333H62.065778v-85.333333h898.616889zM498.176 302.648889a22.755556 22.755556 0 0 1 31.971556 3.185778l150.414222 183.239111a22.755556 22.755556 0 0 1-17.578667 37.205333H554.666667v404.821333h-85.333334V526.222222l-108.088889 0.056889A22.755556 22.755556 0 0 1 349.866667 523.377778l-3.242667-2.275556a22.755556 22.755556 0 0 1-3.015111-32.028444L494.933333 305.777778a22.755556 22.755556 0 0 1 3.128889-3.128889z"
              p-id="19449" /></svg>
        </div>
      </Tooltip>
    </React.Fragment>
  )
}
