import { get, post } from '@/utils/request';
import api from '@/utils/request/api/DkApi';
import { encodeURIParamFn } from '@/utils';

export async function createKnowledge(params) {
  const res = await post(api.CREATE_DK, params, {
    headers: {
      'X-App-Id': 4,
    },
  });
  return res;
}

export async function getTemplate() {
  const res = await get(api.GET_TEMPLATE);
  return res;
}

export async function getTeamMembers(teamId) {
  const res = await get(`${api.TEAM_MEMBERS.replace(':teamId', teamId)}`, {
    params: {
      pageNum: 0,
      pageSize: 10000,
    },
  });
  return res;
}

export async function getTeamDetail(teamId) {
  const res = await get(`${api.TEAM_DETAIL.replace(':teamId', teamId)}`, {
    headers: {
      'X-App-Id': 2,
    },
  });
  return res;
}

export async function getDepartment(par) {
  const res = await get(`${api.GET_DEPARTMENT}${encodeURIParamFn(par)}`);
  return res;
}

export async function canDepartmentUse(params) {
  const res = await get(api.CAN_DEPARTMENT_USE, { params });
  return res;
}

export async function getMySpaceCreation() {
  const res = await get(api.MY_SPACE_CREATION, {
    headers: {
      'X-App-Id': 2,
    },
  });
  return res;
}


