/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-10-27 16:03:23
 * @LastEditTime: 2023-11-23 15:46:39
 * @Description: 预览服务出口文件
 * @FilePath: /knowledgeforge/src/components/FilePreview/index.js
 *
 */
import LazyComponent from './LazyComponent';


const FilePreview = (props) => <LazyComponent
  impt={import('./main')}
  config={{ ...props }} />;

export default FilePreview;
