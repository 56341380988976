import { intl } from 'di18n-react';
import { Fragment, useEffect, useState } from 'react';
import classBind from 'classnames/bind';
import { Modal } from 'antd';
import MemberInput from '@/components/serviceComponents/MemberComponent/MemberInput';
import ModalTitleTip from '@/components/ModalTitleTip';
import AddCollaborator from './addCollaborator';
import { ADD_MEMBER_FROM_TYPE } from '@/constants/setup';
import styles from './style.module.less';

const cx = classBind.bind(styles);

const AddMember = ({
  onInputChange,
  permissionType,
  inSetup = true,
  currId,
  invite,
  batchAdd,
  placeholder = '',
  getOpenAddMemberModalFn,
  addMemberPermission = true,
  addManagerPermission = true,
  existMessage,
  EpClickBatchAddFns,
  children,
  hasTip = true,
  groupOmegaType,
  inDk,
}) => {
  const [showAddMemberModal, setShowAddMemberModal] = useState(false);
  const [showBatchAdd, setShowBatchAdd] = useState(false);

  useEffect(() => {
    getOpenAddMemberModalFn(() => openAddMemberModal);
  }, []);

  const openAddMemberModal = (type, source) => {
    switch (type) {
      case ADD_MEMBER_FROM_TYPE.Dk_Group:
        window.__OmegaEvent('ep_dkpc_member_creategroup_invite_ck', '', {
          source,
        });
        break;
      case ADD_MEMBER_FROM_TYPE.DK:
        window.__OmegaEvent('ep_dkpc_member_invite_ck');
        break;
      case ADD_MEMBER_FROM_TYPE.DK_PAGE:
        window.__OmegaEvent('ep_dkpc_pagedetail_invite_ck');
        break;
      default:
    }

    setShowAddMemberModal(true);
  };
  const closeAddMemberModal = () => {
    setShowAddMemberModal(false);
    setShowBatchAdd(false);
  };
  const backToAddMemberModal = () => {
    setShowBatchAdd(false);
    EpClickBatchAddFns.return();
  };

  const ModalTitle = () => {
    if (showBatchAdd) {
      return (
        <div>
          <i
            onClick={backToAddMemberModal}
            className={cx('dk-iconfont', 'dk-icon-fanhuiyemian', 'back')}
          />
          {intl.t('批量添加')}
        </div>
      );
    }
    return (
      <div>
        {intl.t('添加成员')}

        {hasTip && (
          <ModalTitleTip
            title={intl.t('权限说明：')}
            desc={[
              `· ${
                inSetup
                  ? intl.t('已是知识库成员，通过群组再次添加时不会更改该成员当前知识库权限')
                  : intl.t('已是页面成员，通过群组再次添加时不会更改该成员当前页面权限')
              }`,
              `· ${intl.t(
                '同时添加单个用户和包含该用户的群组时，该用户取添加单个用户时设置的权限',
              )}`,
              `· ${intl.t(
                '同时添加包含某个用户的多个群组时，该用户取较高权限（{slot0}成员>只读成员）',
                { slot0: inSetup ? intl.t('管理员>') : '' },
              )}`,
            ]}
          />
        )}
      </div>
    );
  };

  return (
    <div className={cx('addMember')}>
      <MemberInput
        customStyle={
          inSetup
            ? { width: '420px', marginLeft: '0' }
            : { margin: '0 20px 10px 30px' }
        }
        onInputChange={onInputChange}
        placeholder={placeholder}
      />
      {addMemberPermission && <Fragment>{children}</Fragment>}
      {showAddMemberModal && (
        <Modal
          title={ModalTitle()}
          visible={true}
          closeIcon={
            <i className={cx('dk-iconfont', 'dk-icon-guanbi', 'close')} />
          }
          onCancel={closeAddMemberModal}
          footer={null}
          width={showBatchAdd ? 640 : 550}
          wrapClassName={cx(
            'addMemberModal',
            showBatchAdd ? 'batchAdd' : '',
            'knowledge_component_addMember',
          )}
          maskStyle={{ zIndex: 1030 }}
        >
          <AddCollaborator
            inDk={inDk}
            addManagerPermission={addManagerPermission}
            permissionType={permissionType}
            currId={currId}
            invite={invite}
            batchAdd={batchAdd}
            showBatchAdd={showBatchAdd}
            setShowBatchAdd={(v) => setShowBatchAdd(v)}
            existMessage={existMessage}
            onClose={closeAddMemberModal}
            EpClickBatchAddFns={EpClickBatchAddFns}
            groupOmegaType={groupOmegaType}
          />
        </Modal>
      )}
    </div>
  );
};

export default AddMember;
