import { intl } from 'di18n-react';
import { Button } from 'antd';
import classNames from 'classnames/bind';
import CopyToClipboard from 'react-copy-to-clipboard';
import { openNewWindow } from '@/utils';
import { SHARE_TYPE } from '@/constants/index';
import styles from './style.module.less';

const cls = classNames.bind(styles);

const ShareLink = ({
  shareLink,
  shareTypeText,
  resourceName,
  copyCallBack,
  shareType,
}) => {
  return (
    <div className={cls('share-link')}>
      <input
        className={cls('link')}
        value={
          shareLink ? `${shareLink}#${shareTypeText}《${resourceName}》` : ''
        }
        readOnly
      />

      <Button
        className={cls('preview-link')}
        onClick={() => {
          shareType === SHARE_TYPE.PAGE
            ? window.__OmegaEvent('ep_dkpc_pagedetail_share_preview_ck')
            : window.__OmegaEvent('ep_dkpc_share_preview_ck');
          openNewWindow(shareLink);
        }}
      >
        {intl.t('预览')}
      </Button>
      <CopyToClipboard
        text={`${shareLink}#${shareTypeText}《${resourceName}》`}
        onCopy={copyCallBack}
      >
        <Button className={cls('copy-button')}>{intl.t('复制链接')}</Button>
      </CopyToClipboard>
    </div>
  );
};

export default ShareLink;
