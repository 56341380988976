/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-07-24 10:54:49
 * @LastEditTime: 2023-08-04 14:58:11
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/CooperFilesList/DragLi/index.js
 *
 */
import { useDrag, useDrop } from 'react-dnd';
import { useRef, useEffect, Fragment } from 'react';
import { noop } from 'lodash-es';
import { getEmptyImage } from 'react-dnd-html5-backend';
import classBind from 'classnames/bind';
import styles from './style.module.less';
import { FlowChart } from '@/constants/cooperConstants';

const cx = classBind.bind(styles);

function Li({
  children,
  idx = 0,
  switchOrder = noop,
}) {
  // 处理拖拽
  const ref = useRef(null);
  const [{ isOver }, drop] = useDrop({
    accept: 'DragDropBox',
    drop: (item) => {
      if (!ref.current) {
        return
      }
      let dragIndex = item.idx;
      let hoverIndex = idx;
      if (hoverIndex === dragIndex) {
        return
      }
      switchOrder(dragIndex, hoverIndex)
    },
    collect: (monitor) => ({
      isOver: monitor.isOver() && idx.type === 1,
    }),
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'DragDropBox',
    item: () => {
      return { idx }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => {
      return idx.mime_type !== 9 && idx.type !== FlowChart
    },
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true }); // 隐藏拖拽dom
  }, []);


  return (
    <Fragment>
      <li
        ref={drag(drop(ref))}
        key={idx}
        style={{
          opacity: isDragging ? 0.5 : 1,
        }}
        className={cx({ 'hight-light': isOver })}>
        {children}
      </li>
    </Fragment>

  )
}

export default Li
