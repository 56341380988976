import React, { useContext, useMemo } from 'react';
import { intl } from 'di18n-react';
import { getPermissionOptions, DEFAULT_PERM_ID, FULL_AUTH_PERM, PERM_DEFAULT_LIST } from '../../contants';
import DropdownCheckbox from '@/components/common/DropdownCheckbox';
import TeamContext from '@/components/CooperFoldAuth/TeamContext';
import { roleMap } from '../../util';
import '../../MemberPermContent/userPermSelect/index.less';


const UserPermSelect = ({ memberInfo, onChangePermission }) => {
  const { role, cooperate_with_cn: name, email, perm } = memberInfo;
  const { userRole } = useContext(TeamContext);

  const permList = useMemo(() => {
    if (!perm) return DEFAULT_PERM_ID;
    let perms = [];
    for (let i = 0; i < PERM_DEFAULT_LIST.length; i++) {
      // eslint-disable-next-line no-bitwise
      if (perm & PERM_DEFAULT_LIST[i]) perms.push(i);
    }
    return perms;
  }, [perm]);

  const folderPermsDefault = getPermissionOptions();
  // 修改权限 ----查看删除等
  const onCheckboxGroupChange = (value, checkedValues) => {
    let parmArr = value[0] === 0 ? value : [0].concat(value);
    let permNum = null;
    parmArr.forEach((v) => {
      permNum += folderPermsDefault[v].perm;
    });
    onChangePermission({ permNum, role });
  };

  const onManageClick = (item, isAdd) => {
    let permNum = FULL_AUTH_PERM;
    let roleCurr = 'RESOURCE_MEMBER';
    if (isAdd) {
      permNum = 0;
      roleCurr = 'RESOURCE_ADMIN';
    }
    onChangePermission({ permNum, role: roleCurr });
  };


  const getActions = (item) => {
    const res = [];

    if (userRole === 'SUPER_ADMIN') {
      if (roleMap[role] === 'MEMBER') {
        res.unshift({
          label: intl.t('设为管理员'),
          handle: () => {
            onManageClick(item, true);
          },
        });
      } else {
        res.unshift({
          label: intl.t('取消管理员'),
          handle: () => {
            onManageClick(item, false);
          },
        });
      }
    }

    return res;
  };

  return <div
    className={'user-item-right'}
    key={email}>
    <DropdownCheckbox
      isNewStyle={true}
      options={
          roleMap[role] === 'ADMIN'
            ? []
            : folderPermsDefault
        }
      placeholder={
          roleMap[role] === 'ADMIN' ? intl.t('管理员') : null
      }
      defaultOptions={folderPermsDefault}
      actions={getActions(memberInfo)}
      value={permList}
      onChange={onCheckboxGroupChange}
      changeDisabled={false}
    />
  </div>;
};

export default UserPermSelect;
