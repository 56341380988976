import { Fragment, useEffect, useState } from 'react';
import { intl } from 'di18n-react';
import GetHtml from '@/utils/DOMPurify'
import classBind from 'classnames/bind';
import cls from 'classnames';
import { highlight } from '@/utils';
import Tag from '@/components/Tag';
import styles from './style.module.less';
import { Tooltip } from 'antd';
import {
  getGlobalFileDir,
} from '@/service/cooper/home';
import { FileType } from '@/constants/cooper';
import { setImgUrl } from '@/utils/cooperutils';
import ImageEnlarger from '@/components/common/ImageEnlarger';
import { dkPage } from '@/assets/icon/fileIcon';

const cx = classBind.bind(styles);

// TODO: 折行时hover弹tooltip; 全局用到的地方修改传参
// 搜索的位置字段最后的逻辑：最近浏览和首页保持一致，分享非根目录文档的位置显示有误。
const FileContent = ({
  iconImage = dkPage,
  resourceName,
  description,
  children,
  scopeName,
  resourceId,
  parentId,
  teamId,
  spaceType,
  resourceOwner,
  publishDesc,
  refreshDesc,
  fileTypeStr,
  isFromCooperSearch,
  tags,
  isLarge = false,
  isInTable = false,
  onPositionClick,
  overlayClassName,
  isShowOuter = false,
  itemInfo = {},
}) => {
  const [visible, setVisible] = useState(false);
  const [svgData, setSvgData] = useState(null);
  const [globalDir, setGlobalDir] = useState(null);
  const [scopeNameTipsVis, setScopeNameTipsVis] = useState(null);
  const [isMouseOver, setIsMouseOver] = useState();

  const [timer, setTimer] = useState();
  const {
    FILE, DIR, COOPER_DOC, COOPER_SHEET, COOPER_SLICE, COOPER_ANONYMOUS_DOC,
    SHIMO2_WORD, SHIMO2_EXCEL, SHIMO2_PPT, FLOWCHART,
    DI_DOC, DK_PAGE, DK_FILE, PAGE_SHARE, DK_SHARE, DK_RESOURCE, KNOWLEDGE, SPACE,
    WIKI_PAGE, WIKI_PAGE_V2, DK_SHEET, PERSONAL_SPACE, PERSONAL_SPACE_V2, PERSONAL_SPACE_V3,
    DIAGRAMING, MINDMAP,
  } = FileType;

  useEffect(() => {
    if (fileTypeStr === FileType.FLOWCHART) {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', iconImage, true);
      xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 300) {
          const svgContent = xhr.responseText;
          setSvgData(GetHtml(svgContent))
        } else {
          setSvgData(null);
        }
      };
      xhr.send();
    } else {
      setSvgData(null);
    }
  }, [fileTypeStr, iconImage]);

  useEffect(() => {
    if (isMouseOver === false) {
      setScopeNameTipsVis(false);
    }
  }, [globalDir]);

  const getWholePath = () => {
    if (scopeName === '分享给我' || scopeName === '链接分享') {
      setGlobalDir(intl.t('分享给我'));
      return;
    }

    if ((spaceType === PERSONAL_SPACE
      || spaceType === PERSONAL_SPACE_V2
      || spaceType === PERSONAL_SPACE_V3) && parentId === 0) {
      setGlobalDir(scopeName);
      return; // 个人空间跟文件位置显示scopeName，其他的调用接口
    }

    // 知识库页面
    if ([DK_PAGE, DK_FILE, PAGE_SHARE, DK_SHARE, DK_RESOURCE, DK_SHEET].includes(fileTypeStr)) {
      setGlobalDir(intl.t(`${intl.t('知识库')}/${scopeName}`));
      return;
    }

    if (fileTypeStr === WIKI_PAGE || fileTypeStr === WIKI_PAGE_V2) {
      setGlobalDir(intl.t(`${intl.t(`Wiki/${scopeName}`)}`));
      return;
    }

    getGlobalFileDir(parentId, teamId).then((res) => {
      setGlobalDir(res);
      setScopeNameTipsVis(!scopeNameTipsVis);
    });
  }

  return (
    <div
      key={resourceId}
      className={cx(
        'file-content-wrap',
        {
          'file-content-wrap-large': isLarge,
          'no-padding-left': isInTable,
        },
        overlayClassName,
      )}
    >
      <ImageEnlarger
        src={setImgUrl({
          resourceType: fileTypeStr,
          tinyImage: iconImage,
        })}
        isTiny={itemInfo.tiny ? (!!itemInfo.tiny) : false }
        mimeType={itemInfo.mime_type || itemInfo.mimeType}
        resourceType={fileTypeStr}
        overlayClassName={cls('search-item-icon')}
      />

      <div className={cls('file-text-main')}>
        <div className={cx('title')}>
          {resourceName && (
            <p
              className={cx('title-text')}
              title={resourceName?.replace(/§§/g, '')}
            >
              {highlight(resourceName || '')}
            </p>
          )}
          {tags?.length > 0 && (
            <div className={cx('tag-list')}>
              {tags.map((tag, index) => {
                return (
                  <Fragment key={tag}>
                    <Tooltip
                      visible={visible}
                      title={
                        tag === intl.t('访客链接')
                          ? intl.t('通过开启知识库/页面分享方式获得的链接')
                          : null
                      }
                    >
                      <Tag
                        type="blue-primary"
                        text={tag}
                        onMouseOver={() => {
                          setVisible(true);
                        }}
                        onMouseOut={() => {
                          setVisible(false);
                        }}
                      />
                    </Tooltip>
                  </Fragment>
                );
              })}
            </div>
          )}
          {
          isShowOuter && <Tag
            type='out-yellow'
            text={intl.t('外部')}/>
          }
        </div>
        {description && (
          <p
            className={cx('description')}
            title={description?.replace(/§§/g, '')}
          >
            {highlight(description || '')}
          </p>
        )}
        {children ? (
          <div className={cx('addition')}>{children}</div>
        ) : (
          <div className={cx('addition')}>
            {
              fileTypeStr !== KNOWLEDGE
              && fileTypeStr !== SPACE
              && fileTypeStr !== PERSONAL_SPACE
              && (!!scopeName)
              && (
                <Tooltip
                  key={resourceId}
                  title={(globalDir && globalDir.length > 0) ? globalDir : null}
                  placement="topLeft"
                  visible={scopeNameTipsVis}
                  onVisibleChange={setScopeNameTipsVis}
                >
                  <div
                    className={cx('metis-name item-div')}
                    onMouseOver={() => {
                      setIsMouseOver(true);
                      if (!globalDir) {
                        getWholePath();
                      }
                      const hoverTimer = setTimeout(() => {
                        setScopeNameTipsVis(true);
                      }, 2000);
                      setTimer(hoverTimer);
                    }}
                    onMouseOut={() => {
                      setIsMouseOver(false);
                      clearTimeout(timer);
                      setScopeNameTipsVis(false)
                    }}
                  >
                    <span
                      className={cx('metis-span item-span')}
                      onClick={(e) => {
                        e.stopPropagation();
                        isFromCooperSearch && window.__OmegaEvent('ep_search_location_ck');
                        onPositionClick && onPositionClick();
                      }}

                    >
                      {
                        <>
                          {
                            (scopeName === '分享给我' || scopeName === '链接分享')
                              ? <i class={cx('dk-iconfont', 'dk-icon-fenxiang1', 'scope-icon')} />
                              : (
                                spaceType === PERSONAL_SPACE
                              || spaceType === PERSONAL_SPACE_V2
                              || spaceType === PERSONAL_SPACE_V3
                              ) ? <i className={cx('dk-iconfont', 'dk-icon-gerenkongjian', 'scope-icon')} />
                                : <i
                                    className={cx('dk-iconfont', {
                                      'dk-icon-zhishikuzuixin': [DK_PAGE, DK_FILE, PAGE_SHARE, DK_SHARE, DK_RESOURCE, DK_SHEET].includes(fileTypeStr),
                                      'dk-icon-tuanduikongjian4px': [FILE, DIR, COOPER_DOC, COOPER_SHEET, COOPER_SLICE, COOPER_ANONYMOUS_DOC, SHIMO2_WORD, SHIMO2_EXCEL, SHIMO2_PPT, FLOWCHART, DI_DOC, DIAGRAMING, MINDMAP].includes(fileTypeStr),
                                    }, 'scope-icon')}
                            />
                          }
                          {scopeName}  {/* 空间名称/文件夹名称 */}
                        </>
                      }

                    </span>
                  </div>
                </Tooltip>

              )
            }
            {resourceOwner && (
              <div
                title={resourceOwner}
                className={cx('owner item-div')}>
                <span className={cx('owner-span item-span')}>
                  {intl.t('所有者')}:
                  {resourceOwner}
                </span>
              </div>
            )}
            {publishDesc && (
              <div
                className={cx('publish item-div')}
                title={publishDesc}>
                <span className={cx('item-span')}>{publishDesc}</span>
              </div>
            )}
            {refreshDesc && (
              <div
                className={cx('refresh item-div')}
                title={refreshDesc}>
                <span className={cx('item-span')}>{refreshDesc}</span>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default FileContent;
