import { intl } from 'di18n-react';
import classNames from 'classnames/bind';
import { Popover, Button, Tooltip } from 'antd';
import { powerManagermentCheckIcon, SwitchLocaleIcon } from '@/assets/icon';
import { MemberRoleMap } from '../config.js';
import { throttle } from 'lodash-es';
import styles from './style.module.less';
import { useEffect, useState, useContext, useCallback } from 'react';
import LayoutContext from '@/components/serviceComponents/Layout/layoutContext';
// import * as PageService from '@/service/knowledge/page';

const cls = classNames.bind(styles);

const InheritSelect = ({
  inheritValue,
  InheritConfig: Config = {},
  inheritValueSource,
  itemMessage,
  bottomLink,
  changePermExplainText,
  isRootNode,
  // knowledgeMember,
  changeInherit = () => {},
}) => {
  const { pageId } = useContext(LayoutContext);
  const [InheritConfig, setInheritConfig] = useState(Config);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [chooseTitle, setChooseTitle] = useState();

  const getMemberRoleList = (operationList) => {
    const res = {};
    if (operationList?.length > 0) {
      operationList.forEach((operation) => {
        res[operation] = MemberRoleMap[operation];
      });
    }
    return res;
  };

  useEffect(() => {
    if (itemMessage?.sources === 'ALONE_PERM' && itemMessage?.operationListNew) {
      setInheritConfig(getMemberRoleList(itemMessage?.operationListNew) || {});
    }
  }, [itemMessage]);

  useEffect(() => {
    setChooseTitle(
      itemMessage
        ? MemberRoleMap[inheritValue]?.title
        : InheritConfig[inheritValue]?.title,
    );
  }, [InheritConfig, inheritValue]);

  const doChange = throttle(
    async (configItem) => {
      changeInherit({ value: configItem.value, itemMessage });
      setPopoverVisible(!popoverVisible);
      // 成员采用刷新列表，继承方式采用前端设置
      if (!itemMessage) {
        setChooseTitle(configItem.title);
      }
    },
    2000,
    { trailing: false },
  );

  const onVisibleChange = (visible) => {
    setPopoverVisible(visible);
  };

  const hasOperatePerm = () => {
    return Object.keys(InheritConfig)?.length > 0;
  };

  const chooseContent = () => {
    if (Object.keys(InheritConfig).length === 0) return <div />;
    return (
      <div className={cls('changePower-inherit')}>
        {changePermExplainText && (
          <div className={cls('change-perm-text-wrap')}>
            <p className={cls('change-perm-text')}>{changePermExplainText}</p>
          </div>
        )}

        {Object.keys(InheritConfig).map((option) => {
          return (
            <React.Fragment key={option}>
              {option === 'RESUME' && (
                <Tooltip
                  title={
                    <div>
                      {intl.t('恢复后当前页面权限跟随{slot0}权限自动更改', {
                        slot0: isRootNode ? intl.t('知识库新的') : intl.t('父页面'),
                      })}
                    </div>
                  }
                  placement="top"
                  style="white-space:pre"
                  overlayClassName="dk-ant-tooltip__reset"
                >
                  <div className={cls('resume')}>
                    <span className={cls('resume-text')}>
                      {intl.t('该用户权限已被修改，可恢复继承')}
                    </span>
                    <Button
                      className={cls('resume-btn')}
                      onClick={() => doChange(InheritConfig[option])}
                    >
                      {intl.t('恢复')}
                    </Button>
                  </div>
                </Tooltip>
              )}

              {option !== 'RESUME' && (
                <div
                  className={cls(
                    'changePower-item',
                    {
                      'remove-special': InheritConfig[option].isRemove,
                    },
                    { disabled: false },
                  )}
                  onClick={() => doChange(InheritConfig[option])}
                >
                  <div className={cls('item-context-wrap')}>
                    <div className={cls('item-context')}>
                      <p className={cls('item-context-title')}>
                        {InheritConfig[option].title}
                      </p>
                      <p className={cls('item-context-text')}>
                        {InheritConfig[option].desc}
                      </p>
                    </div>
                    {inheritValue === InheritConfig[option].value && (
                      <img
                        className={cls('checkIcon')}
                        src={powerManagermentCheckIcon}
                      />
                    )}
                  </div>
                </div>
              )}
            </React.Fragment>
          );
        })}

        <div className={cls('bottom-link')}>
          <a
            href={bottomLink.link}
            className={cls('bottom-link-context')}
            target="_blank"
          >
            <span>{bottomLink.text}</span>
            <i
              className={cls(
                'dk-icon-xingzhuangjiehe',
                'dk-iconfont',
                'perm-link',
              )}
            />
          </a>
        </div>
      </div>
    );
  };
  return (
    <div
      className={cls('identity-select', { noHover: !hasOperatePerm() })}
      key={inheritValue}
    >
      <Popover
        trigger="click"
        overlayClassName={cls(
          'dk-changePower-wrap_reset',
          'dk-ant-popover__reset',
          'identity-select-popover',
          { hideChangePowerPopover: !hasOperatePerm() },
        )}
        content={chooseContent}
        onVisibleChange={onVisibleChange}
        visible={popoverVisible}
        placement={'bottom'}
      >
        <div className={cls('identity-text')}>
          <p className={cls('identity-text-large')}>{chooseTitle}</p>
          {itemMessage && (
            <p className={cls('identity-text-little')}>{inheritValueSource}</p>
          )}
        </div>
        <img
          className={cls('openChangePower', { hide: !hasOperatePerm() })}
          src={SwitchLocaleIcon}
        />
      </Popover>
    </div>
  );
};
export default InheritSelect;
