import React, { useEffect, useState, createContext, useMemo } from 'react';
import { Modal, message, Spin } from 'antd';
import { intl } from 'di18n-react';
import { getUserRole, getInheritStatus } from './service';
import MemberPermContent from './MemberPermContent';
import TeamContext from '@/components/CooperFoldAuth/TeamContext';
import RestrictLength from '@/components/RestrictLength';
import { roleMap } from './util';
import './index.less';


const CooperFoldAuth = ({
  resourceId,
  teamId,
  folderName,
  onCancel,
  refreshFileOrCrumb,
  goRootFolder,
  getTeamListData,
}) => {
  const [addMemberOpen, setAddMemberOpen] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [isInherit, setIsInherit] = useState(null);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    getUserRoleForFold();
    getIsInherit();
  }, []);

  useEffect(() => {
    if (userRole !== null && isInherit !== null) { setLoading(false); }
  }, [userRole, isInherit]);

  const getUserRoleForFold = () => {
    getUserRole({
      spaceId: teamId,
      resourceId,
    }).then((res) => {
      if (res.spaceOwner) {
        setUserRole('SUPER_ADMIN');
      } else {
        setUserRole(roleMap[res.resourceRole]);
      }
    }).catch(() => {
      message.error(intl.t('获取权限失败'));
      setUserRole('MEMBER');
    });
  };

  const getIsInherit = () => {
    getInheritStatus({
      spaceId: teamId,
      resourceId,
    }).then((res) => {
      setIsInherit(res.extensionType === 0);
    }).catch(() => {
    });
  };

  // 根文件夹 & 文件夹的超管和普管有权限
  const showInherit = useMemo(() => {
    return !window.isChildFile && (userRole === 'ADMIN' || userRole === 'SUPER_ADMIN');
  }, [window.isChildFile, userRole]);


  // 不继承 & 文件夹的超管和普管有权限 & 根节点
  const showAddMemberBtn = useMemo(() => {
    if (window.isChildFile) return false;
    if (isInherit) return false;
    return (userRole === 'ADMIN' || userRole === 'SUPER_ADMIN');
  }, [isInherit, userRole, window.isChildFile]);

  const showBatchMemberBtn = useMemo(() => {
    return (userRole === 'ADMIN' || userRole === 'SUPER_ADMIN');
  }, [userRole, window.isChildFile]);

  const closeAddMember = () => {
    setAddMemberOpen(false);
  };

  const openAddMember = () => {
    setAddMemberOpen(true);
    window.__OmegaEvent('ep_teamspace_more_permisionset_add_ck', '', {
      platform: 'new',
    });
  };

  const closeFoldAuthModal = () => {
    onCancel();
    refreshFileOrCrumb();
  };

  const inheritRoleCallback = (value) => {
    getIsInherit();
    getTeamListData(value);
  };


  return <Modal
    closable={!addMemberOpen}
    wrapClassName={`cooperFoldAuth ${addMemberOpen ? 'addMemberModal' : ''}`}
    title={addMemberOpen
      ? <div className='addMemberTitle'><span
          className='back'
          onClick={closeAddMember}/>{intl.t('添加可访问成员')}</div>
      : <span><RestrictLength
          word={folderName}
          maxLength={18} />&nbsp;{intl.t('权限设置')}</span>}
    visible={true}
    style={{ top: 100 }}
    footer={null}
    width={640}
    maskClosable={false}
    onCancel={onCancel}
  >
    <TeamContext.Provider value={{ resourceId, teamId, closeFoldAuthModal, goRootFolder, userRole }}>
      <MemberPermContent
        refreshFileOrCrumb={refreshFileOrCrumb}
        addMemberOpen={addMemberOpen}
        openAddMember={openAddMember}
        closeAddMember={closeAddMember}
        showAddMemberBtn={showAddMemberBtn}
        showBatchMemberBtn={showBatchMemberBtn}
        showInherit={showInherit}
        isInherit={isInherit}
        inheritRoleCallback={inheritRoleCallback}
      />
    </TeamContext.Provider>
  </Modal>;
};


export default CooperFoldAuth;
