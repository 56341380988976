/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-07-22 15:41:23
 * @LastEditTime: 2023-07-22 15:46:11
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/CooperFoldAuth/MemberPermContent/memberList/index.js
 *
 */
import React from 'react';
import MemberItem from '../memberItem';
import './index.less';


const MemberList = ({ list, refresh, keyWord }) => {
  return <div className='memberList'>
    {list && list.map((item) => {
      return <div
        className='memberItem'
        key={item.email}>
        <MemberItem
          memberInfo={item}
          refresh={refresh}
          keyWord={keyWord}
          />
      </div>;
    }) }

  </div>;
};

export default MemberList;
