import * as React from 'react';

const loadComponent = (props) => (cb) => {
  const { impt, tagName, child, config } = props;
  impt.then((component) => {
    let Container = null;
    let Before = (child && child.before) || null;
    let After = (child && child.after) || null;
    let Child = (child && child.tagName) ? component[child.tagName] : null;
    Container = tagName ? component[tagName] : (component.default || component);
    let OverTag = tagName ? <Container {...config} >
      {Before}
      {Child ? <Child {...child.config} /> : null}
      {After}
    </Container> : Container;
    cb(OverTag);
  });
};

export default class LazyComponent extends React.Component {
  constructor() {
    super(...arguments);
    this.state = {
      component: null,
    };
  }

  componentDidMount() {
    loadComponent(this.props)((component) => this.setState({ component }));
  }

  render() {
    const Component = this.state.component;
    return this.props.tagName ? Component : (Component && <Component {...this.props.config}>{this.props.children}</Component>);
  }
}
