import React from 'react';
import MemberItem from '../memberItem';
import './index.less';

const MemberList = ({ list, refresh, keyWord, selectedList, type, singleCheckChange, removeSingleItem }) => {
  return <div className='batchMemberList'>
    {list && list.map((item) => {
      return <div
        key={item.email}>
        <MemberItem
          memberInfo={item}
          refresh={refresh}
          keyWord={keyWord}
          selectedList= {selectedList}
          type={type}
          singleCheckChange={singleCheckChange}
          removeSingleItem={removeSingleItem}
          />
      </div>;
    })}
  </div>;
};

export default MemberList;
