/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-07-22 15:41:23
 * @LastEditTime: 2024-04-16 15:24:34
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/CooperFoldAuth/MemberPermContent/memberItem/index.js
 *
 */
import React, { useMemo } from 'react';
import { intl } from 'di18n-react';
import { highlight } from '@/utils';
import DepartContent from '../../departContent';
import UserPermSelect from '../userPermSelect';
import './index.less';

const Tag = ({ content, className = '' }) => {
  return (
    <span className={`tag ${className}`}>
      <span className="word">{intl.t(content)}</span>
    </span>
  );
};

const TagText = ({ tagList }) => {
  if (tagList.indexOf('SPACE_OWNER') !== -1) {
    return <Tag content={intl.t('空间所有者')} />;
  }
  if (tagList.indexOf('RESOURCE_OWNER') !== -1) {
    return <Tag content={intl.t('文件夹所有者')} />;
  }
  return '';
};

const MemberItem = ({ memberInfo, keyWord, refresh, hideChangePerm }) => {
  const {
    tag,
    self,
    email,
    avatar,
    cooperate_with_cn: name,
    id,
    department,
    showOuter,
  } = memberInfo;
  const { hrStatus } = memberInfo;
  const isShort = useMemo(() => {
    return (
      tag.indexOf('SPACE_ADMINISTRATOR') !== -1
      || tag.indexOf('RESOURCE_OWNER') !== -1
    );
  }, [tag]);

  return (
    <div
      className={`${
        hrStatus === 'I' ? 'quit-member' : ''
      } cooperFoldAuthm-member-list`}
      key={id}
    >
      <img
        className="pic"
        src={avatar} />
      <div className="info">
        <div className="nameArea">
          <div
            className="name"
          >{highlight(name)}</div>
          <DepartContent
            department={department}
            isShort={isShort} />
          {self && <Tag
            content={intl.t('我')}
            className="tag-self" />}
          {hrStatus === 'I' && (
            <Tag
              content={intl.t('已离职')}
              className="tag-quit-self" />
          )}
          {
            showOuter && <Tag
              content={intl.t('外部')}
              className="out-yellow" />
          }
          <TagText tagList={tag} />
        </div>
        <p
          className="mail"
        >{ highlight(email)}</p>
      </div>
      {!hideChangePerm && (
        <UserPermSelect
          memberInfo={memberInfo}
          refresh={refresh} />
      )}
    </div>
  );
};

export default MemberItem;
