import { intl } from 'di18n-react';

import { BetaIcon } from '@/assets/icon';
import EmptyRender from '@/components/SpinRender';
import LayoutContext from '@/components/serviceComponents/Layout/layoutContext';
import * as PageService from '@/service/knowledge/page';
import { blurTime } from '@/utils';
import { Checkbox, Tooltip } from 'antd';
import classNames from 'classnames/bind';
import { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styles from './style.module.less';

const cls = classNames.bind(styles);
function VersionComparisonSearch({ id, ...props }) {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState(props.options || []);
  const { pageId } = useContext(LayoutContext);

  const onValueChange = (val, i) => {
    props.changeComparisonVersion(i);
    props.onChange(val);
  };

  const filterMy = async (val) => {
    setLoading(true);
    const { checked } = val.target;
    const owner = checked ? 1 : 0;
    const data = await PageService.getVersionList(pageId, owner);
    if (data) {
      setLoading(false);
      setOptions(data);
    }
  };

  return (
    <div className={cls('version-popover-content')}>
      <div className={cls('versionTitle')}>
        <span className={cls('version')}>{intl.t('版本')}</span>
        <span className={cls('publictTime')}>{intl.t('发布时间')}</span>
        <span className={cls('Publisher')}>{intl.t('发布人')}</span>
        <span className={cls('endSelect')}>
          <Checkbox onChange={filterMy}>
            <span className={cls('mineList')}>{intl.t('仅看我的')}</span>
          </Checkbox>
        </span>
      </div>
      {options.length ? (
        <div className={cls('content-scroll-box')}>
          <ul className={cls('content_box')}>
            {options.map((v, i) => (
              <li
                key={i}
                className={id === v.id ? `${cls('active')}` : ''}
                onClick={() => onValueChange(v, i)}
              >
                <span>
                  {v.historyName}
                  {intl.t('版本')}
                </span>
                <span>{blurTime(v.createdOn)}</span>
                <span>{v.createdBy.chineseName}</span>
                <i
                  style={{ display: id === v.id ? 'block' : 'none' }}
                  className={cls('dk-icon-xuanzhong', 'dk-iconfont')}
                />
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <>
          {loading ? (
            <EmptyRender loading={true} />
          ) : (
            <div className={cls('versionEmpty')}>{intl.t('搜索无结果')}</div>
          )}
        </>
      )}
    </div>
  );
}

function DebounceSelect({
  data,
  setOperationType,
  changeComparisonVersion,
  getVersionList,
  currentVersionIndex,
}) {
  const [historyName, setHistoryName] = useState('');
  const [visible, setVisible] = useState(false);
  const [id, setId] = useState('');
  useEffect(() => {
    setId('');
    setHistoryName('');
    changeComparisonVersion('');
    setOperationType(false);
  }, [currentVersionIndex]);

  const handleVersion = (v) => {
    setHistoryName(v.historyName);
    setId(v.id);
    setOperationType(true);
    setVisible(false);

    if (v.historyName === historyName) {
      setHistoryName('');
      setId('');
      changeComparisonVersion('');
      setOperationType(false);
    }
  };

  return (
    <div>
      <Tooltip
        overlayClassName={`${cls('version-search-popover')} ${cls(
          'switch-search-popover',
        )} ${cls('dk-switch-search-popver')}`}
        placement="bottomLeft"
        trigger="click"
        visible={visible}
        destroyTooltipOnHide={true}
        onVisibleChange={() => {
          setVisible(!visible);
        }}
        title={
          <VersionComparisonSearch
            options={data}
            id={id}
            changeComparisonVersion={changeComparisonVersion}
            getVersionList={getVersionList}
            onChange={(v) => handleVersion(v)}
          />
        }
      >
        <div
          className={cls('versionComparison')}
          style={{ border: visible ? '1px solid #047FFE' : '' }}
          onClick={() => {
            setVisible(!visible);
          }}
        >
          <span
            className={
              !historyName
                ? `${cls('versionValuable')}`
                : `${cls('versionNovalue')}`
            }
          >
            {historyName === ''
              ? intl.t('请选择版本')
              : intl.t('{historyName}版本', { historyName })}
          </span>
          <i
            className={`${cls('dk-iconfont', 'dk-icon-xiayitiaopinglun')} ${cls(
              'moreDk',
            )} ${visible ? cls('isOpen') : ''}`}
          />
        </div>
      </Tooltip>
    </div>
  );
}

function VersionComparison(props) {
  const {
    versionList,
    currentVersionIndex,
    changeComparisonVersion,
    getVersionList,
  } = props;
  const [operationType, setOperationType] = useState(false);
  const currentVersion = versionList[currentVersionIndex];
  const text = (
    <span>
      {intl.t(
        '版本对比功能仍在持续优化中，如遇到问题请加入“知识库反馈群”进行反馈',
      )}
    </span>
  );
  return (
    <div className={cls('versioncomparison')}>
      <div className={cls('right')}>
        {
          <>
            <div className={cls('rightVersion')}>
              {currentVersion && currentVersion.historyName ? (
                <>
                  <div className={cls('visible-version')}>
                    <span className={cls('visible-version-text')}>
                      {currentVersion.historyName}
                      {intl.t('版本')}
                    </span>
                  </div>

                  <span className={cls('span')}>{intl.t('与')}</span>
                  <div>
                    <DebounceSelect
                      setOperationType={setOperationType}
                      getVersionList={getVersionList}
                      changeComparisonVersion={changeComparisonVersion}
                      currentVersionIndex={currentVersionIndex}
                      data={versionList}
                    />
                  </div>
                  <span className={cls('span')}>{intl.t('对比')}</span>
                  <Tooltip
                    placement="bottom"
                    title={text}
                    overlayClassName={cls('dk-ant-tooltip__reset')}
                    overlayStyle={{ minWidth: 412 }}
                  >
                    <div className={cls('versionTipIcon')}>
                      <img
                        src={BetaIcon}
                        className={cls('betaIcon')} />
                      <i
                        className={cls(
                          'dk-icon-a-bangzhuzhongxin4px',
                          'dk-iconfont',
                          'import-tip',
                        )}
                      />
                    </div>
                  </Tooltip>
                </>
              ) : null}
            </div>
          </>
        }
      </div>
      <div className={cls('left', operationType ? 'left-bock' : 'left-none')}>
        <span className={cls('add')}>{intl.t('添加')}</span>
        <span className={cls('delete')}>{intl.t('删除')}</span>
        <span className={cls('modify')}>{intl.t('修改')}</span>
        <div className={cls('verticalBar')}> </div>
      </div>
    </div>
  );
}
function mapStateToProps({ historyVersion, pageDetail }) {
  const { docInfo } = pageDetail;
  const { view, versionList, currentVersionIndex } = historyVersion;
  return {
    view,
    docInfo,
    versionList,
    currentVersionIndex,
  };
}
function mapDispatchToProps({ historyVersion, pageDetail }) {
  const {
    revertVersion,
    toggleHistoryVersionVisible,
    changeComparisonVersion,
    getVersionList,
  } = historyVersion;
  const { getPageDetail } = pageDetail;
  return {
    revertVersion,
    toggleHistoryVersionVisible,
    changeComparisonVersion,
    getPageDetail,
    getVersionList,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VersionComparison);
