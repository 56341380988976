import InfiniteScroll from 'react-infinite-scroller';
import { Table } from 'antd';
import classnames from 'classnames/bind';
import styles from './style.module.less';
import { isDesktopDC } from '@/utils';

const cx = classnames.bind(styles);

const ScrollTableList = ({ loadMore, loading, hasMore, columns, dataList, clickRow }) => {
  return <InfiniteScroll
    initialLoad={false}
    pageStart={0}
    loadMore={() => loadMore()} // careful, 默认会传页码
    hasMore={!loading && hasMore}
    useWindow={false}
>
    <Table
      className={cx('knowledge_scrollTableList_wrap', {
        'dc-knowledge_scrollTableList_wrap': isDesktopDC,
      })}
      columns={columns}
      dataSource={dataList}
      pagination={false}
      sticky={true}
      onRow={(record) => {
        return {
          onClick: () => { clickRow && clickRow(record); },
        };
      }}
/>
  </InfiniteScroll>;
};
export default ScrollTableList;
